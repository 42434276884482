<template>
  <div class="section-title style1 text-center">
    <h2>{{ $t('faq.faqAdmissibilityTitle') }}</h2>
  </div>
  <div class="faq-wrap ptb-100">
    <div class="container">
      <div class="row gx-5 align-items-center">
        <div class="col-lg-12">
          <div class="accordion style2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading9">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse9"
                  aria-expanded="false"
                  aria-controls="collapse9"
                >
                  <span>
                    <i class="ri-arrow-down-s-line plus"></i>
                    <i class="ri-arrow-up-s-line minus"></i>
                  </span>
                  {{ $t('faq.faqAdmissibilityQuestion1') }}
                </button>
              </h2>
              <div
                id="collapse9"
                class="accordion-collapse collapse"
                aria-labelledby="heading9"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="single-product-text">
                    <p>
                      {{ $t('faq.faqAdmissibilityAnswer1') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading10">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse10"
                  aria-expanded="false"
                  aria-controls="collapse10"
                >
                  <span>
                    <i class="ri-arrow-down-s-line plus"></i>
                    <i class="ri-arrow-up-s-line minus"></i>
                  </span>
                  {{ $t('faq.faqAdmissibilityQuestion2') }}
                </button>
              </h2>
              <div
                id="collapse10"
                class="accordion-collapse collapse"
                aria-labelledby="heading10"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    {{ $t('faq.faqAdmissibilityAnswer2') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading11">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse11"
                  aria-expanded="false"
                  aria-controls="collapse11"
                >
                  <span>
                    <i class="ri-arrow-down-s-line plus"></i>
                    <i class="ri-arrow-up-s-line minus"></i>
                  </span>
                  {{ $t('faq.faqAdmissibilityQuestion3') }}
                </button>
              </h2>
              <div
                id="collapse11"
                class="accordion-collapse collapse"
                aria-labelledby="heading11"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    {{ $t('faq.faqAdmissibilityAnswer3') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading12">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse12"
                  aria-expanded="false"
                  aria-controls="collapse12"
                >
                  <span>
                    <i class="ri-arrow-down-s-line plus"></i>
                    <i class="ri-arrow-up-s-line minus"></i>
                  </span>
                  {{ $t('faq.faqAdmissibilityQuestion4') }}
                </button>
              </h2>
              <div
                id="collapse12"
                class="accordion-collapse collapse"
                aria-labelledby="heading12"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="single-product-text">
                    <p>
                      {{ $t('faq.faqAdmissibilityAnswer41') }}
                    </p>
                    <ul>
                      <li>{{ $t('faq.faqAdmissibilityAnswer42') }}</li>
                      <li>
                        {{ $t('faq.faqAdmissibilityAnswer43') }}
                      </li>
                      <li>{{ $t('faq.faqAdmissibilityAnswer44') }}</li>
                      <li>{{ $t('faq.faqAdmissibilityAnswer45') }}</li>
                      <li>{{ $t('faq.faqAdmissibilityAnswer46') }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading13">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse13"
                    aria-expanded="false"
                    aria-controls="collapse13"
                  >
                    <span>
                      <i class="ri-arrow-down-s-line plus"></i>
                      <i class="ri-arrow-up-s-line minus"></i>
                    </span>
                    {{ $t('faq.faqAdmissibilityQuestion5') }}
                  </button>
                </h2>
                <div
                  id="collapse13"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading13"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      {{ $t('faq.faqAdmissibilityAnswer5') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading14">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse14"
                    aria-expanded="false"
                    aria-controls="collapse14"
                  >
                    <span>
                      <i class="ri-arrow-down-s-line plus"></i>
                      <i class="ri-arrow-up-s-line minus"></i>
                    </span>
                    {{ $t('faq.faqAdmissibilityQuestion6') }}
                  </button>
                </h2>
                <div
                  id="collapse14"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading14"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      {{ $t('faq.faqAdmissibilityAnswer6') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading15">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse15"
                    aria-expanded="false"
                    aria-controls="collapse15"
                  >
                    <span>
                      <i class="ri-arrow-down-s-line plus"></i>
                      <i class="ri-arrow-up-s-line minus"></i>
                    </span>
                    {{ $t('faq.faqAdmissibilityQuestion7') }}
                  </button>
                </h2>
                <div
                  id="collapse15"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading15"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>{{ $t('faq.faqAdmissibilityAnswer7') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FaqAdmissibility',
}
</script>

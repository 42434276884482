<template>
  <div class="page-wrapper">
    <div class="container tabs">
      <div
        @click="activeTab = 'admin'"
        :class="{ active: activeTab === 'admin' }"
        class="tab-header"
      >
        Admin Dashboard
      </div>
      <div
        @click="activeTab = 'transactions'"
        :class="{ active: activeTab === 'transactions' }"
        class="tab-header"
      >
        User Transactions
      </div>
    </div>
    <div v-if="loggedInUser && userIsAdmin" class="content-wrapper">
      <template v-if="activeTab === 'admin'">
        <AdminDashboard />
      </template>
      <template v-else-if="activeTab === 'transactions'">
        <UserTransactions />
      </template>
      <template v-else>
        <ErrorPage />
      </template>
    </div>
  </div>
</template>

<script>
import environment from '../../../environment'
import AdminDashboard from '../Admin/AdminDashboard'
import UserTransactions from '../Admin/UserTransactions'
import ErrorPage from '@/components/Pages/ErrorPage'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'

export default {
  name: 'AdminDashboardPage',
  components: {
    AdminDashboard,
    UserTransactions,
    ErrorPage,
  },
  data() {
    return {
      userIsAdmin: false,
      activeTab: 'admin', // Set the default active tab to 'admin'
    }
  },
  mounted() {
    this.updateUserIsAdmin()
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
  watch: {
    loggedInUser() {
      this.updateUserIsAdmin()
    },
  },
  methods: {
    updateUserIsAdmin() {
      if (this.loggedInUser) {
        this.userIsAdmin = environment.RUBY_ADMIN.includes(
          this.loggedInUser.email,
        )
      }
    },
  },
}
</script>

<style>
.tabs {
  display: flex;
  justify-content: space-between; /* Adjust the alignment of the tabs */
  margin-top: 10%; /* Add some margin to push the tabs below the top bar */
}

.tab-header {
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.tab-header.active {
  font-weight: bold;
}
</style>

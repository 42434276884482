<template>
  <div class="loan-wrap ptb-100 bg-alco">
    <div class="container">
      <div class="section-title style1 text-center mb-40">
        <span>Financial Planning</span>
        <h2 class="text-white">We Offer Loan From Banks</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
          <div class="service-card style2">
            <div class="service-info">
              <div class="service-title">
                <span><i class="flaticon-loan"></i></span>
                <h3>Home Loans</h3>
              </div>
              <ul class="content-feature-list list-style">
                <li>
                  <i class="ri-checkbox-multiple-line"></i>Lowest Interest rate
                </li>
                <li>
                  <i class="ri-checkbox-multiple-line"></i>Fastest Loan
                  Processing
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
          <div class="service-card style2">
            <div class="service-info">
              <div class="service-title">
                <span><i class="flaticon-car-loan"></i></span>
                <h3>Car Loans</h3>
              </div>
              <ul class="content-feature-list list-style">
                <li>
                  <i class="ri-checkbox-multiple-line"></i>Competitive Rate
                </li>
                <li>
                  <i class="ri-checkbox-multiple-line"></i>Quick &amp; Easy
                  Processing
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
          <div class="service-card style2">
            <div class="service-info">
              <div class="service-title">
                <span><i class="flaticon-graduated"></i></span>
                <h3>Education Loans</h3>
              </div>
              <ul class="content-feature-list list-style">
                <li>
                  <i class="ri-checkbox-multiple-line"></i>Pay Back Conveniently
                </li>
                <li>
                  <i class="ri-checkbox-multiple-line"></i>Fast Processing
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
          <div class="service-card style2">
            <div class="service-info">
              <div class="service-title">
                <span><i class="flaticon-graduated"></i></span>
                <h3>Business Loans</h3>
              </div>
              <ul class="content-feature-list list-style">
                <li><i class="ri-checkbox-multiple-line"></i>Easy Approval</li>
                <li>
                  <i class="ri-checkbox-multiple-line"></i>Full Assistance
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-10">
        <router-link to="/apply-loan" class="btn style1"
          >Apply For Loan</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinancialPlanning',
}
</script>

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Blog Right Sidebar" />
      <BlogRightSidebar />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import BlogRightSidebar from '@/components/Unused/BlogRightSidebar/BlogRightSidebar'

export default {
  name: 'BlogRightSidebarPage',
  components: {
    PageTitle,
    BlogRightSidebar,
  },
}
</script>

<template>
  <div class="container">
    <div class="login-form-wrap">
      <div class="login-header">
        <h3 class="fw-bold">{{ $t('forgotPasswordLink') }}</h3>

        <p v-if="forgotEmailSentMessage">{{ forgotEmailSentMessage }}</p>
        <p v-else>
          Nous enverrons un lien de vérification à votre courrier pour
          réinitialiser votre mot de passe
        </p>
      </div>
      <div class="login-form">
        <div class="login-body">
          <form class="form-wrap" @submit.prevent>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <input
                    type="text"
                    class="input"
                    placeholder="Nom d'utilisateur ou courriel"
                    v-model="email"
                  />
                </div>
              </div>

              <div class="col-md-12 text-center">
                <p class="mb-0 text-danger" v-if="errorMessage !== ''">
                  {{ errorMessage }}
                </p>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <button
                    class="btn style1"
                    @click="sendForgPasswordEmail"
                    :disabled="!email"
                  >
                    {{
                      forgotEmailSentMessage
                        ? `Ré-envoyer l'email`
                        : `Envoyer l'email`
                    }}
                  </button>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <p class="mb-0">
                  Retour connexion ?
                  <a class="style1" @click="toggleForgotPasswordView">
                    Click Here</a
                  >
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/helpers/firebaseInit.js'
import { sendPasswordResetEmail } from 'firebase/auth'

export default {
  name: 'ForgotPasswordContent',
  data() {
    return {
      email: '',
      forgotEmailSentMessage: '',
      errorMessage: '',
    }
  },
  methods: {
    toggleForgotPasswordView() {
      this.$emit('toggleForgotPasswordViewClicked')
    },
    sendForgPasswordEmail() {
      this.forgotEmailSentMessage = ''
      if (!this.email) {
        return
      }
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.forgotEmailSentMessage = `Nous avons envoyé un e-mail de vérification sur votre compte <b>${this.email}</b>`
        })
        .catch((error) => {
          error =
            error.message.indexOf('Firebase: ') > -1
              ? error.message.split('Firebase: ')[1]
              : error.message
          this.errorMessage = error
        })
    },
  },
}
</script>

<template>
  <div v-if="loggedInUser" class="admin-dashboard-wrap">
    <Popup
      popupTitle="Confirm Delete"
      popupContent="This action is permanent and cannot be undone"
      :showMessage="showMessagePopup"
      @okClicked="continueDelete"
      @cancelClicked="abortDelete"
    />
    <div class="container">
      <h1 class="text-center mb-5">Admin Dashboard</h1>
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-12">
            <div class="card-header d-flex align-items-center">
              <h5 class="mb-0">Welcome to the Admin Dashboard</h5>
              <button
                class="btn style6 ms-auto"
                @click="refreshDashboard"
                label="refresh"
              >
                {{ $t('refresh') }}
                <img
                  src="@/assets/images/arrow-clockwise.svg"
                  alt="Refresh icon"
                />
              </button>
            </div>
            <div class="card-body wrap">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t('email') }}</th>
                    <th>{{ $t('loanStatus') }}</th>
                    <th>{{ $t('loanAmount') }}</th>
                    <th>{{ $t('dateRequested') }}</th>
                    <th>{{ $t('contract') }}</th>
                    <th>{{ $t('approve') }}</th>
                    <th>{{ $t('deny') }}</th>
                    <th>{{ $t('delete') }}</th>
                    <th>{{ $t('expand') }}</th>
                  </tr>
                </thead>
                <tbody v-for="(loan, index) in loanDetails" :key="index">
                  <tr>
                    <td>{{ loan.email }}</td>
                    <td>{{ $t('loanStatus.' + loan.status) }}</td>
                    <td>{{ loan.initialLoanAmount }}</td>
                    <td>{{ loan.dateLoanRequested }}</td>
                    <td>
                      <a :href="loan.contractUrl" target="_blank">
                        {{ $t('contract') }}
                      </a>
                    </td>
                    <td>
                      <button
                        class="btn btn-success"
                        @click="updateLoan(loan.uid, 'approved')"
                      >
                        {{ $t('approve') }}
                      </button>
                    </td>
                    <td>
                      <button
                        class="btn btn-danger"
                        @click="updateLoan(loan.uid, 'rejected')"
                      >
                        {{ $t('deny') }}
                      </button>
                    </td>
                    <td>
                      <button
                        class="btn btn-danger"
                        @click="initiateDelete(loan.uid)"
                      >
                        {{ $t('delete') }}
                      </button>
                    </td>
                    <td>
                      <b-button
                        v-b-toggle="'collapse-' + index.toString()"
                        @click="toggleExpand(index)"
                      >
                        {{ $t(expandText[index] ? 'minimize' : 'expand') }}
                      </b-button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <b-collapse
                        :id="'collapse-' + index.toString()"
                        class="mt-2"
                      >
                        <UserDetailsCard :uid="loan.uid" />
                      </b-collapse>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'
import { getUIFormatDate } from '@/helpers/textFormatter'
import Popup from '@/components/Common/Popup'
import UserDetailsCard from '@/components/Admin/UserDetailsCard'

export default {
  name: 'AdminDashboard',
  components: {
    Popup,
    UserDetailsCard,
  },
  data: function () {
    return {
      loanDetails: [],
      expandText: [],
      userIsAdmin: false,
      showMessagePopup: false,
      deleteUID: '',
    }
  },
  mounted() {
    this.fetchAllLoan()
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
  methods: {
    toggleExpand(index) {
      this.expandText[index] = !this.expandText[index]
    },
    async initiateDelete(uid) {
      this.deleteUID = uid
      this.showMessagePopup = true
    },
    async continueDelete() {
      this.deleteContract(this.deleteUID)
      this.showMessagePopup = false
      this.deleteUID = ''
    },
    abortDelete() {
      this.showMessagePopup = false
    },
    fetchAllLoan() {
      const fetchAllLoanFn = httpsCallable(functions, 'fetchAllLoan')
      fetchAllLoanFn()
        .then((result) => {
          const sortedLoanDetails = result.data.sort((a, b) => {
            if (a.status === 'inProgress' && b.status !== 'inProgress') {
              return -1
            } else if (a.status !== 'inProgress' && b.status === 'inProgress') {
              return 1
            }
          })
          this.loanDetails = sortedLoanDetails
          this.expandText = Array(sortedLoanDetails.length).fill(false)
          console.log(this.loanDetails)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    refreshDashboard() {
      this.fetchAllLoan()
    },
    async updateLoan(uid, status) {
      try {
        let loanDetails = {
          uid: uid,
          status: status,
          approvedBy: this.loggedInUser.email,
          approvedDate: getUIFormatDate(),
        }
        const updateFirestore = httpsCallable(functions, 'updateFirestore')
        const response = await updateFirestore({ loanDetails })

        this.fetchAllLoan()
        console.log(response.data.message)
      } catch (error) {
        console.error(error)
      }
    },
    async deleteContract(uid) {
      const deleteLoanDetailsByUID = httpsCallable(
        functions,
        'deleteLoanDetailsByUID',
      )

      const response = await deleteLoanDetailsByUID({ uid })
      alert(response.data.message)
    },
  },
}
</script>

<style>
.admin-dashboard-wrap {
  padding: 50px 0;
}

.nav-link.active {
  color: #007bff;
}
</style>

<template>
  <div class="section-title style1 text-center">
    <h2>{{ $t('faq.faqPaymentTitle') }}</h2>
  </div>
  <div class="faq-wrap ptb-100">
    <div class="container">
      <div class="row gx-5 align-items-center">
        <div class="col-lg-6">
          <div class="faq-img-wrap">
            <img src="../../assets/images/faq-1.jpg" alt="Image" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="accordion style2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading1">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse1"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  <span>
                    <i class="ri-arrow-down-s-line plus"></i>
                    <i class="ri-arrow-up-s-line minus"></i>
                  </span>
                  {{ $t('faq.faqPaymentQuestion1') }}
                </button>
              </h2>
              <div
                id="collapse1"
                class="accordion-collapse collapse show"
                aria-labelledby="heading1"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="single-product-text">
                    <p>
                      {{ $t('faq.faqPaymentAnswer1') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading2">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse2"
                  aria-expanded="false"
                  aria-controls="collapse2"
                >
                  <span>
                    <i class="ri-arrow-down-s-line plus"></i>
                    <i class="ri-arrow-up-s-line minus"></i>
                  </span>
                  {{ $t('faq.faqPaymentQuestion2') }}
                </button>
              </h2>
              <div
                id="collapse2"
                class="accordion-collapse collapse"
                aria-labelledby="heading2"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    {{ $t('faq.faqPaymentAnswer2') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading3">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse3"
                  aria-expanded="false"
                  aria-controls="collapse3"
                >
                  <span>
                    <i class="ri-arrow-down-s-line plus"></i>
                    <i class="ri-arrow-up-s-line minus"></i>
                  </span>
                  {{ $t('faq.faqPaymentQuestion3') }}
                </button>
              </h2>
              <div
                id="collapse3"
                class="accordion-collapse collapse"
                aria-labelledby="heading3"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    {{ $t('faq.faqPaymentAnswer3') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading4">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  aria-expanded="false"
                  aria-controls="collapse4"
                >
                  <span>
                    <i class="ri-arrow-down-s-line plus"></i>
                    <i class="ri-arrow-up-s-line minus"></i>
                  </span>
                  {{ $t('faq.faqPaymentQuestion4') }}
                </button>
              </h2>
              <div
                id="collapse4"
                class="accordion-collapse collapse"
                aria-labelledby="heading4"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="single-product-text">
                    <p>
                      {{ $t('faq.faqPaymentAnswer4') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqPayment',
}
</script>

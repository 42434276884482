<template>
  <div class="container">
    <div class="login-form-wrap">
      <div class="login-header">
        <h3>Connectez-vous à votre compte</h3>
        <p>
          Connectez-vous pour vérifier votre processus de demande et gérer vos
          prêts.
        </p>
      </div>
      <div class="login-form">
        <div class="login-body">
          <form class="form-wrap" @submit.prevent>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <input
                    type="text"
                    class="input"
                    placeholder="Nom d'utilisateur ou courriel"
                    v-model="email"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <input
                    type="password"
                    class="input"
                    placeholder="Mot de passe"
                    v-model="password"
                  />
                </div>
              </div>
              <div class="col-md-12 text-center">
                <p class="mb-0 text-danger" v-if="errorMessage !== ''">
                  {{ errorMessage }}
                </p>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                <div class="checkbox style3">
                  <input type="checkbox" id="test_1" />
                  <label for="test_1">Remember Me</label>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-end">
                <p>
                  <a class="style1" @click="toggleForgotPasswordView">
                    {{ $t('forgotPasswordLink') }}
                  </a>
                </p>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <button
                    class="btn style1"
                    @click="signIn"
                    :disabled="password != '' && username != ''"
                  >
                    S'identifier
                  </button>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <p class="mb-0">
                  Vous n'avez pas de compte? Faites une demande maintenant pour
                  créer un compte.
                  <a class="style1" @click="toggleRegistrationView">
                    Crée un compte</a
                  >
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { auth } from '@/helpers/firebaseInit.js'
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'

export default {
  data() {
    return {
      email: '',
      username: '',
      password: '',
      errorMessage: '',
    }
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setLoggedInUser(user)
        this.$emit('loginButtonClicked')
      } else {
        this.setLoggedInUser()
      }
    })
  },
  computed: {
    isValid() {
      return this.email !== '' && this.password !== ''
    },
  },
  methods: {
    ...mapActions(useAuthStore, ['setLoggedInUser']),
    toggleRegistrationView() {
      this.$emit('toggleRegistrationViewClicked')
    },
    toggleForgotPasswordView() {
      this.$emit('toggleForgotPasswordViewClicked')
    },
    signIn() {
      const email = this.email
      const password = this.password

      signInWithEmailAndPassword(auth, email, password).catch((error) => {
        // catch any errors
        error =
          error.message.indexOf('Firebase: ') > -1
            ? error.message.split('Firebase: ')[1]
            : error.message
        this.errorMessage = error
      })
    },
  },
  watch: {},
}
</script>

import { defineStore } from 'pinia'
import { initFirebase, db } from '@/helpers/firebaseInit'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    loggedInUser: undefined,
    userDetails: undefined,
  }),
  actions: {
    setLoggedInUser(user) {
      this.loggedInUser = user
    },
    fetchUserDetails(uid) {
      initFirebase()
      const userRef = db.collection('userDetails').doc(uid)

      userRef.get().then((doc) => {
        if (doc.exists) {
          const userDetails = doc.data()
          this.userDetails = userDetails
        }
      })
    },
  },
})

<template>
  <div
    v-if="loggedInUser"
    class="apply-loan-content-box apply-loan-content-box-success"
  >
    <div class="apply-loan-content-box-body">
      <h3 class="text-center">
        {{ $t('applicationSubmittedForm.formTitle').toUpperCase() }}
      </h3>
      <div class="apply-loan-content-box-success-text text-center">
        {{ $t('applicationSubmittedForm.formSubtitle') }}
      </div>
      <h5 class="text-center">
        <img src="@/assets/images/person-image.png" />
      </h5>
    </div>
    <div class="apply-loan-content-box-footer">
      <router-link :to="`/my-dashboard`" class="btn style1 w-100 d-block">
        {{ $t('applicationSubmittedForm.goToDashboard').toUpperCase() }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'

export default {
  name: 'ContractForm',
  data() {
    return {}
  },
  mounted() {},
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
  methods: {},
}
</script>

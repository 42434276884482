<template>
  <form v-if="loggedInUser" @submit.prevent>
    <div class="apply-loan-content-box">
      <div class="apply-loan-content-box-action" @click="backButtonClicked">
        <i class="ri-arrow-left-s-line"></i>
        {{ $t('personalDetailsForm.formTitle').toUpperCase() }}
      </div>
      <div class="apply-loan-content-box-inner">
        <div class="apply-loan-content-box-header">
          <h5 class="apply-loan-content-box-header-title">
            {{ $t('contactDetails') }}
          </h5>
          <div class="apply-loan-content-box-header-desc">
            {{ $t('personalDetailsForm.subTitle') }}
          </div>
        </div>
        <div class="apply-loan-content-box-body">
          <div class="form-group">
            <label> {{ $t('cellPhone') }} </label>
            <input
              type="tel"
              required
              v-model="inFormContactDetails.cellPhoneNumber"
            />
          </div>

          <div class="form-group">
            <label> {{ $t('homePhone') }} </label>
            <input type="tel" v-model="inFormContactDetails.homePhoneNumber" />
          </div>

          <div class="form-group">
            <label> {{ $t('addressLine1') }} </label>
            <input
              type="text"
              required
              v-model="inFormContactDetails.addressLine1"
            />
          </div>

          <div class="form-group">
            <label> {{ $t('addressLine2') }} </label>
            <input type="text" v-model="inFormContactDetails.addressLine2" />
          </div>

          <div class="form-group">
            <label> City </label>
            <input type="text" required v-model="inFormContactDetails.city" />
          </div>

          <div class="row">
            <div class="col-6 form-group">
              <label> {{ $t('province') }} </label>
              <input
                type="text"
                required
                v-model="inFormContactDetails.province"
              />
            </div>

            <div class="col-6 form-group">
              <label> {{ $t('postalCode') }} </label>
              <input
                type="text"
                required
                v-model="inFormContactDetails.postalCode"
              />
            </div>
          </div>

          <button
            type="submit"
            class="btn style1 w-100 d-block"
            @click="ProcessContactDetailsForm"
            :disabled="disableContinue"
          >
            {{ $t('sharedFormTexts.next').toUpperCase() }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { getServerFormatDate } from '@/helpers/textFormatter'
import { ContactDetails, LoanDetails } from '@/models/Models'
import { mapState } from 'pinia'
import { useRepo } from 'pinia-orm'
import { useAuthStore } from '@/store/auth'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'ContactDetailsForm',
  data() {
    return {
      inFormContactDetails: {
        cellPhoneNumber: '',
        homePhoneNumber: '',
        addressLine1: '',
        city: '',
        province: '',
        postalCode: '',
      },
    }
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    disableContinue() {
      return (
        this.inFormContactDetails.cellPhoneNumber === '' ||
        this.inFormContactDetails.addressLine1 === '' ||
        this.inFormContactDetails.city === '' ||
        this.inFormContactDetails.province === '' ||
        this.inFormContactDetails.postalCode === ''
      )
    },
  },
  methods: {
    ProcessContactDetailsForm() {
      if (this.loggedInUser) {
        const contactDetails = {
          cellPhoneNumber: this.inFormContactDetails.cellPhoneNumber,
          homePhoneNumber: this.inFormContactDetails.homePhoneNumber,
          addressLine1: this.inFormContactDetails.addressLine1,
          addressLine2: this.inFormContactDetails.addressLine2,
          city: this.inFormContactDetails.city,
          province: this.inFormContactDetails.province,
          postalCode: this.inFormContactDetails.postalCode,
          createTime: getServerFormatDate(),
          updateTime: getServerFormatDate(),
          email: this.loggedInUser.email,

          uid: this.loggedInUser.uid,
        }

        useRepo(ContactDetails).save(contactDetails)
        const createdStore = useRepo(ContactDetails).all()[0]

        if (
          createdStore.cellPhoneNumber &&
          createdStore.addressLine1 &&
          createdStore.city &&
          createdStore.province &&
          createdStore.postalCode
        ) {
          this.contactDetailsSubmitClicked()
        }
      }
    },
    backButtonClicked() {
      this.$emit('backButtonClicked', 1, 1)
    },
    contactDetailsSubmitClicked() {
      this.$emit('contactDetailsSubmitClicked')
    },
  },
  setup() {
    const router = useRouter()

    onMounted(() => {
      const loanDetails = useRepo(LoanDetails).all()
      if (loanDetails.length === 0) {
        router.push('/')
      }
    })
  },
}
</script>

<template>
  <div class="app-wrap style2 ptb-100 bg-stratos">
    <img
      src="@/assets/images/section-shape-2.png"
      alt="Image"
      class="section-shape-two"
    />
    <div class="container">
      <div class="row align-items-center gx-5">
        <div class="col-lg-6">
          <div class="app-img-wrap">
            <img
              class="bounce"
              src="@/assets/images/app/app-2.png"
              alt="Image"
            />
            <img
              class="app-shape-one"
              src="@/assets/images/app/app-shape-2.png"
              alt="Image"
            />
            <img
              class="app-shape-two"
              src="@/assets/images/app/app-shape-3.png"
              alt="Image"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="app-content">
            <div class="content-title style2">
              <span>Download Our Mobile App</span>
              <h2>You Can Find All Things You Need In Our App</h2>
              <p>
                There are many variations of passages of Lorem Ipsum amet
                avoilble but majority have suffered alteration in some form, by
                injected humur or randomise words which don't sure amet sit
                dolor quras alto lorem.
              </p>
            </div>
            <div class="app-btn">
              <a target="_blank" href="https://www.apple.com/app-store/"
                ><img src="@/assets/images/apple-store-3.png" alt="Image"
              /></a>
              <a target="_blank" href="https://play.google.com/store/apps"
                ><img src="@/assets/images/play-store.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDownload',
}
</script>

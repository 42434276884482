<template>
  <div class="table-container">
    <b-table
      v-if="personalDetails.length > 0"
      responsive
      :items="personalDetails"
    ></b-table>
    <b-table
      v-if="contactDetails.length > 0"
      responsive
      fixed
      :items="contactDetails"
    ></b-table>
    <b-table
      v-if="aditionalPersonalDetails.length > 0"
      responsive
      class="flex-grow-1"
      :items="aditionalPersonalDetails"
    ></b-table>
    <b-table
      v-if="loanDetails.length > 0"
      responsive
      :items="loanDetails"
    ></b-table>
    <b-table
      v-if="secondaryContactDetails.length > 0"
      responsive
      :items="secondaryContactDetails"
    ></b-table>
  </div>
</template>
<script>
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'

export default {
  name: 'UserDetailsCard',
  data() {
    return {
      bankDetails: [],
      contactDetails: [],
      jobDetails: [],
      loanDetails: [],
      personalDetails: [],
      secondaryContactDetails: [],
      aditionalPersonalDetails: [],
    }
  },
  props: ['uid'],
  computed: {},
  mounted() {
    this.fetchUserDetails()
  },
  methods: {
    filterEmptyValues(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          // eslint-disable-next-line no-unused-vars
          .filter(([k, v]) => v)
          .filter(
            // eslint-disable-next-line no-unused-vars
            ([k, v]) =>
              k !== 'email' &&
              k !== 'uid' &&
              k !== 'contracts' &&
              k !== 'contractID' &&
              k !== 'contractUrl' &&
              k !== 'dailyInterest' &&
              k !== 'previousLoans' &&
              k !== 'previousStatements' &&
              k !== 'status' &&
              k !== 'initialLoanAmount' &&
              k !== 'dateLoanRequested' &&
              k !== 'createTime' &&
              k !== 'updateTime',
          ),
      )
    },
    fetchUserDetails() {
      console.log(this.uid)
      const fetchAllUserData = httpsCallable(functions, 'fetchAllUserData')
      fetchAllUserData({ uid: this.uid })
        .then((result) => {
          const data = result.data

          console.log('Fetching All User Data...')

          if (data.bankDetails[0] !== 'no bank details found') {
            this.bankDetails = this.filterEmptyValues(data.bankDetails)
          }
          if (data.contactDetails[0] !== 'no contact details found') {
            this.contactDetails = [this.filterEmptyValues(data.contactDetails)]
          }
          if (data.jobDetails[0] !== 'no job details found') {
            this.jobDetails = this.filterEmptyValues(data.jobDetails)
          }
          if (data.loanDetails[0] !== 'no loan details found') {
            this.loanDetails = [this.filterEmptyValues(data.loanDetails)]
          }
          if (data.personalDetails[0] !== 'no personal details found') {
            this.personalDetails = [
              this.filterEmptyValues(data.personalDetails),
            ]
          }
          if (
            data.secondaryContactDetails[0] !==
            'no secondary contact details found'
          ) {
            this.secondaryContactDetails = [
              this.filterEmptyValues(data.secondaryContactDetails),
            ]
          }

          this.aditionalPersonalDetails = [
            {
              ...this.jobDetails,
              ...this.bankDetails,
            },
          ]
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>

<style>
.table-container {
  max-width: 1220px;
  margin: 0 auto;
}
</style>

<template>
  <div v-if="loggedInUser" class="content-wrapper">
    <NoLoanErrorPage
      v-if="!this.loanDetails || this.loanDetails.status != 'approved'"
    />
    <div v-else class="section-title style1 text-center mb-40">
      <div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
          "
        >
          <i class="fas fa-angle-left" @click="prevMonth">{{
            $t('myCalendar.previous')
          }}</i>
          <h4>{{ $t('myCalendar.' + monthName) }} {{ year }}</h4>
          <i class="fas fa-angle-right" @click="nextMonth">{{
            $t('myCalendar.next')
          }}</i>
        </div>
      </div>

      <div
        class="calendar-container"
        style="display: flex; justify-content: center"
      >
        <table
          class="calendar-table"
          style="width: 100%; border-collapse: collapse"
        >
          <thead>
            <tr>
              <th>{{ $t('myCalendar.sunday') }}</th>
              <th>{{ $t('myCalendar.monday') }}</th>
              <th>{{ $t('myCalendar.tuesday') }}</th>
              <th>{{ $t('myCalendar.wednesday') }}</th>
              <th>{{ $t('myCalendar.thursday') }}</th>
              <th>{{ $t('myCalendar.friday') }}</th>
              <th>{{ $t('myCalendar.saturday') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week, index) in weeks" :key="index">
              <td
                v-for="day in week"
                :key="day.date"
                :class="{
                  'due-payment': day.duePayment,
                  prev: day.type === 'prev',
                  next: day.type === 'next',
                }"
              >
                {{ day.date || '' }}
                <div v-if="day.duePayment" class="text-danger fs-5">
                  {{ $t('myCalendar.paymentDue') }}
                </div>
                <div v-if="day.duePayment" class="text-danger fs-5">
                  {{ this.loanDetails.minPaymentPerPeriod }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { mapRepos } from 'pinia-orm'
import { LoanDetails } from '@/models/Models'
import NoLoanErrorPage from '@/components/Pages/NoLoanErrorPage'

export default {
  name: 'MyCalendar',
  components: {
    NoLoanErrorPage,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      monthName: '',
      weeks: [],
    }
  },
  mounted() {
    this.renderCalendar()
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    ...mapRepos({ loanDetailsRepo: LoanDetails }),
    loanDetails() {
      return this.loanDetailsRepo.all()[0]
    },
  },
  methods: {
    prevMonth() {
      if (this.month === 0) {
        this.year--
        this.month = 11
      } else {
        this.month--
      }
      this.renderCalendar()
    },
    nextMonth() {
      if (this.month === 11) {
        this.year++
        this.month = 0
      } else {
        this.month++
      }
      this.renderCalendar()
    },
    computeDueDates() {
      if (this.loanDetails) {
        const nextPayday = new Date(this.loanDetails.nextPayday)
        const repayFrequency = this.loanDetails.repayFrequency
        const loanTermInWeeks = this.loanDetails.loanTermInWeeks
        const dates = [nextPayday]

        let currentDate = nextPayday
        let incrementAmount = 0

        if (repayFrequency === 'weekly') {
          incrementAmount = 7
        } else if (repayFrequency === 'everyTwoWeeks') {
          incrementAmount = 14
        } else if (repayFrequency === 'twiceAMonth') {
          incrementAmount = 15
        } else if (repayFrequency === 'monthly') {
          incrementAmount = 30
        }

        for (let i = 1; i < loanTermInWeeks; i++) {
          currentDate = new Date(
            currentDate.getTime() + incrementAmount * 24 * 60 * 60 * 1000,
          )
          dates.push(currentDate)
        }

        return dates
      }

      return []
    },
    computeDueDatesThisMonth(dates) {
      const now = new Date()
      const startOfMonth = new Date(now.getFullYear(), this.month, 1)
      const endOfMonth = new Date(now.getFullYear(), this.month + 1, 0)
      endOfMonth.setHours(23, 59, 59, 999)

      const result = []

      for (const date of dates) {
        if (date >= startOfMonth && date <= endOfMonth) {
          result.push(date.getDate())
        }
      }

      return result
    },
    renderCalendar() {
      const months = [
        'january',
        'febuary',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ]

      this.monthName = months[this.month]
      const firstDayOfMonth = new Date(this.year, this.month, 1).getDay()
      const lastDateOfMonth = new Date(this.year, this.month + 1, 0).getDate()
      const days = []

      const dueDates = this.computeDueDates()
      const dueDatesThisMonth = this.computeDueDatesThisMonth(dueDates)

      for (let i = 1; i <= lastDateOfMonth; i++) {
        days.push({
          date: i,
          duePayment: dueDatesThisMonth.includes(i),
        })
      }

      this.weeks = []
      let week = []

      // Get the last date of the previous month
      let prevMonth = new Date(this.year, this.month, 0)
      let lastDateOfPrevMonth = prevMonth.getDate()

      // Fill in the empty tiles with the dates from the previous month
      for (let i = firstDayOfMonth - 1; i >= 0; i--) {
        week.unshift({
          date: lastDateOfPrevMonth,
          duePayment: false,
          type: 'prev',
        })
        lastDateOfPrevMonth--
      }

      for (const day of days) {
        week.push(day)
        if (week.length === 7) {
          this.weeks.push(week)
          week = []
        }
      }

      // Get the first date of the next month
      let nextMonth = new Date(this.year, this.month + 1, 1)
      let firstDateOfNextMonth = nextMonth.getDate()

      // Fill in the remaining empty tiles with the dates from the next month
      for (let i = week.length; i < 7; i++) {
        week.push({
          date: firstDateOfNextMonth,
          duePayment: false,
          type: 'next',
        })
        firstDateOfNextMonth++
      }

      if (week.length > 0) {
        this.weeks.push(week)
      }
    },
  },
  watch: {
    loanDetails: {
      handler() {
        this.renderCalendar()
      },
      immediate: true,
    },
  },
}
</script>
<style scoped>
.calendar-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow-y: auto;
}

.calendar-table {
  width: 100%;
  border-collapse: collapse;
}

.calendar-table th,
.calendar-table td {
  width: 14.285714%;
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  height: 20px;
}
td {
  width: 14.285714%;
  text-align: center;
  padding: 10px 0;
  border: 1px solid #ddd;
  vertical-align: top;
  height: 50px;
}
td p {
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  height: 100%; /* Make sure the height is equal to the height of the cell */
  display: flex; /* Flexbox to center the content vertically */
  align-items: center; /* Center the content vertically */
  color: red;
}
.prev {
  background-color: lightgrey;
  font-style: italic;
}
.next {
  background-color: lightgrey;
  font-style: italic;
}
</style>

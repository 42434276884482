<template>
  <form v-if="loggedInUser" @submit.prevent>
    <div class="apply-loan-content-box">
      <div class="apply-loan-content-box-inner">
        <div class="apply-loan-content-box-header">
          <h5 class="apply-loan-content-box-header-title">
            {{ $t('personalDetailsForm.formTitle') }}
          </h5>
          <div class="apply-loan-content-box-header-desc">
            {{ $t('personalDetailsForm.subTitle') }}
          </div>
        </div>
        <div class="apply-loan-content-box-body">
          <div class="form-group">
            <label> {{ $t('firstName') }}</label>
            <input
              type="text"
              required
              autocomplete="off"
              v-model="inFormPersonalDetails.firstName"
              pattern="^[a-zA-Z\s-]+$"
            />
          </div>

          <div class="form-group">
            <label> {{ $t('lastName') }}</label>
            <input
              type="text"
              required
              v-model="inFormPersonalDetails.lastName"
              pattern="^[a-zA-Z\s-]+$"
            />
          </div>

          <div class="form-group">
            <label> {{ $t('dateOfBirth') }}</label>
            <input
              type="date"
              required
              :min="getMinDateOfBirth"
              v-model="inFormPersonalDetails.dateOfBirth"
            />
          </div>

          <div class="form-group">
            <label> {{ $t('sin') }}</label>
            <input
              type="text"
              v-model="inFormPersonalDetails.socialInsuranceNumber"
              pattern="^(\d{9}|(\d{3}\s){2}\d{3})$"
            />
          </div>

          <div class="form-group">
            <div class="apply-loan-content-box-helper">
              <i class="ri-shield-check-fill"></i>
              {{ $t('personalDetailsForm.formBottomText') }}
            </div>
          </div>

          <button
            type="submit"
            class="btn style1 w-100 d-block"
            @click="ProcessPersonalDetailsForm"
            :disabled="disableContinue"
          >
            {{ $t('sharedFormTexts.next').toUpperCase() }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { getServerFormatDate } from '@/helpers/textFormatter'
import { PersonalDetails, LoanDetails } from '@/models/Models'
import { useRepo } from 'pinia-orm'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'PersonalDetailsForm',
  data() {
    return {
      inFormPersonalDetails: {
        firstName: '',
        lastName: '',
        dateOfBirth: this.getMinDateOfBirth(),
      },
    }
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    disableContinue() {
      return (
        this.inFormPersonalDetails.firstName === '' ||
        this.inFormPersonalDetails.lastName === ''
      )
    },
  },
  methods: {
    ProcessPersonalDetailsForm() {
      if (this.loggedInUser) {
        const personalDetails = {
          firstName: this.inFormPersonalDetails.firstName,
          lastName: this.inFormPersonalDetails.lastName,
          dateOfBirth: this.inFormPersonalDetails.dateOfBirth,
          socialInsuranceNumber:
            this.inFormPersonalDetails.socialInsuranceNumber,
          createTime: getServerFormatDate(),
          updateTime: getServerFormatDate(),
          email: this.loggedInUser.email,

          uid: this.loggedInUser.uid,
        }

        useRepo(PersonalDetails).save(personalDetails)
        const createdStore = useRepo(PersonalDetails).all()[0]

        if (createdStore.firstName !== '' && createdStore.lastName !== '') {
          this.personalDetailsSubmitClicked()
        }
      }
    },
    getMinDateOfBirth() {
      const today = new Date()

      const min = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate(),
      )

      const year = min.getFullYear()
      const month = min.getMonth() + 1
      const day = min.getDate()
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`

      return formattedDate
    },
    personalDetailsSubmitClicked() {
      this.$emit('personalDetailsSubmitClicked')
    },
  },
  setup() {
    const router = useRouter()

    onMounted(() => {
      const loanDetails = useRepo(LoanDetails).all()
      if (loanDetails.length === 0) {
        router.push('/')
      }
    })
  },
}
</script>

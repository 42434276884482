<template>
  <div v-if="loggedInUser" class="apply-loan-content-box">
    <div class="apply-loan-content-box-action" @click="backButtonClicked">
      <i class="ri-arrow-left-s-line"></i>
      {{ $t('ibvForm.formTitle').toUpperCase() }}
    </div>
    <div class="apply-loan-content-box-inner">
      <div class="apply-loan-content-box-header">
        <h5 class="apply-loan-content-box-header-title">
          {{ $t('ibvForm.formTitle') }}
        </h5>
        <div class="apply-loan-content-box-header-desc">
          {{ $t('ibvForm.formSubtitle') }}
        </div>
      </div>
      <PlaidLink
        clientName="Vue Test"
        env="development"
        :link_token="linkToken"
        :products="['auth', 'transactions']"
        webhook="https://requestb.in"
        :onLoad="onLoad"
        :onSuccess="onSuccess"
        :onExit="onExit"
        :onEvent="onEvent"
      >
        <div class="m-4">
          <button class="btn style2 w-100 mt-1 d-block">
            {{ $t('ibvForm.connectButton') }}
          </button>
        </div>
      </PlaidLink>
      <button
        type="submit"
        class="btn style1 w-100 d-block"
        @click="processBankDetailsForm"
        :disabled="disableContinue"
      >
        {{ $t('sharedFormTexts.next').toUpperCase() }}
      </button>
    </div>
  </div>
</template>

<script>
import { LoanDetails } from '@/models/Models'
import { mapState } from 'pinia'
import { useRepo } from 'pinia-orm'
import { useAuthStore } from '@/store/auth'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import PlaidLink from '@/components/Plaid/PlaidLink'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'

export default {
  name: 'IBVForm',
  components: {
    PlaidLink,
  },
  data() {
    return {
      disableContinue: true,
      linkToken: '',
    }
  },
  mounted() {
    this.createLinkToken()
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
  methods: {
    onLoad() {},
    async onSuccess(public_token, metadata) {
      console.log('public_token')
      console.log(public_token)
      console.log(metadata)
      try {
        const testExchangePublicToken = httpsCallable(
          functions,
          'testExchangePublicToken',
        )

        const response = await testExchangePublicToken(public_token)
        if (response.data.accessTokenReceived) {
          this.disableContinue = false
        } else {
          console.log('Something went wrong during token exchange')
        }
      } catch (error) {
        console.error('Error calling testExchangePublicToken:', error)
      }
    },
    onExit(err, metadata) {
      console.log('err')
      console.log(err)
      console.log(metadata)
    },
    onEvent(eventName, metadata) {
      console.log('eventName')
      console.log(eventName)
      console.log(metadata)
    },
    async createLinkToken() {
      try {
        const testGeneratePlaidLinkToken = httpsCallable(
          functions,
          'testGeneratePlaidLinkToken',
        )
        const response = await testGeneratePlaidLinkToken()
        localStorage.setItem('link_token', response.data.link_token)
      } catch (error) {
        console.error('Error calling testGeneratePlaidLinkToken:', error)
      }
    },
    processBankDetailsForm() {
      if (this.loggedInUser) {
        this.ibvFormSubmitClicked()
      }
    },
    backButtonClicked() {
      this.$emit('backButtonClicked', 1, 5)
    },
    ibvFormSubmitClicked() {
      this.$emit('ibvFormSubmitClicked')
    },
  },
  setup() {
    const router = useRouter()

    onMounted(() => {
      const loanDetails = useRepo(LoanDetails).all()
      if (loanDetails.length === 0) {
        router.push('/')
      }
    })
  },
}
</script>

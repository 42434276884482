<template>
  <div v-if="loggedInUser" class="admin-dashboard-wrap">
    <div class="container">
      <div class="row">
        <div class="col-xl-5 col-md-8 col-sm-8 mx-auto">
          <div class="admindashboard">
            <div class="admindashboard-inner">
              <div class="admindashboard-inner-heading text-center">
                <h5>{{ $t('myDashboard.welcome') }}</h5>
                <h5 v-if="loggedInUser.name">
                  {{ loggedInUser.name }}
                </h5>
                <h5 v-else>
                  {{ loggedInUser.email }}
                </h5>
                <p v-if="loanDetails.status !== 'approved'">
                  {{ $t('myDashboard.loanPending') }}
                </p>
                <p v-else>
                  {{ $t('myDashboard.loanApproved') }}
                </p>
              </div>
              <div class="admindashboard-inner-main">
                <div class="main-heading">
                  <div class="main-heading-pending">
                    <span class="pending-dot"></span>
                    <span v-if="loanDetails">
                      {{
                        loanDetails.status === 'signed'
                          ? $t('loanStatus.inProgress')
                          : $t('loanStatus.' + loanDetails.status)
                      }}
                    </span>
                    <span v-else> {{ $t('loading') }} </span>
                  </div>
                  <div class="main-heading-date">{{ date }}</div>
                </div>
                <div class="dashboard-amount">
                  <img src="" alt="" />
                  <p v-if="loanDetails">${{ loanDetails.initialLoanAmount }}</p>
                  <p v-else>{{ $t('loading') }}</p>
                </div>
                <div class="progress-bar mb-40">
                  <div class="shell">
                    <div
                      class="bar"
                      v-if="progress <= 100"
                      :style="{ width: progress + '%' }"
                    ></div>
                    <div class="bar" v-else style="width: 100%"></div>
                  </div>
                  <span v-if="progress <= 100">{{ progress }}%</span>
                  <span v-else>100%</span>
                </div>
                <div class="dashboard-btn d-block mx-auto mb-30">
                  <div class="row mb-10">
                    <div class="col-6">
                      <button
                        class="dasboard-list-btn w-100"
                        :disabled="active1 == false"
                      >
                        <i class="ri-bank-fill"></i>
                        <span :class="{ active: active1 }">
                          <a v-bind:href="getPaymentLink()" data-currency="CAD">
                            {{ $t('myDashboard.makeAPayment') }}
                          </a>
                        </span>
                      </button>
                    </div>
                    <div class="col-6">
                      <button
                        class="dasboard-list-btn w-100"
                        :disabled="active2 == false"
                      >
                        <i class="ri-calendar-line"></i>
                        <span :class="{ active: active2 }">
                          {{ $t('myDashboard.viewStatement') }}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="row mb-10">
                    <div class="col-6">
                      <button
                        class="dasboard-list-btn w-100"
                        :disabled="active3 == false"
                      >
                        <i class="ri-refresh-line"></i>
                        <span :class="{ active: active3 }">
                          {{ $t('myDashboard.renewLoan') }}
                        </span>
                      </button>
                    </div>
                    <div class="col-6">
                      <router-link to="/my-contract">
                        <button
                          class="dasboard-list-btn w-100"
                          :class="{ activeborder: viewContract }"
                          :disabled="viewContract == false"
                        >
                          <i class="ri-attachment-line"></i>
                          <span :class="{ active: viewContract }">
                            {{ $t('myDashboard.viewContract') }}
                          </span>
                        </button>
                      </router-link>
                    </div>
                  </div>
                  <div class="row mb-10">
                    <div class="col-6">
                      <router-link to="/upload-documents">
                        <button
                          class="dasboard-list-btn w-100"
                          :class="{ activeborder: uploadDocuments }"
                          :disabled="uploadDocuments == false"
                        >
                          <i class="ri-upload-cloud-fill"></i>
                          <span :class="{ active: uploadDocuments }">
                            {{ $t('filesUpload.title') }}
                          </span>
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <span> {{ $t('myDashboard.renewLoanCondition') }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUIFormatDate } from '@/helpers/textFormatter'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { mapRepos } from 'pinia-orm'
import { LoanDetails } from '@/models/Models'

export default {
  name: 'MyDashboard',
  data: () => ({
    active1: true,
    active2: false,
    active3: false,
    viewContract: true,
    uploadDocuments: true,
    progress: 10,
    userData: {},
    date: getUIFormatDate(),
  }),
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    ...mapRepos({ loanDetailsRepo: LoanDetails }),
    loanDetails() {
      return this.loanDetailsRepo.all()[0]
    },
  },
  methods: {
    getPaymentLink() {
      if (this.loanDetails.initialLoanAmount == '750') {
        if (this.loanDetails.repayFrequency == 'weekly')
          return 'https://buy.stripe.com/test_bIY8yc2O20WG2cg5kq'
        if (this.loanDetails.repayFrequency == 'everyTwoWeeks')
          return 'https://buy.stripe.com/test_3csbKofAOdJsbMQ008'
        if (this.loanDetails.repayFrequency == 'twiceAMonth')
          return 'https://buy.stripe.com/test_3csbKofAOdJsbMQ008'
        if (this.loanDetails.repayFrequency == 'monthly')
          return 'https://buy.stripe.com/test_28o29OewKbBkbMQ5kr'
      }

      if (this.loanDetails.initialLoanAmount == '500') {
        if (this.loanDetails.repayFrequency == 'weekly')
          return 'https://buy.stripe.com/test_9AQ15K60e6h03gkaEJ'
        if (this.loanDetails.repayFrequency == 'everyTwoWeeks')
          return 'https://buy.stripe.com/test_6oEeWAgES48S5osbIM'
        if (this.loanDetails.repayFrequency == 'twiceAMonth')
          return 'https://buy.stripe.com/test_6oEeWAgES48S5osbIM'
        if (this.loanDetails.repayFrequency == 'monthly')
          return 'https://buy.stripe.com/test_5kA3dSagubBk2cgdQT'
      }

      if (this.loanDetails.initialLoanAmount == '250') {
        if (this.loanDetails.repayFrequency == 'weekly')
          return 'https://buy.stripe.com/test_dR66q4coC0WGcQUdQS'
        if (this.loanDetails.repayFrequency == 'everyTwoWeeks')
          return 'https://buy.stripe.com/test_7sI15K74igVE8AE6op'
        if (this.loanDetails.repayFrequency == 'twiceAMonth')
          return 'https://buy.stripe.com/test_7sI15K74igVE8AE6op'
        if (this.loanDetails.repayFrequency == 'monthly')
          return 'https://buy.stripe.com/test_7sIeWAagu48S4ko6oo'
      }

      return '$119.36'
    },
  },
}
</script>

<style></style>

<template>
  <div class="testimonial-wrap pt-100 pb-75 bg-albastor">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="testimonial-card style1">
            <div class="client-info-area">
              <div class="client-info-wrap">
                <div class="client-img">
                  <img
                    src="../../assets/images/testimonials/client-1.jpg"
                    alt="Image"
                  />
                </div>
              </div>
              <div class="quote-icon">
                <i class="flaticon-quote"></i>
              </div>
            </div>
            <p class="client-quote">
              Lorem ipsum dolor sit amet adi elition repell tetur delni vel quam
              aliq earum explibo dolor eme fugiat amet dolor lorem sit consect.
            </p>
            <div class="client-info">
              <h3>Jim Morison</h3>
              <span>Director, BAT</span>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="testimonial-card style1">
            <div class="client-info-area">
              <div class="client-info-wrap">
                <div class="client-img">
                  <img
                    src="../../assets/images/testimonials/client-2.jpg"
                    alt="Image"
                  />
                </div>
              </div>
              <div class="quote-icon">
                <i class="flaticon-quote"></i>
              </div>
            </div>
            <p class="client-quote">
              Lorem ipsum dolor sit amet adi elition repell tetur delni vel quam
              aliq earum explibo dolor eme fugiat amet dolor lorem sit consect.
            </p>
            <div class="client-info">
              <h3>Alex Cruis</h3>
              <span>CEO, IBAC</span>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="testimonial-card style1">
            <div class="client-info-area">
              <div class="client-info-wrap">
                <div class="client-img">
                  <img
                    src="../../assets/images/testimonials/client-3.jpg"
                    alt="Image"
                  />
                </div>
              </div>
              <div class="quote-icon">
                <i class="flaticon-quote"></i>
              </div>
            </div>
            <p class="client-quote">
              Lorem ipsum dolor sit amet adi elition repell tetur delni vel quam
              aliq earum explibo dolor eme fugiat amet dolor lorem sit consect.
            </p>
            <div class="client-info">
              <h3>Tom Haris</h3>
              <span>Engineer, Olleo</span>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="testimonial-card style1">
            <div class="client-info-area">
              <div class="client-info-wrap">
                <div class="client-img">
                  <img
                    src="../../assets/images/testimonials/client-4.jpg"
                    alt="Image"
                  />
                </div>
              </div>
              <div class="quote-icon">
                <i class="flaticon-quote"></i>
              </div>
            </div>
            <p class="client-quote">
              Lorem ipsum dolor sit amet adi elition repell tetur delni vel quam
              aliq earum explibo dolor eme fugiat amet dolor lorem sit consect.
            </p>
            <div class="client-info">
              <h3>Harry Jackson</h3>
              <span>Enterpreneur</span>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="testimonial-card style1">
            <div class="client-info-area">
              <div class="client-info-wrap">
                <div class="client-img">
                  <img
                    src="../../assets/images/testimonials/client-5.jpg"
                    alt="Image"
                  />
                </div>
              </div>
              <div class="quote-icon">
                <i class="flaticon-quote"></i>
              </div>
            </div>
            <p class="client-quote">
              Lorem ipsum dolor sit amet adi elition repell tetur delni vel quam
              aliq earum explibo dolor eme fugiat amet dolor lorem sit consect.
            </p>
            <div class="client-info">
              <h3>Chris Haris</h3>
              <span>MD, ITec</span>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="testimonial-card style1">
            <div class="client-info-area">
              <div class="client-info-wrap">
                <div class="client-img">
                  <img
                    src="../../assets/images/testimonials/client-6.jpg"
                    alt="Image"
                  />
                </div>
              </div>
              <div class="quote-icon">
                <i class="flaticon-quote"></i>
              </div>
            </div>
            <p class="client-quote">
              Lorem ipsum dolor sit amet adi elition repell tetur delni vel quam
              aliq earum explibo dolor eme fugiat amet dolor lorem sit consect.
            </p>
            <div class="client-info">
              <h3>Mark Owen</h3>
              <span>Enterpreneur</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Testimonials',
}
</script>

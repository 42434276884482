<template>
  <div class="pricing-table-wrap pt-100 pb-75 mt-100">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
          <div class="section-title style1 text-center mb-40">
            <h2>Commencez maintenant</h2>
            <span>Sélectionnez le montant de votre prêt </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="pricing-table style2">
            <div class="pricing-header">
              <h2>
                <img src="@/assets/images/pricing-star.png" alt="Image" />
              </h2>
              <div class="pricing-header-tag">
                <h3>$750</h3>
              </div>
              <span class="feature-tag">Most Popular</span>
            </div>
            <div class="purchase-pricing mt-4">
              <router-link to="/login" class="btn style2"
                >Emprunter maintenant</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="pricing-table style2">
            <div class="pricing-header">
              <h2>
                <img src="@/assets/images/pricing-star.png" alt="Image" />
              </h2>
              <div class="pricing-header-tag">
                <h3>$500</h3>
              </div>
            </div>
            <div class="purchase-pricing mt-4">
              <router-link to="/login" class="btn style2"
                >Emprunter maintenant</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="pricing-table style2">
            <div class="pricing-header">
              <h2>
                <img src="@/assets/images/pricing-star.png" alt="Image" />
              </h2>
              <div class="pricing-header-tag">
                <h3>$250</h3>
              </div>
            </div>

            <div class="purchase-pricing mt-4">
              <router-link to="/login" class="btn style2"
                >Emprunter maintenant</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pricing',
}
</script>

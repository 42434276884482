<template>
  <div class="container col-6 mt-3">
    <h5>{{ $t('secondaryContact') }}</h5>
    <div class="edit-button">
      <button class="btn style3" @click="toggleEditMode()">
        {{ showEdit ? 'Cancel' : 'Edit' }}
      </button>
    </div>

    <form @submit.prevent>
      <div v-if="!showEdit">
        <label for="firstName">
          {{ $t('firstName') }}
          {{ secondaryContactDetails.firstName }}
        </label>
      </div>
      <div v-if="showEdit">
        <label>{{ $t('firstName') }}</label>

        <input
          :placeholder="secondaryContactDetails.firstName"
          v-model="editedSecondaryContactDetails.firstName"
          type="text"
          id="firstName"
        />
      </div>
      <div v-if="!showEdit">
        <label for="lastName">
          {{ $t('lastName') }} {{ secondaryContactDetails.lastName }}
        </label>
      </div>
      <div v-if="showEdit">
        <label>{{ $t('lastName') }}</label>

        <input
          :placeholder="secondaryContactDetails.lastName"
          v-model="editedSecondaryContactDetails.lastName"
          type="text"
          id="lastName"
        />
      </div>

      <div v-if="!showEdit">
        <label>
          {{ $t('phoneNumber') }}
          {{ secondaryContactDetails.phoneNumber }}</label
        >
      </div>
      <div v-if="showEdit">
        <label for="phoneNumber">
          {{ $t('phoneNumber') }}
        </label>
        <input
          :placeholder="secondaryContactDetails.phoneNumber"
          v-model="editedSecondaryContactDetails.phoneNumber"
          type="text"
          id="phoneNumber"
        />
      </div>
      <div v-if="!showEdit">
        <label>
          {{ $t('relationToUser') }}
          {{ secondaryContactDetails.relationToUser }}</label
        >
      </div>
      <div v-if="showEdit">
        <label for="relationToUser">
          {{ $t('relationToUser') }}
        </label>
        <input
          :placeholder="secondaryContactDetails.relationToUser"
          v-model="editedSecondaryContactDetails.relationToUser"
          type="text"
          id="relationToUser"
        />
      </div>
      <button
        class="save-button"
        v-if="showEdit"
        type="submit"
        @click="saveChanges"
      >
        {{ $t('save') }}
      </button>
    </form>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'
import { getServerFormatDate } from '@/helpers/textFormatter'
import { useRepo } from 'pinia-orm'
import { SecondaryContactDetails } from '@/models/Models'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'

export default {
  name: 'SecondaryContactDetailsCard',
  props: {},
  data() {
    return {
      editedSecondaryContactDetails: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        relationToUser: '',
      },
      secondaryContactDetails: {},
      showEdit: false,
    }
  },
  mounted() {
    setTimeout(this.laodDetails, 1000)
  },
  methods: {
    async laodDetails() {
      try {
        const fetchDetailsFrom = httpsCallable(functions, 'fetchDetailsFrom')
        const response = await fetchDetailsFrom({
          uid: this.loggedInUser.uid,
          storage: 'secondaryContactDetails',
        })
        if (
          response.data.secondaryContactDetails[0] !=
          'no secondary contact details found'
        ) {
          useRepo(SecondaryContactDetails).save(
            response.data.secondaryContactDetails,
          )

          console.log(response.data.secondaryContactDetails)

          let secondaryContactDetails = useRepo(
            SecondaryContactDetails,
          ).all()[0]
          this.secondaryContactDetails = { ...secondaryContactDetails }
        }
      } catch (error) {
        console.error(
          'Error calling laodDetails in secondaryContactDetailsCard:',
          error,
        )
      }
    },
    toggleEditMode() {
      this.showEdit = !this.showEdit
    },
    async saveChanges() {
      if (this.showEdit) {
        let secondaryContactDetailsEdit = {}

        const updateFieldIfModified = (field, editedField) => {
          const trimmedValue = editedField.trim()
          if (trimmedValue !== '' && trimmedValue !== field) {
            return trimmedValue
          }
          return field
        }

        secondaryContactDetailsEdit.firstName = updateFieldIfModified(
          this.secondaryContactDetails.firstName,
          this.editedSecondaryContactDetails.firstName,
        )
        secondaryContactDetailsEdit.lastName = updateFieldIfModified(
          this.secondaryContactDetails.lastName,
          this.editedSecondaryContactDetails.lastName,
        )
        secondaryContactDetailsEdit.phoneNumber = updateFieldIfModified(
          this.secondaryContactDetails.phoneNumber,
          this.editedSecondaryContactDetails.phoneNumber,
        )
        secondaryContactDetailsEdit.relationToUser = updateFieldIfModified(
          this.secondaryContactDetails.relationToUser,
          this.editedSecondaryContactDetails.relationToUser,
        )

        console.log(this.loggedInUser.uid)

        try {
          const secondaryContactDetailsUpdate = {
            uid: this.loggedInUser.uid,
            updateTime: getServerFormatDate(),
            ...secondaryContactDetailsEdit,
          }

          console.log(secondaryContactDetailsUpdate)
          const updateFirestore = httpsCallable(functions, 'updateFirestore')
          const response = await updateFirestore({
            secondaryContactDetails: secondaryContactDetailsUpdate,
          })

          console.log(response.data.message)
          this.toggleEditMode()
          setTimeout(this.laodDetails, 1000)
        } catch (error) {
          console.error(error)
        }
      }
    },
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
}
</script>
<style scoped>
.container {
  position: relative;
}

.edit-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.save-button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
}
</style>

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createORM } from 'pinia-orm'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import i18n from './i18n'

import BootstrapVue3 from 'bootstrap-vue-3'

import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import './assets/custom.scss'

const pinia = createPinia().use(createORM())

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(BootstrapVue3)
app.use(AOS.init())
app.use(i18n)
app.mount('#app')

app.config.productionTip = false

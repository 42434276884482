<template>
  <div :class="['header-wrap', { sticky: isSticky }]">
    <!-- Top Header -->
    <div class="header-top" :class="{ open: open }">
      <button type="button" class="close-sidebar" @click="open = !open">
        <i class="ri-close-fill"></i>
      </button>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
            <div class="header-top-left">
              <ul class="contact-info list-style">
                <li>
                  <i class="flaticon-call"></i>
                  <a href="tel:15145005936">
                    {{ $t('footer.footerContactLine4') }}
                  </a>
                </li>
                <li>
                  <i class="flaticon-email-1"></i>
                  <a href="mailto:support@pretruby.ca">
                    {{ $t('contactUs.emailUsText1') }}@{{
                      $t('contactUs.emailUsText2')
                    }}
                  </a>
                </li>
                <li>
                  <i class="flaticon-pin"></i>
                  <p>{{ $t('footer.footerContactLine2') }}</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-3">
            <div class="header-top-right">
              <ul class="header-top-menu list-style">
                <li>
                  <router-link to="/contact">
                    {{ $t('navbar.support') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/FAQ">
                    {{ $t('navbar.help') }}
                  </router-link>
                </li>
                <li
                  v-if="$i18n.locale == 'en'"
                  @click="
                    () => {
                      $i18n.locale = 'fr'
                    }
                  "
                >
                  {{ $t('navbar.language') }}
                </li>
                <li
                  v-if="$i18n.locale == 'fr'"
                  @click="
                    () => {
                      $i18n.locale = 'en'
                    }
                  "
                >
                  {{ $t('navbar.language') }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Header -->

    <!-- Navbar -->
    <div class="header-bottom">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link to="/" class="navbar-brand nav-link">
            <img
              class="logo-light"
              src="../../assets/images/logo.png"
              alt="logo"
            />
          </router-link>
          <div v-if="loggedInUser">
            <p class="navbar-nav ms-auto">
              {{ $t('navbar.loggedInAs') }} {{ loggedInUser.email }}
            </p>
          </div>
          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <router-link to="/" class="nav-link">
                  {{ $t('getALoan') }}
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/faq" class="nav-link">FAQ</router-link>
              </li>

              <li class="nav-item">
                <router-link to="/contact" class="nav-link">
                  {{ $t('contactUs.shortTitle') }}
                </router-link>
              </li>

              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  {{ $t('navbar.dropdownTitle') }}
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item" v-if="loggedInUser">
                    <a href="#" class="nav-link">
                      {{ $t('navbar.userPages') }}
                      <i class="ri-arrow-down-s-line"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/my-account" class="nav-link">
                          {{ $t('navbar.userAccount') }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/my-dashboard" class="nav-link">
                          {{ $t('navbar.userDashboard') }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/my-calendar" class="nav-link">
                          {{ $t('navbar.userCalendar') }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <router-link to="/privacy-policy" class="nav-link">
                      {{ $t('navbar.legalMention') }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="mobile-bar-wrap">
            <AuthNav />
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navbar -->
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import AuthNav from './AuthNav.vue'

export default {
  name: 'Navbar',
  components: {
    AuthNav,
  },
  data: () => ({
    isSticky: false,
    active: false,
    open: false,
  }),
  mounted() {
    const that = this
    window.addEventListener('scroll', () => {
      let scrollPos = window.scrollY
      if (scrollPos >= 100) {
        that.isSticky = true
      } else {
        that.isSticky = false
      }
    })
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
}
</script>
<style>
.nav-link {
  z-index: 998;
}
</style>

<template>
  <div class="terms-wrap pt-100 pb-100">
    <div class="container">
      <div class="row gx-5">
        <div class="col-lg-12">
          <div class="single-terms">
            <p>
              ruby (ci-après « nous ») est l’unique propriétaire et exploitant
              du présent site Web : https://ruby.ca (ci-après le « site »).
            </p>
            <h3>CHAMP D’APPLICATION ET DISPOSITIONS PRÉLIMINAIRES</h3>
            <p>
              L’utilisation du site est assujettie aux conditions d’utilisation
              qui suivent (ci-après les « conditions »), lesquelles régissent
              notamment le contenu et le fonctionnement du site, de même que
              l’utilisation autorisée que vous pouvez en faire. Veuillez ne pas
              accéder au site si vous ne les acceptez pas entièrement, sans
              limite ni réserve. En accédant au site vous acceptez les
              conditions énoncées ci-dessous et vous acceptez d’être lié par les
              dispositions desdites conditions. Veuillez noter que nous pouvons
              modifier les conditions d’utilisation à tout moment et sans
              préavis. À cet effet, vous acceptez d’être lié par ces
              modifications. Par conséquent, nous vous suggérons de consulter
              régulièrement les conditions.
            </p>
          </div>
          <div class="single-terms">
            <h3>UTILISATION DU SITE</h3>
            <p>
              Vous déclarez que vous avez l’âge de majorité dans votre état ou
              province de résidence, ou que vous avez l’âge de majorité dans
              votre état ou province de résidence et que vous avez donné votre
              accord pour qu’une personne mineure dont vous avez la charge
              légale utilise ce site, que vous êtes habilité à conclure un
              contrat et que vous utilisez le présent site conformément à toutes
              les présentes conditions. Vous convenez d’assumer tous les frais
              engagés dans le cadre de l’utilisation du site (de même que ceux
              engagés par d’autres personnes utilisant votre compte). Vous
              déclarez également que toute l’information que vous avez fournie
              dans le cadre de l’utilisation du site est véridique et exacte. Il
              vous incombe de vous assurer que vous avez légalement accès au
              site et que les éléments disponibles dans celui-ci ou par
              l’intermédiaire de celui-ci sont licites dans chaque territoire où
              vous accédez au site ou à ces éléments ou visualisez ceux-ci.
            </p>
          </div>
          <div class="single-terms">
            <h3>INSCRIPTION ET ABONNEMENT</h3>
            <p>
              Si vous décidez de vous abonner à des services ou à des fonctions
              du site qui nécessitent un abonnement, vous acceptez de fournir
              des renseignements exacts et à jour à votre sujet comme l’exige le
              processus d’inscription ou d’abonnement pertinent, et de vous
              assurer de leur exactitude en effectuant les mises à jour
              nécessaires dès que possible. Vous acceptez d’assurer la
              confidentialité de tous les mots de passe ou autres
              identificateurs de compte que vous aurez choisis ou qui vous
              seront attribués au moment d’une inscription ou d’un abonnement et
              d’assumer la responsabilité à l’égard de toutes les activités
              reliées à l’utilisation de ces mots de passe ou de ces comptes. De
              plus, vous acceptez de nous prévenir de toute utilisation non
              autorisée de votre mot de passe ou de votre compte de membre, sans
              délai. Nous ne pouvons aucunement être tenus responsables,
              directement ou indirectement, des pertes ou dommages de quelque
              nature que ce soit découlant du défaut de vous conformer à la
              présente disposition ou liés à un tel défaut.
            </p>
            <p>
              Vous reconnaissez que nous pouvons, à notre seule et absolue
              discrétion, et ce, sans préavis, suspendre, annuler ou mettre fin
              à votre compte, ou à votre utilisation ou à votre accès au site ou
              à un de ses services, et retirer et supprimer tout renseignement
              ou contenu se rapportant au site ou à un des services offerts (et
              mettre fin à l’utilisation que vous en faites), pour quelque motif
              que ce soit, y compris si nous croyons que vous ne respectez pas
              les présentes conditions. En outre, vous reconnaissez que nous ne
              serons aucunement responsables envers vous ou envers quiconque à
              la suite d’une telle suspension, annulation ou fin. Si vous êtes
              insatisfait de ruby ou d’un de ses services, ou d’une des
              présentes conditions, des règles, des politiques, des lignes
              directrices ou de nos pratiques relativement à l’exploitation de
              ruby ou d’un de ses services, votre seul recours consiste à cesser
              d’utiliser le site ou le service en question.
            </p>
          </div>
          <div class="single-terms">
            <h3>COMMENTAIRES DES UTILISATEURS</h3>
            <p>
              Vous acceptez que tout commentaire que vous pourriez émettre à
              notre égard, notamment en lien avec la prestation de nos services,
              pourra être utilisé et publié sur notre site ou ailleurs pour des
              fins publicitaires.
            </p>
          </div>
          <div class="single-terms">
            <h3>LIMITATION DE RESPONSABILITÉ</h3>
            <p>
              En plus des autres limites de responsabilité contenues aux
              présentes conditions, nous (ainsi que les administrateurs,
              dirigeants, employés ou agents de ruby) ne sont en aucune
              circonstance responsables tant en matière civile qu’autrement de
              toute perte ou tout dommage de quelque nature que ce soit en lien
              avec la navigation sur le site ou l’utilisation du site et de son
              contenu, ainsi que l’utilisation par vous des services offerts par
              ruby, sous réserve de ce qui pourrait être prévu dans vos ententes
              spécifiques avec nous à cet effet, ni de l’impossibilité d’accéder
              au site ou de l’utiliser, notamment en cas de : (i) défaillance,
              défectuosité ou destruction de nos systèmes informatiques utilisés
              pour la mise en ligne et le bon fonctionnement du site; (ii) délai
              ou retard, quelles qu’en soient les causes, qui empêche quiconque
              d’utiliser ou de naviguer convenablement sur le site; (iii) de
              même qu’en raison de toute défaillance, délai ou autre
              circonstance de même nature imputable à un tiers impliqué dans la
              prestation des services, y inclus tous autres fournisseurs de
              services ou d’équipements de télécommunications en lien avec le
              site. Vous comprenez et acceptez que nous pouvons, en tout temps
              et à notre seule discrétion, cesser d’offrir certains services ou
              certains contenus sur le site ou encore en ajouter, et ce, sans
              avoir à vous aviser au préalable et sans que nous ne puissions
              être tenus responsables à cet égard. De plus, nous ne pouvons être
              tenus responsables dans le cas où nous décidons de refuser,
              désactiver, bloquer, suspendre ou terminer un abonnement ou une
              inscription, ou prendre toute autre mesure que nous jugeons
              appropriée, en raison du non-respect des présentes conditions, ou
              pour toute autre raison que nous jugeons pertinente. À cet effet,
              nous pourrons procéder à toute vérification en cas de situation
              litigieuse ou douteuse. Nous ne fournissons aucune garantie,
              expresse ou implicite, quant à l’exactitude, à l’exhaustivité, à
              la validité ou à l’actualité des renseignements et des contenus
              fournis sur le site. Nous ne garantissons pas que le site et les
              serveurs qui le rendent disponible sont exempts de virus ou de
              composantes nuisibles. Le site et son contenu sont fournis « tels
              quels » et « selon la disponibilité » sans déclaration ou garantie
              de notre part de quelque nature que ce soit, expresse ou
              implicite.
            </p>
          </div>
          <div class="single-terms">
            <h3>PROPRIÉTÉ INTELLECTUELLE</h3>
            <p>
              Le présent site ne peut être reproduit, en totalité ou en partie,
              sans notre consentement exprès écrit au préalable. Certains noms,
              mots, titres, expressions, logos, icônes, éléments graphiques,
              dessins ou autres contenus figurant sur les pages du site sont des
              appellations commerciales ou des marques de commerce qui nous
              appartiennent ou des appellations commerciales ou des marques de
              commerce qui nous ont été octroyées en vertu d’un permis ou d’une
              licence. Tout autre matériel contenu sur le site est protégé par
              les droits d’auteur. Tout téléchargement, retransmission, copie ou
              modification non autorisé des marques de commerce, des images, des
              textes ou des données contenus sur le site peut contrevenir aux
              lois fédérales ou provinciales et notamment à toutes lois
              relatives aux marques de commerce, aux dessins industriels ou aux
              droits d’auteur, et pourrait vous exposer à des procédures
              judiciaires.Hormis pour les besoins propres à la navigation sur
              Internet et sauf indication contraire, il est strictement interdit
              de copier, de redistribuer, de reproduire, de republier,
              d’emmagasiner sur tout média, de retransmettre ou de modifier
              l’information contenue sur le site ou d’en faire un usage public
              ou commercial, sous quelque forme que ce soit, sous réserve de
              notre approbation préalable écrite, laquelle sera assujettie à
              toutes les conditions que nous jugeons nécessaires.
            </p>
          </div>
          <div class="single-terms">
            <h3>CONFIDENTIALITÉ</h3>
            <p>
              Les communications via Internet sont sujettes à interception,
              perte ou altération, par conséquent, vous reconnaissez que les
              renseignements ou éléments que vous fournissez par voie
              électronique du fait que vous accédez à ce site ou en faites usage
              ne sont ni confidentiels ni exclusifs, sauf dans la mesure requise
              par les lois applicables et que les communications par courrier
              électronique non protégées sur Internet peuvent être interceptées,
              altérées ou se perdre. Pour plus d’information, veuillez consulter
              notre politique de confidentialité à l’adresse suivante
            </p>
          </div>
          <div class="single-terms">
            <h3>NOMS DE DOMAINE</h3>
            <p>
              Les noms de domaine de ruby en sont sa propriété exclusive. Aucune
              usurpation de noms de domaine par le biais d’hyperliens,
              d’encadrement de sites ou d’enregistrement de noms de domaines
              connexes ne sera tolérée.
            </p>
          </div>
          <div class="single-terms">
            <h3>HYPERLIENS VERS UN SITE TIERS</h3>
            <p>
              Le site contient des liens qui vous permettent d’accéder à des
              sites exploités par des tiers. Ces liens sont mis à votre
              disposition pour votre seule convenance et en vue de vous fournir
              une expérience de navigation plus intuitive et agréable.
              L’existence de tels liens ne constitue en aucun cas un appui
              implicite ou explicite aux sites concernés, à leur contenu ou aux
              autres sites qui sont rattachés à ces sites, et nous ne pouvons
              aucunement être tenus responsables de leur contenu. Nous sommes en
              tout temps libres de retrancher ou de modifier tout lien
              apparaissant sur le site ou d’en ajouter de nouveaux. Nous
              n’assumons aucune responsabilité quant au choix des liens fournis.
            </p>
          </div>
          <div class="single-terms">
            <h3>HYPERLIENS VERS LE SITE</h3>
            <p>
              Le site contient des liens qui vous permettent d’accéder à des
              sites exploités par des tiers. Ces liens sont mis à votre
              disposition pour votre seule convenance et en vue de vous fournir
              une expérience de navigation plus intuitive et agréable.
              L’existence de tels liens ne constitue en aucun cas un appui
              implicite ou explicite aux sites concernés, à leur contenu ou aux
              autres sites qui sont rattachés à ces sites, et nous ne pouvons
              aucunement être tenus responsables de leur contenu. Nous sommes en
              tout temps libres de retrancher ou de modifier tout lien
              apparaissant sur le site ou d’en ajouter de nouveaux. Nous
              n’assumons aucune responsabilité quant au choix des liens fournis.
            </p>
            <ul>
              <li>
                N’utilise pas la technique de l’encadrement ou du site miroir,
                ou toute autre méthode similaire, sans notre consentement
                préalable écrit;
              </li>
              <li>
                N’utilise pas de lien intégré à des images affichées sur le
                site;
              </li>
              <li>
                Ne laisse pas supposer qu’il existe une affiliation entre
                l’entreprise ou la personne au bénéfice duquel le site web est
                maintenu et nous, ni que nous appuyons les produits, services ou
                tout autre contenu mis de l’avant par le site web, à moins d’une
                entente écrite à l’effet contraire;
              </li>
              <li>
                Ne laisse pas supposer qu’il existe une affiliation entre
                l’entreprise ou la personne au bénéfice duquel le site web est
                maintenu et nous, ni que nous appuyons les produits, services ou
                tout autre contenu mis de l’avant par le site web, à moins d’une
                entente écrite à l’effet contraire;
              </li>
              <li>
                Ne comporte pas l’un ou plusieurs de nos logos, à moins d’une
                entente écrite à l’effet contraire
              </li>
              <li>
                Ne contienne pas de matériel pouvant être interprété comme de
                mauvais goût ou offensant, ou destiné ou convenant aux personnes
                âgées de moins de 18 ans; et
              </li>
            </ul>
            <p>
              Nous pouvons, en tout temps et à notre seule et entière
              discrétion, abolir immédiatement votre privilège d’établir un lien
              vers le site.
            </p>
          </div>
          <div class="single-terms">
            <h3>LANGUE</h3>
            <p>
              En cas de divergence entre les versions française et anglaise du
              contenu du site, notamment les présentes conditions et la
              politique de confidentialité, la version française a priorité.
            </p>
          </div>
          <div class="single-terms">
            <h3>LOIS APPLICABLES ET JURIDICTION</h3>
            <p>
              Les présentes conditions sont régies par les lois en vigueur dans
              la province de Québec et doivent être interprétées conformément à
              celles-ci. Vous convenez expressément que toute procédure
              judiciaire ou quasi-judiciaire pouvant être instituée par ruby ou
              par vous en relation avec les présentes conditions ou, plus
              généralement, l’utilisation que vous faites du site, devra être
              résolu en fonction des lois applicables du Québec et du Canada et
              devra l’être devant l’instance ayant compétence dans le district
              judiciaire de Longueuil, province de Québec, Canada.
            </p>
          </div>
          <div class="single-terms">
            <h3>PRIORITÉ DES CONTRATS</h3>
            <p>
              Toute disposition de tout contrat ou de toute entente spécifique
              entre ruby et vous qui n’est pas compatible avec les dispositions
              des présentes conditions ou les dispositions de la politique de
              confidentialité aura préséance sur ces dernières dispositions.
            </p>
          </div>
          <div class="single-terms">
            <h3>AUCUNE RENONCIATION</h3>
            <p>
              Le défaut de ruby d’appliquer de façon stricte ou non toute
              disposition des présentes conditions ou de la politique de
              confidentialité ne doit pas être interprété comme étant une
              renonciation à ce droit ou à cette disposition
            </p>
          </div>
          <div class="single-terms">
            <h3>NON-CONFORMITÉ D’UNE DISPOSITION</h3>
            <p>
              L’éventuelle illégalité ou nullité d’un article, d’un paragraphe
              ou d’une disposition des présentes conditions ou de la Politique
              n’affecte aucunement la légalité ou validité des autres articles,
              paragraphes ou dispositions ni non plus le reste du paragraphe ou
              de l’article, à moins d’intention contraire évidente dans le
              texte.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
}
</script>

<template>
  <div class="service-charge-wrap bg-stratos mt-100 pt-100">
    <div class="container text-center">
      <form @submit.prevent class="charge-form">
        <div class="col-lg-12 col-md-6 mb-3">
          <div class="col-lg-12">
            <h2 class="text-white">
              {{ $t('frontApply.applyNow') }}
            </h2>
            <h5 class="text-white">{{ $t('frontApply.selectAmount') }}</h5>
          </div>
        </div>
        <div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="inlineRadio1"
              value="750"
              v-model="loanAmount"
            />
            <label class="form-check-label" for="inlineRadio1">
              <span
                class="radio-number"
                :style="{
                  backgroundColor: loanAmount === '750' ? '#00a9a4' : 'white',
                }"
              >
                <span
                  :style="{
                    color: loanAmount === '750' ? 'white' : 'black',
                  }"
                  >$750</span
                >
              </span>
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="inlineRadio2"
              value="500"
              v-model="loanAmount"
            />
            <label class="form-check-label" for="inlineRadio2">
              <span
                class="radio-number"
                :style="{
                  backgroundColor: loanAmount === '500' ? '#00a9a4' : 'white',
                }"
              >
                <span
                  :style="{
                    color: loanAmount === '500' ? 'white' : 'black',
                  }"
                  >$500</span
                >
              </span>
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="inlineRadio3"
              value="250"
              v-model="loanAmount"
            />
            <label class="form-check-label" for="inlineRadio3">
              <span
                class="radio-number"
                :style="{
                  backgroundColor: loanAmount === '250' ? '#00a9a4' : 'white',
                }"
              >
                <span
                  :style="{
                    color: loanAmount === '250' ? 'white' : 'black',
                  }"
                  >$250</span
                >
              </span>
            </label>
          </div>
        </div>
        <div class="col-lg-12 col-md-6 mb-2">
          <div class="col-lg-12">
            <h5 class="text-white mt-3">
              {{ $t('frontApply.dateLoanWanted') }}
            </h5>
          </div>
        </div>
        <div>
          <input
            type="date"
            v-model="selectedDate"
            :min="minDate"
            @change="validateDate"
            style="font-size: 2rem"
          />
        </div>
        <div class="col-lg-12 col-md-6 mb-2">
          <div class="col-lg-12">
            <h5 class="text-white mt-3">
              {{ $t('frontApply.paycheckFrequency') }}
            </h5>
          </div>
        </div>
        <table
          class="selection-table"
          style="width: 30%; border: 2px solid #00a9a4"
        >
          <tr
            v-for="(option, index) in options"
            :key="index"
            @click="selectedPaybackInterval = option"
            :style="{
              backgroundColor:
                selectedPaybackInterval === option ? '#00a9a4' : 'white',
              color: selectedPaybackInterval === option ? 'white' : 'black',
            }"
          >
            <td>
              <label :for="option">{{ $t('frontApply.' + option) }}</label>
            </td>
          </tr>
        </table>
        <div class="col-lg-12 col-md-6 mb-2">
          <div class="col-lg-12">
            <h5 class="text-white mt-3">
              {{ $t('frontApply.payApproximately') }}
            </h5>
            <h3 class="text-white">{{ calculatedPayback }}</h3>
          </div>
        </div>
        <div class="col-lg-12 col-md-6 mb-2">
          <div class="col-lg-12">
            <h5 class="text-white mt-3">
              {{ $t('frontApply.payBackTime') }}
            </h5>
            <h3 class="text-white">{{ calculatedPaybackTime }}</h3>
          </div>
        </div>
        <a class="btn style1 mt-1" @click="launchLoanProcess">
          {{ $t('frontApply.askForLoan') }}
        </a>
      </form>

      <div>
        <p>
          {{ $t('frontApply.agreeToTerms') }}
          <router-link to="/privacy-policy" class="link style1">
            {{ $t('frontApply.termsAndPolicy') }}
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getUIFormatDate } from '@/helpers/textFormatter'
import { useRepo } from 'pinia-orm'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { LoanDetails } from '@/models/Models'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'

export default {
  name: 'FrontApply',
  data() {
    return {
      loanAmount: '750',
      selectedDate: new Date().toISOString().substr(0, 10),
      options: ['weekly', 'everyTwoWeeks', 'twiceAMonth', 'monthly'],
      selectedPaybackInterval: 'everyTwoWeeks',
    }
  },
  methods: {
    validateDate() {
      let today = new Date()
      let selected = new Date(this.selectedDate)
      if (selected < today) {
        this.selectedDate = today.toISOString().substr(0, 10)
      }
    },
    launchLoanProcess() {
      if (!this.loggedInUser) {
        document.getElementById('user-register-btn').click()
      } else {
        const loanDetails = useRepo(LoanDetails).all()[0]

        if (!loanDetails || loanDetails.status == '') {
          const loanDetails = {
            initialLoanAmount: this.loanAmount,
            dateLoanRequested: getUIFormatDate(),
            nextPayday: this.selectedDate,
            repayFrequency: this.selectedPaybackInterval,
            minPaymentPerPeriod: this.calculatedPayback,
            loanTermInMonths: this.calculatedPaybackTimeForLoanDetailsInMonths,
            loanTermInWeeks: this.calculatedPaybackTimeForLoanDetailsInWeeks,
            uid: this.loggedInUser.uid,
            email: this.loggedInUser.email,
          }

          const date = new Date()
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
          }
          const formatter = new Intl.DateTimeFormat('en-US', options)
          let dateString = formatter
            .format(date)
            .replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2')
            .replace(',', '')
            .replace(' ', '@')

          console.log(dateString)
          const initialLoanRequestLog = {
            uid: this.loggedInUser.uid,
            displayName: this.loggedInUser.displayName,
            email: this.loggedInUser.email,
            dateTimeNow: dateString,
          }

          const logInitialLoanRequest = httpsCallable(
            functions,
            'logInitialLoanRequest',
          )
          logInitialLoanRequest(initialLoanRequestLog)

          useRepo(LoanDetails).save(loanDetails)

          this.$router.push('/apply-loan')
        } else {
          this.$router.push('/my-dashboard')
        }
      }
    },
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    calculatedPaybackTime() {
      if (this.loanAmount == 750) return '150 jours'
      if (this.loanAmount == 500) return '120 jours'
      if (this.loanAmount == 250) return '90 jours'

      return '150 jours'
    },
    calculatedPaybackTimeForLoanDetailsInWeeks() {
      if (this.loanAmount == 750) return '20'
      if (this.loanAmount == 500) return '16'
      if (this.loanAmount == 250) return '12'

      return 'Loan amount not considered'
    },
    calculatedPaybackTimeForLoanDetailsInMonths() {
      if (this.loanAmount == 750) return '5'
      if (this.loanAmount == 500) return '4'
      if (this.loanAmount == 250) return '3'

      return 'Loan amount not considered'
    },
    calculatedPayback() {
      if (this.loanAmount == 750) {
        if (this.selectedPaybackInterval == 'weekly') return '$59.63'
        if (this.selectedPaybackInterval == 'everyTwoWeeks') return '$119.36'
        if (this.selectedPaybackInterval == 'twiceAMonth') return '$128.89'
        if (this.selectedPaybackInterval == 'monthly') return '$259.02'
      }

      if (this.loanAmount == 500) {
        if (this.selectedPaybackInterval == 'weekly') return '$53.07'
        if (this.selectedPaybackInterval == 'everyTwoWeeks') return '$106.24'
        if (this.selectedPaybackInterval == 'twiceAMonth') return '$113.72'
        if (this.selectedPaybackInterval == 'monthly') return '$233.95'
      }

      if (this.loanAmount == 250) {
        if (this.selectedPaybackInterval == 'weekly') return '$44.54'
        if (this.selectedPaybackInterval == 'everyTwoWeeks') return '$89.08'
        if (this.selectedPaybackInterval == 'twiceAMonth') return '$95.44'
        if (this.selectedPaybackInterval == 'monthly') return '$186.86'
      }

      return '$119.36'
    },
    minDate() {
      const today = new Date()

      // Format the date as "yyyy-mm-dd" for use in the date input field
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const day = today.getDate()
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`

      return formattedDate
    },
  },
}
</script>

<style>
.form-check-input {
  display: none;
}

.radio-number {
  display: block;
  width: 6rem;
  height: 6rem;
  line-height: 6rem;
  text-align: center;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #00a9a4;
  font-size: 2rem;
}

.form-check-label {
  margin-left: 0.5rem;
}
.vue-date-picker {
  width: 200px;
  height: 40px;
  font-size: 20px;
}
.selection-table {
  border-radius: 10px;
  border: 1px solid #ccc;
  margin: 20px auto;
  text-align: center;
}

.selection-table td {
  padding: 10px;
}
</style>

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle :pageTitle="$t('contactUs.shortTitle')" />
      <Contact />
    </div>
  </div>
</template>

<script>
import PageTitle from '../Common/PageTitle'
import Contact from '../Contact/Contact'

export default {
  name: 'ContactUsPage',
  components: {
    PageTitle,
    Contact,
  },
}
</script>

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Blog Details Right  Sidebar" />
      <BlogDetailsRightSidebar />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import BlogDetailsRightSidebar from '@/components/Unused/BlogDetailsRightSidebar/BlogDetailsRightSidebar'

export default {
  name: 'BlogDetailsRightSidebarPage',
  components: {
    PageTitle,
    BlogDetailsRightSidebar,
  },
}
</script>

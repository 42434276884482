<template>
  <div class="feature-wrap pt-100 pb-75">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="feature-card style3">
            <div class="feature-info">
              <div class="feature-title">
                <span
                  ><img
                    src="../../assets/images/feature/feature-icon-1.png"
                    alt="Image"
                /></span>
                <h3>{{ $t('feature.featureTitle1') }}</h3>
              </div>
              <p>
                {{ $t('feature.featureText1') }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="feature-card style3">
            <div class="feature-info">
              <div class="feature-title">
                <span
                  ><img
                    src="../../assets/images/feature/feature-icon-2.png"
                    alt="Image"
                /></span>
                <h3>{{ $t('feature.featureTitle2') }}</h3>
              </div>
              <p>
                {{ $t('feature.featureText2') }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="feature-card style3">
            <div class="feature-info">
              <div class="feature-title">
                <span
                  ><img
                    src="../../assets/images/feature/feature-icon-3.png"
                    alt="Image"
                /></span>
                <h3>{{ $t('feature.featureTitle3') }}</h3>
              </div>
              <p>
                {{ $t('feature.featureText3') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feature',
}
</script>

<template>
  <div class="page-wrapper">
    <MainBanner />
    <Services />
    <PaymentPlatform />
    <Currency />
    <ProtectMoney />
    <FinancialPlanning />
    <GlobalFeatures />
    <AppDownload />
    <Blog />
    <FooterStyleTwo />
  </div>
</template>

<script>
import MainBanner from '@/components/Unused/HomeTwo/MainBanner'
import Services from '@/components/Unused/HomeTwo/Services'
import PaymentPlatform from '@/components/Unused/HomeTwo/PaymentPlatform'
import Currency from '@/components/Unused/HomeTwo/Currency'
import ProtectMoney from '@/components/Unused/HomeTwo/ProtectMoney'
import FinancialPlanning from '@/components/Unused/HomeTwo/FinancialPlanning'
import GlobalFeatures from '@/components/Unused/HomeTwo/GlobalFeatures'
import AppDownload from '@/components/Unused/HomeTwo/AppDownload'
import Blog from '@/components/Unused/HomeTwo/Blog'
import FooterStyleTwo from '@/components/Layouts/FooterStyleTwo'

export default {
  name: 'HomeTwoPage',
  components: {
    MainBanner,
    Services,
    PaymentPlatform,
    Currency,
    ProtectMoney,
    FinancialPlanning,
    GlobalFeatures,
    AppDownload,
    Blog,
    FooterStyleTwo,
  },
}
</script>

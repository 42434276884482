<template>
  <div class="contact-wrap pt-100">
    <div class="container">
      <div class="row justify-content-center pb-75">
        <div class="col-lg-4 col-md-6">
          <div class="contact-item">
            <span class="contact-icon">
              <i class="flaticon-map"></i>
            </span>
            <div class="contact-info">
              <h3>{{ $t('contactUs.ourLocationTitle') }}</h3>
              <p>{{ $t('contactUs.ourLocationText') }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="contact-item">
            <span class="contact-icon">
              <i class="flaticon-email-2"></i>
            </span>
            <div class="contact-info">
              <h3>{{ $t('contactUs.emailUsTitle') }}</h3>
              <a href="mailto:support@pretruby.ca">
                {{ $t('contactUs.emailUsText1') }}@{{
                  $t('contactUs.emailUsText2')
                }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="contact-item">
            <span class="contact-icon">
              <i class="flaticon-phone-call"></i>
            </span>
            <div class="contact-info">
              <h3>{{ $t('contactUs.callUsTitle') }}</h3>
              <a href="tel:88098787868">{{ $t('contactUs.callUsText') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="comp-map pb-100">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2740.478464916077!2d-72.69070574818262!3d46.6173088636782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc65215935ec7d3%3A0x6d6fa150148d6ccf!2s304%20Av.%20de%20Grand-M%C3%A8re%2C%20Grand-M%C3%A8re%2C%20QC%20G9T%202H1!5e0!3m2!1sen!2sca!4v1676856224906!5m2!1sen!2sca"
        ></iframe>
      </div>
    </div>
    <div class="contact-form-area ptb-100 bg-albastor">
      <img
        src="../../assets/images/contact-shape-1.png"
        alt="Image"
        class="contact-shape-one animationFramesTwo"
      />
      <img
        src="../../assets/images/contact-shape-2.png"
        alt="Image"
        class="contact-shape-two bounce"
      />
      <div class="container">
        <div class="row">
          <div class="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
            <div class="content-title style1 text-center mb-40">
              <span>{{ $t('contactUs.contactFormSuperTitle') }}</span>
              <h2>{{ $t('contactUs.contactFormTitle') }}</h2>
            </div>
            <div class="contact-form">
              <form class="form-wrap" @submit.prevent>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        name="name"
                        :placeholder="$t('contactUs.contactFormName')"
                        id="name"
                        required
                        data-error="Please enter your name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        :placeholder="$t('contactUs.contactFormEmail')"
                        data-error="Please enter your email*"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="number"
                        name="phone"
                        id="phone"
                        required
                        :placeholder="$t('contactUs.contactFormPhoneNumber')"
                        data-error="Please enter your phone number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        name="msg_subject"
                        :placeholder="$t('contactUs.contactFormSubject')"
                        id="msg_subject"
                        required
                        data-error="Please enter your subject"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group v1">
                      <textarea
                        name="message"
                        id="message"
                        :placeholder="$t('contactUs.contactFormMessageFill')"
                        cols="30"
                        rows="10"
                        required
                        data-error="Please enter your message"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button type="submit" class="btn style1 w-100 d-block">
                      {{ $t('contactUs.contactFormSend') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
}
</script>

<template>
  <form v-if="loggedInUser" @submit.prevent>
    <div class="apply-loan-content-box">
      <div class="apply-loan-content-box-action" @click="backButtonClicked">
        <i class="ri-arrow-left-s-line"></i>
        {{ $t('bankDetailsForm.formTitle').toUpperCase() }}
      </div>
      <div class="apply-loan-content-box-inner">
        <div class="apply-loan-content-box-header">
          <h5 class="apply-loan-content-box-header-title">
            {{ $t('secondaryContact') }}
          </h5>
          <div class="apply-loan-content-box-header-desc">
            {{ $t('secondaryContactForm.formSubtitle') }}
          </div>
        </div>
        <div class="apply-loan-content-box-body">
          <div class="form-group">
            <label>
              {{ $t('firstName') }}
            </label>
            <input
              type="text"
              required
              v-model="inFormSecondaryContactDetails.firstName"
            />
          </div>
          <div class="form-group">
            <label>
              {{ $t('lastName') }}
            </label>
            <input
              type="text"
              required
              v-model="inFormSecondaryContactDetails.lastName"
            />
          </div>
          <div class="form-group">
            <label> {{ $t('phoneNumber') }}</label>
            <input
              type="tel"
              required
              v-model="inFormSecondaryContactDetails.phoneNumber"
            />
          </div>

          <div class="form-group">
            <label>
              {{ $t('relationToUser') }}
            </label>
            <select
              required
              v-model="inFormSecondaryContactDetails.relationToUser"
            >
              <option value="">{{ $t('jobDetailsForm.select') }}</option>
              <option value="Spouse">
                {{ $t('secondaryContactForm.spouse') }}
              </option>
              <option value="Father">
                {{ $t('secondaryContactForm.father') }}
              </option>
              <option value="Mother">
                {{ $t('secondaryContactForm.mother') }}
              </option>
              <option value="Brother">
                {{ $t('secondaryContactForm.brother') }}
              </option>
              <option value="Sister">
                {{ $t('secondaryContactForm.sister') }}
              </option>
              <option value="Other">
                {{ $t('secondaryContactForm.other') }}
              </option>
            </select>
          </div>

          <button
            type="submit"
            class="btn style1 w-100 d-block"
            :disabled="disableContinue"
            @click="processSecondaryContactJobDetailsForm"
          >
            {{ $t('sharedFormTexts.next').toUpperCase() }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { getServerFormatDate } from '@/helpers/textFormatter'
import { SecondaryContactDetails, LoanDetails } from '@/models/Models'
import { mapState } from 'pinia'
import { useRepo } from 'pinia-orm'
import { useAuthStore } from '@/store/auth'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'SecondaryContactDetailsForm',
  data() {
    return {
      inFormSecondaryContactDetails: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        relationToUser: '',
      },
    }
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    disableContinue() {
      return (
        this.inFormSecondaryContactDetails.firstName === '' ||
        this.inFormSecondaryContactDetails.lastName === '' ||
        this.inFormSecondaryContactDetails.phoneNumber === '' ||
        this.inFormSecondaryContactDetails.relationToUser === ''
      )
    },
  },
  methods: {
    processSecondaryContactJobDetailsForm() {
      if (this.loggedInUser) {
        const secondaryContactDetails = {
          firstName: this.inFormSecondaryContactDetails.firstName,
          lastName: this.inFormSecondaryContactDetails.lastName,
          phoneNumber: this.inFormSecondaryContactDetails.phoneNumber,
          relationToUser: this.inFormSecondaryContactDetails.relationToUser,
          transferMethod: '',
          createTime: getServerFormatDate(),
          updateTime: getServerFormatDate(),
          email: this.loggedInUser.email,

          uid: this.loggedInUser.uid,
        }

        useRepo(SecondaryContactDetails).save(secondaryContactDetails)
        const createdStore = useRepo(SecondaryContactDetails).all()[0]

        if (
          createdStore.firstName &&
          createdStore.lastName &&
          createdStore.phoneNumber &&
          createdStore.relationToUser
        ) {
          this.secondaryContactDetailsSubmitClicked()
        }
      }
    },
    backButtonClicked() {
      this.$emit('backButtonClicked', 1, 4)
    },
    secondaryContactDetailsSubmitClicked() {
      this.$emit('secondaryContactDetailsSubmitClicked')
    },
  },
  setup() {
    const router = useRouter()

    onMounted(() => {
      const loanDetails = useRepo(LoanDetails).all()
      if (loanDetails.length === 0) {
        router.push('/')
      }
    })
  },
}
</script>

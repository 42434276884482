<template>
  <div class="users-page-wrap ptb-100">
    <div class="container">
      <div class="row gx-5">
        <div class="col-12">
          <div class="page-header">
            <h3>Users</h3>
          </div>
          <div class="page-subheader">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" href="#all">All</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#active">Active</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#banned">Banned</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#email-verified">Email Verified</a>
              </li>
            </ul>
          </div>
          <div class="page-content">
            <div class="tab-content">
              <div class="tab-pane active" id="all">
                <div class="search-form">
                  <input type="text" placeholder="Search" />
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Contact Info</th>
                      <th>Country</th>
                      <th>Member Since</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in users" :key="user.id">
                      <td>{{ user.username }}</td>
                      <td>{{ user.contact_info }}</td>
                      <td>{{ user.country }}</td>
                      <td>{{ user.member_since }}</td>
                      <td>
                        <router-link :to="`/users/${user.id}`">
                          View
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane" id="active">
                <div class="search-form">
                  <input type="text" placeholder="Search" />
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Contact Info</th>
                      <th>Country</th>
                      <th>Member Since</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in activeUsers" :key="user.id">
                      <td>{{ user.username }}</td>
                      <td>{{ user.contact_info }}</td>
                      <td>{{ user.country }}</td>
                      <td>{{ user.member_since }}</td>
                      <td>
                        <router-link :to="`/users/${user.id}`">
                          View
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane" id="banned">
                <div class="search-form">
                  <input type="text" placeholder="Search" />
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Contact Info</th>
                      <th>Country</th>
                      <th>Member Since</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in bannedUsers" :key="user.id">
                      <td>{{ user.username }}</td>
                      <td>{{ user.contact_info }}</td>
                      <td>{{ user.country }}</td>
                      <td>{{ user.member_since }}</td>
                      <td>
                        <router-link :to="`/users/${user.id}`">
                          View
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane" id="email-verified">
                <div class="search-form">
                  <input type="text" placeholder="Search" />
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Contact Info</th>
                      <th>Country</th>
                      <th>Member Since</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in emailVerifiedUsers" :key="user.id">
                      <td>{{ user.username }}</td>
                      <td>{{ user.contact_info }}</td>
                      <td>{{ user.country }}</td>
                      <td>{{ user.member_since }}</td>
                      <td>
                        <router-link :to="`/users/${user.id}`">
                          View
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdminUsersView',
  data() {
    return {
      users: [
        {
          id: 1,
          username: 'John Doe',
          contact_info: 'johndoe@example.com',
          country: 'United States',
          member_since: 'Jan 1, 2020',
        },
        {
          id: 2,
          username: 'Jane Doe',
          contact_info: 'janedoe@example.com',
          country: 'Canada',
          member_since: 'Feb 1, 2020',
        },
        {
          id: 3,
          username: 'Jim Smith',
          contact_info: 'jimsmith@example.com',
          country: 'United Kingdom',
          member_since: 'Mar 1, 2020',
        },
      ],
    }
  },
  computed: {
    activeUsers() {
      return this.users.filter((user) => user.status === 'active')
    },
    bannedUsers() {
      return this.users.filter((user) => user.status === 'banned')
    },
    emailVerifiedUsers() {
      return this.users.filter((user) => user.email_verified === true)
    },
  },
}
</script>

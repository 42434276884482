<template>
  <form v-if="loggedInUser" @submit.prevent>
    <div class="apply-loan-content-box">
      <div class="apply-loan-content-box-action" @click="backButtonClicked">
        <i class="ri-arrow-left-s-line"></i>
        {{ $t('contactDetailsForm.formTitle').toUpperCase() }}
      </div>
      <div class="apply-loan-content-box-inner">
        <div class="apply-loan-content-box-header">
          <h5 class="apply-loan-content-box-header-title">
            {{ $t('jobDetailsForm.formTitle') }}
          </h5>
          <div class="apply-loan-content-box-header-desc">
            {{ $t('jobDetailsForm.subTitle') }}
          </div>
        </div>
        <div class="apply-loan-content-box-body">
          <div class="form-group">
            <label> {{ $t('jobDetailsForm.sourceOfIncome') }}</label>
            <select required v-model="inFormJobDetails.sourceOfIncome">
              <option value="">{{ $t('jobDetailsForm.select') }}</option>
              <option value="Employment insurance">
                {{ $t('jobDetailsForm.employmentInsurance') }}
              </option>
              <option value="Q.P.I.P.">{{ $t('jobDetailsForm.qpip') }}</option>
              <option value="Self-Employed">
                {{ $t('jobDetailsForm.selfEmployed') }}
              </option>
              <option value="Employed">
                {{ $t('jobDetailsForm.employed') }}
              </option>
              <option value="C.S.S.T. For Life">
                {{ $t('jobDetailsForm.permanentCSST') }}
              </option>
              <option value="S.A.A.Q. Temporary">
                {{ $t('jobDetailsForm.temporarySAAQ') }}
              </option>
              <option value="S.A.A.Q. For Life">
                {{ $t('jobDetailsForm.permanentSAAQ') }}
              </option>
              <option value="Social Assistance">
                {{ $t('jobDetailsForm.socialInsurance') }}
              </option>
              <option value="C.S.S.T. Temporary">
                {{ $t('jobDetailsForm.temporaryCSST') }}
              </option>
            </select>
          </div>

          <div
            class="form-group"
            v-if="inFormJobDetails.sourceOfIncome == 'Employment insurance'"
          >
            <label> {{ $t('jobDetailsForm.socialInsuranceStartDate') }} </label>
            <input
              type="date"
              required
              v-model="inFormJobDetails.employmentInsuranceStartDate"
            />
          </div>

          <div
            class="form-group"
            v-else-if="inFormJobDetails.sourceOfIncome == 'Self-Employed'"
          >
            <label> {{ $t('jobDetailsForm.paidByDirectDeposit') }} </label>
            <div class="radio_box">
              <div class="radio style3">
                <input
                  type="radio"
                  value="Yes"
                  v-model="inFormJobDetails.isPaidByDirectDeposit"
                />
                <label>{{ $t('sharedFormTexts.yes') }}</label>
              </div>

              <div class="radio style3">
                <input
                  type="radio"
                  checked
                  value="No"
                  v-model="inFormJobDetails.isPaidByDirectDeposit"
                />
                <label>{{ $t('sharedFormTexts.no') }}</label>
              </div>
            </div>
          </div>
          <div v-else-if="inFormJobDetails.sourceOfIncome == 'Employed'">
            <div class="form-group">
              <label> {{ $t('jobDetailsForm.employerName') }} </label>
              <input
                type="text"
                required
                v-model="inFormJobDetails.employerName"
              />
            </div>
            <div class="form-group">
              <label> {{ $t('jobDetailsForm.employerPhoneNumber') }} </label>
              <input
                type="tel"
                required
                v-model="inFormJobDetails.employerPhoneNumber"
              />
            </div>
            <div class="form-group">
              <label> {{ $t('jobDetailsForm.jobDescription') }} </label>
              <input
                type="text"
                required
                v-model="inFormJobDetails.jobDescription"
              />
            </div>
            <div class="form-group">
              <label> {{ $t('jobDetailsForm.supervisorName') }} </label>
              <input
                type="text"
                required
                v-model="inFormJobDetails.supervisorName"
              />
            </div>
            <div class="form-group">
              <label> {{ $t('jobDetailsForm.hiringDate') }} </label>
              <input
                type="date"
                required
                v-model="inFormJobDetails.dateOfEmployment"
              />
            </div>
          </div>

          <button
            type="submit"
            class="btn style1 w-100 d-block"
            @click="ProcessJobDetailsForm"
            :disabled="disableContinue"
          >
            {{ $t('sharedFormTexts.next').toUpperCase() }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { getServerFormatDate, formatDate } from '@/helpers/textFormatter'
import { JobDetails, LoanDetails } from '@/models/Models'
import { mapState } from 'pinia'
import { useRepo } from 'pinia-orm'
import { useAuthStore } from '@/store/auth'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'JobDetailsForm',
  data() {
    return {
      inFormJobDetails: { sourceOfIncome: '' },
    }
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    disableContinue() {
      return this.inFormJobDetails.sourceOfIncome === ''
    },
  },
  methods: {
    ProcessJobDetailsForm() {
      if (this.loggedInUser) {
        const jobDetails = {
          sourceOfIncome: this.inFormJobDetails.sourceOfIncome,
          employmentInsuranceStartDate:
            this.inFormJobDetails.employmentInsuranceStartDate,
          isPaidByDirectDeposit: this.inFormJobDetails.isPaidByDirectDeposit,
          employerName: this.inFormJobDetails.employerName,
          supervisorName: this.inFormJobDetails.supervisorName,
          employerPhoneNumber: this.inFormJobDetails.employerPhoneNumber,
          jobDescription: this.inFormJobDetails.jobDescription,
          dateOfEmployment: this.inFormJobDetails.dateOfEmployment
            ? formatDate(this.inFormJobDetails.dateOfEmployment)
            : '',
          createTime: getServerFormatDate(),
          updateTime: getServerFormatDate(),
          email: this.loggedInUser.email,

          uid: this.loggedInUser.uid,
        }

        useRepo(JobDetails).save(jobDetails)
        const createdStore = useRepo(JobDetails).all()[0]

        if (createdStore.sourceOfIncome) {
          this.jobDetailsSubmitClicked()
        }
      }
    },
    backButtonClicked() {
      this.$emit('backButtonClicked', 1, 2)
    },
    jobDetailsSubmitClicked() {
      this.$emit('jobDetailsSubmitClicked')
    },
  },
  setup() {
    const router = useRouter()

    onMounted(() => {
      const loanDetails = useRepo(LoanDetails).all()
      if (loanDetails.length === 0) {
        router.push('/')
      }
    })
  },
}
</script>

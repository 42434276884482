<template>
  <div class="page-wrapper">
    <div v-if="loggedInUser" class="content-wrapper">
      <div class="container">
        <LoanSteps :currentStep="currentStep" :steps="steps" />
        <div class="apply-loan-content account-form">
          <div v-if="currentStep == 1">
            <PersonalDetailsForm
              v-if="detailCollectionSetp == 1"
              @personalDetailsSubmitClicked="goToContactDetails"
            />
            <ContactDetailsForm
              v-else-if="detailCollectionSetp == 2"
              @backButtonClicked="goToStep"
              @contactDetailsSubmitClicked="goToJobDetails"
            />
            <JobDetailsForm
              v-else-if="detailCollectionSetp == 3"
              @backButtonClicked="goToStep"
              @jobDetailsSubmitClicked="goToBankDetails"
            />
            <BankDetailsForm
              v-else-if="detailCollectionSetp == 4"
              @backButtonClicked="goToStep"
              @bankDetailsSubmitClicked="goToSecondaryContactDetails"
            />
            <SecondaryContactDetailsForm
              v-else-if="detailCollectionSetp == 5"
              @backButtonClicked="goToStep"
              @secondaryContactDetailsSubmitClicked="goToIBV"
            />
          </div>
          <div v-if="currentStep == 2">
            <IBVForm
              @backButtonClicked="goToStep"
              @ibvFormSubmitClicked="goToContract"
            />
          </div>
          <div v-if="currentStep == 3">
            <ContractForm
              v-if="detailCollectionSetp == 5"
              @backButtonClicked="goToStep"
              @contractFormSubmitClicked="goToTransferMethod"
            />
            <TransferMethodForm
              v-if="detailCollectionSetp == 6"
              @backButtonClicked="goToStep"
              @transferMethodFormSubmitClicked="goToApplicationSubmitted"
            />
          </div>
          <div v-if="currentStep == 4">
            <ApplicationSubmittedForm />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import LoanSteps from '@/components/Apply/LoanSteps'
import PersonalDetailsForm from '@/components/Apply/PersonalDetailsForm'
import ContactDetailsForm from '@/components/Apply/ContactDetailsForm'
import JobDetailsForm from '@/components/Apply/JobDetailsForm'
import BankDetailsForm from '@/components/Apply/BankDetailsForm'
import SecondaryContactDetailsForm from '@/components/Apply/SecondaryContactDetailsForm'
import IBVForm from '@/components/Apply/IBVForm'
import ContractForm from '@/components/Apply/ContractForm'
import TransferMethodForm from '@/components/Apply/TransferMethodForm'
import ApplicationSubmittedForm from '@/components/Apply/ApplicationSubmittedForm'
import ErrorPage from '@/components/Pages/ErrorPage'
import { LoanDetails } from '@/models/Models'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'

export default {
  name: 'ApplyPage',
  components: {
    LoanSteps,
    PersonalDetailsForm,
    ContactDetailsForm,
    JobDetailsForm,
    BankDetailsForm,
    SecondaryContactDetailsForm,
    IBVForm,
    ContractForm,
    TransferMethodForm,
    ApplicationSubmittedForm,
    ErrorPage,
  },
  data() {
    return {
      // TO-DO change back to 1
      currentStep: 1,
      detailCollectionSetp: 1,
      steps: [
        {
          id: 1,
          heading: this.$t('applyPage.step1'),
        },
        {
          id: 2,
          heading: this.$t('applyPage.step2'),
        },
        {
          id: 3,
          heading: this.$t('applyPage.step3'),
        },
        {
          id: 4,
          heading: this.$t('applyPage.step4'),
        },
      ],
    }
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
  methods: {
    printRoute() {
      if (this.loggedInUser) {
        console.log(LoanDetails.query().where('uid', this.loggedInUser.uid))
      }
    },
    goToStep(step, detailCollectionSetp) {
      this.detailCollectionSetp = detailCollectionSetp
      this.currentStep = step
      this.scrollToTop()
    },
    goToContactDetails() {
      this.detailCollectionSetp = 2
      this.scrollToTop()
    },
    goToJobDetails() {
      this.detailCollectionSetp = 3
      this.scrollToTop()
    },
    goToBankDetails() {
      this.detailCollectionSetp = 4
      this.scrollToTop()
    },
    goToSecondaryContactDetails() {
      this.detailCollectionSetp = 5
      this.scrollToTop()
    },
    goToIBV() {
      this.currentStep = 2
      this.scrollToTop()
    },
    goToContract() {
      this.currentStep = 3
      this.scrollToTop()
    },
    goToTransferMethod() {
      this.currentStep = 3
      this.detailCollectionSetp = 6
      this.scrollToTop()
    },
    goToApplicationSubmitted() {
      this.currentStep = 4
      this.detailCollectionSetp = 7
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },
}
</script>

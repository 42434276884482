const options = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: true,
  timeZoneName: 'short',
}

export function getServerFormatDate() {
  const date = new Date()
  return date.toLocaleString('en-US', options)
}

export function formatDate(date) {
  console.log(date)
  const outDate = new Date(date)

  return outDate.toLocaleString('en-US', options)
}

export function getUIFormatDate() {
  const date = new Date()
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }
  return date.toLocaleString('fr-CA', options)
}

<template>
  <div v-if="loggedInUser" class="admin-dashboard-wrap">
    <div class="container">
      <h1 class="text-center mb-5">Admin Dashboard</h1>
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-12">
            <div class="card-header d-flex align-items-center">
              <h5 class="mb-0">Welcome to the Admin Dashboard</h5>
              <button
                class="btn style6 ms-auto"
                @click="refreshDashboard"
                label="refresh"
              >
                {{ $t('refresh') }}
                <img
                  src="@/assets/images/arrow-clockwise.svg"
                  alt="Refresh icon"
                />
              </button>
            </div>
            <div class="card-body wrap">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t('email') }}</th>
                    <th>{{ $t('numberOfAccountsLinked') }}</th>
                    <th>{{ $t('requestID') }}</th>
                    <th>{{ $t('expand') }}</th>
                  </tr>
                </thead>
                <tbody v-for="(balance, index) in balanceDetails" :key="index">
                  <tr>
                    <td>{{ balance.email }}</td>
                    <td>{{ balance.accounts.length }}</td>
                    <td>{{ balance.request_id }}</td>
                    <td>
                      <b-button v-b-toggle="'collapse-' + index.toString()">
                        {{ $t('expand') }}
                      </b-button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <b-collapse
                        :id="'collapse-' + index.toString()"
                        class="mt-2"
                      >
                        <UserTransactionsDetailsCard
                          :accounts="balance.accounts"
                          :transactions="transactions"
                        />
                      </b-collapse>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'
import UserTransactionsDetailsCard from '@/components/Admin/UserTransactionsDetailsCard'

export default {
  name: 'UserTransactions',
  components: {
    UserTransactionsDetailsCard,
  },
  data: function () {
    return {
      balanceDetails: [],
      userIsAdmin: false,
      deleteUID: '',
    }
  },
  mounted() {
    this.fetchAllLoan()
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
  methods: {
    fetchAllLoan() {
      const testAccountsBalanceGet = httpsCallable(
        functions,
        'testAccountsBalanceGet',
      )
      testAccountsBalanceGet()
        .then((result) => {
          const sortedBalanceDetails = result.data.sort((a, b) => {
            return a.email.localeCompare(b.email)
          })
          this.balanceDetails = sortedBalanceDetails
          console.log(this.balanceDetails)
        })
        .catch((error) => {
          console.error(error)
        })

      const transactionsGet = httpsCallable(functions, 'transactionsGet')
      transactionsGet()
        .then((result) => {
          const sortedBalanceDetails = result.data.sort((a, b) => {
            return a.email.localeCompare(b.email)
          })
          this.transactions = sortedBalanceDetails
          console.log(this.transactions)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    refreshDashboard() {
      this.fetchAllLoan()
    },
  },
}
</script>

<style>
.admin-dashboard-wrap {
  padding: 50px 0;
}

.nav-link.active {
  color: #007bff;
}
</style>

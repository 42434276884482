import { Model } from 'pinia-orm'

class LoanDetails extends Model {
  static entity = 'loandetails'
  static primaryKey = 'uid'

  static fields() {
    return {
      status: this.attr(''),
      apr: this.attr(''),
      dailyInterest: this.attr(() => Number(0.0005205479452054794)),
      subscriptionFee: this.attr(() => Number(23)),
      previousLoans: this.attr([]),
      contractMade: this.attr(false),
      principle: this.attr(null),
      minPaymentPerPeriod: this.attr(null),
      loanTermInMonths: this.attr(null),
      loanTermInWeeks: this.attr(null),
      daysPaymentPeriods: this.attr(null),
      initialLoanAmount: this.attr(null),
      amountPayedBack: this.attr(null),
      repayFrequency: this.attr(''),
      dateLoanRequested: this.attr(null),
      dateContractMade: this.attr(null),
      dateFirstPaymentMade: this.attr(null),
      nextPayday: this.attr(null),
      invoiceID: this.attr(null),
      accountName: this.attr(null),
      institutionNumber: this.attr(null),
      transitNumber: this.attr(null),
      accountNumber: this.attr(null),
      contracts: this.attr([]),
      previousStatements: this.attr([]),
      contractUrl: this.attr(''),
      contractID: this.attr(''),
      createTime: this.attr(''),
      updateTime: this.attr(''),
      dateCompleted: this.attr(''),
      email: this.attr(''),
      approvedBy: this.attr(''),

      uid: this.attr(''),
    }
  }
}

class User extends Model {
  static entity = 'user'
  static primaryKey = 'uid'

  static fields() {
    return {
      uid: this.attr(''),
      isAdmin: this.attr(''),
      createTime: this.attr(null),
      updateTime: this.attr(''),
      email: this.attr(''),

      personalDetails: this.hasOne(PersonalDetails, 'uid'),
      contactDetails: this.hasOne(ContactDetails, 'uid'),
      secondaryContactDetails: this.hasOne(SecondaryContactDetails, 'uid'),
      jobDetails: this.hasOne(JobDetails, 'uid'),
      bankDetails: this.hasOne(BankDetails, 'uid'),

      loans: this.hasMany(LoanDetails, 'uid'),
    }
  }
}

class PlaidDetails extends Model {
  static entity = 'plaiddetails'
  static primaryKey = 'uid'

  static fields() {
    return {
      accessToken: this.attr(''),
      email: this.attr(''),

      uid: this.attr(''),
    }
  }
}

class PersonalDetails extends Model {
  static entity = 'personaldetails'
  static primaryKey = 'uid'

  static fields() {
    return {
      firstName: this.attr(''),
      lastName: this.attr(''),
      dateOfBirth: this.attr(''),
      socialInsuranceNumber: this.attr(''),
      createTime: this.attr(null),
      updateTime: this.attr(null),
      email: this.attr(''),

      uid: this.attr(null),
    }
  }
}

class ContactDetails extends Model {
  static entity = 'contactdetails'
  static primaryKey = 'uid'

  static fields() {
    return {
      cellPhoneNumber: this.attr(''),
      homePhoneNumber: this.attr(''),
      addressLine1: this.attr(''),
      addressLine2: this.attr(''),
      city: this.attr(''),
      province: this.attr(''),
      postalCode: this.attr(''),
      createTime: this.attr(null),
      updateTime: this.attr(null),
      email: this.attr(''),

      uid: this.attr(null),
    }
  }
}

class SecondaryContactDetails extends Model {
  static entity = 'secondarycontactdetails'
  static primaryKey = 'uid'

  static fields() {
    return {
      firstName: this.attr(''),
      lastName: this.attr(''),
      phoneNumber: this.attr(''),
      relationToUser: this.attr(''),
      createTime: this.attr(null),
      updateTime: this.attr(null),
      email: this.attr(''),

      uid: this.attr(null),
    }
  }
}

class JobDetails extends Model {
  static entity = 'jobdetails'
  static primaryKey = 'uid'

  static fields() {
    return {
      sourceOfIncome: this.attr(''),
      employmentInsuranceStartDate: this.attr(''),
      isPaidByDirectDeposit: this.attr(false),
      employerName: this.attr(''),
      supervisorName: this.attr(''),
      employerPhoneNumber: this.attr(''),
      jobDescription: this.attr(''),
      dateOfEmployment: this.attr(''),
      createTime: this.attr(null),
      updateTime: this.attr(null),
      email: this.attr(''),

      uid: this.attr(null),
    }
  }
}

class BankDetails extends Model {
  static entity = 'bankdetails'
  static primaryKey = 'uid'

  static fields() {
    return {
      userBankName: this.attr(''),
      branchNumber: this.attr(''),
      acctNumber: this.attr(''),
      planningBankruptcy: this.attr(false),
      hadBankruptcy: this.attr(false),
      transferMethod: this.attr(''),
      createTime: this.attr(null),
      updateTime: this.attr(null),
      email: this.attr(''),

      uid: this.attr(null),
    }
  }
}

class InitialLoanRequestLog extends Model {
  static entity = 'initialloanrequestlog'
  static primaryKey = 'uid'

  static fields() {
    return {
      displayName: this.attr(''),
      email: this.attr(''),

      uid: this.attr(null),
    }
  }
}

export {
  LoanDetails,
  User,
  PersonalDetails,
  ContactDetails,
  SecondaryContactDetails,
  JobDetails,
  BankDetails,
  InitialLoanRequestLog,
  PlaidDetails,
}

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle :pageTitle="$t('faq.title')" />
      <Faq />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import Faq from '@/components/Faq/Faq'

export default {
  name: 'FaqPage',
  components: {
    PageTitle,
    Faq,
  },
}
</script>

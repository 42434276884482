<template>
  <div class="page-wrapper">
    <div v-if="loggedInUser" class="content-wrapper">
      <PageTitle :pageTitle="$t('navbar.userCalendar')" />
      <MyCalendar />
    </div>
    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import PageTitle from '../Common/PageTitle'
import MyCalendar from '../Account/MyCalendar'
import ErrorPage from '@/components/Pages/ErrorPage'

export default {
  name: 'MyAccountPage',
  components: {
    PageTitle,
    MyCalendar,
    ErrorPage,
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
}
</script>

<template>
  <div v-if="loggedInUser" class="apply-loan-content-box">
    <div class="apply-loan-content-box-action" @click="backButtonClicked">
      <i class="ri-arrow-left-s-line"></i>
      {{ $t('ibvForm.formTitle').toUpperCase() }}
    </div>
    <div class="apply-loan-content-box-inner">
      <div class="apply-loan-content-box-header">
        <h5 class="apply-loan-content-box-header-title">
          {{ $t('contractForm.formTitle') }}
        </h5>
        <div class="apply-loan-content-box-header-desc">
          {{ $t('contractForm.formSubtitle') }}
        </div>
      </div>

      <div class="apply-loan-content-box-body">
        <div v-if="contractUrl != ''">
          <p class="apply-loan-content-box-helper">
            <i class="ri-arrow-down-fill"></i> I'm Scroll to the bottom of the
            contract to sign digitall)y
          </p>
          <iframe :src="contractUrl"></iframe>
        </div>
        <div>
          <button
            @click="ProcessContractDetailsForm"
            type="button"
            class="btn style1 mt-3 w-100 d-block"
            :disabled="disableContinue"
          >
            {{ $t('sharedFormTexts.next').toUpperCase() }}
          </button>
        </div>
        <!-- end bank Info -->
      </div>
    </div>
  </div>
</template>

<script>
import { getServerFormatDate } from '@/helpers/textFormatter'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { useRepo } from 'pinia-orm'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'
import {
  BankDetails,
  ContactDetails,
  LoanDetails,
  PersonalDetails,
} from '@/models/Models'

export default {
  name: 'ContractForm',
  data() {
    return {
      disableContinue: true,
      inContractForm: {},
      contractUrl: '',
    }
  },
  mounted() {
    this.generateContract()
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
  methods: {
    ProcessContractDetailsForm() {
      // TO-DO save missing loan details
      if (this.loggedInUser) {
        let loanDetails = useRepo(LoanDetails).all()[0]
        useRepo(LoanDetails).save(loanDetails)

        this.contractFormSubmitClicked()
      }
    },
    backButtonClicked() {
      this.$emit('backButtonClicked', 2, 5)
    },
    contractFormSubmitClicked() {
      this.$emit('contractFormSubmitClicked')
    },
    generateContract() {
      const bankDetails = useRepo(BankDetails).all()[0]
      const contactDetails = useRepo(ContactDetails).all()[0]
      const personalDetails = useRepo(PersonalDetails).all()[0]

      let loanDetails = useRepo(LoanDetails).all()[0]
      this.contractUrl = loanDetails.contractUrl

      if (this.contractUrl !== '') {
        this.disableContinue = false
        return
      }

      let signParma = {
        signers: [
          {
            name: personalDetails.lastName + ' ' + personalDetails.firstName,
            mobile: contactDetails.cellPhoneNumber,
            email: contactDetails.email,
            auto_sign: 'no',
            embedded_sign_page: 'yes',
            embedded_redirect_iframe_only: 'yes',
            signature_request_delivery_method: 'embedded',
          },
        ],
        test: 'no', //TO-DO FiX the damn values
        placeholder_fields: [
          { api_key: 'date', value: getServerFormatDate() },
          {
            api_key: 'name',
            value: personalDetails.lastName + ' ' + personalDetails.firstName,
          },
          {
            api_key: 'address',
            value:
              contactDetails.addressLine1 +
              ', ' +
              contactDetails.addressLine2 +
              ' ' +
              contactDetails.city +
              ' ' +
              contactDetails.postalCode,
          },
          { api_key: 'city', value: contactDetails.city },
          {
            api_key: 'fileNumber',
            value: loanDetails.uid,
          },
          { api_key: 'amount', value: loanDetails.initialLoanAmount },
          { api_key: 'principle', value: loanDetails.minPaymentPerPeriod },
          { api_key: 'regularity', value: loanDetails.repayFrequency },
          { api_key: 'dateFirstPayment', value: loanDetails.nextPayday },
          { api_key: 'numWeeks', value: loanDetails.loanTermInWeeks },
          { api_key: 'phone', value: contactDetails.cellPhoneNumber },
          {
            api_key: 'institutionNumber',
            value: bankDetails.institutionNumber,
          },
          { api_key: 'branchNumber', value: bankDetails.branchNumber },
          { api_key: 'acctNumber', value: bankDetails.acctNumber },
          { api_key: 'email', value: this.loggedInUser.email },
        ],
      }

      console.log(signParma.data)

      const createLoanContract = httpsCallable(functions, 'createLoanContract')
      createLoanContract(signParma).then((response) => {
        console.log('data: ')
        console.log(response.data)

        this.contractUrl = response.data.signers[0].sign_page_url
        this.contracId = response.data.id

        loanDetails.status = response.data.status
        loanDetails.contractUrl = response.data.signers[0].sign_page_url
        loanDetails.contractID = response.data.id
        useRepo(LoanDetails).save(loanDetails)

        this.disableContinue = false
      })
    },
  },
}
</script>

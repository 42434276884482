<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Open Account" />
      <Account />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import Account from '@/components/Unused/Account/Account'

export default {
  name: 'AccountPage',
  components: {
    PageTitle,
    Account,
  },
}
</script>

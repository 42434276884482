<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Blog Details Left Sidebar" />
      <BlogDetailsLeftSidebar />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import BlogDetailsLeftSidebar from '@/components/Unused/BlogDetailsLeftSidebar/BlogDetailsLeftSidebar'

export default {
  name: 'BlogDetailsLeftSidebarPage',
  components: {
    PageTitle,
    BlogDetailsLeftSidebar,
  },
}
</script>

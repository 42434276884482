<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Blog Grid" />
      <Blog />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import Blog from '@/components/Unused/Blog/Blog'

export default {
  name: 'BlogPage',
  components: {
    PageTitle,
    Blog,
  },
}
</script>

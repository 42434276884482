<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Mention légale" />
      <PrivacyPolicy />
    </div>
  </div>
</template>

<script>
import PageTitle from '../Common/PageTitle'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'

export default {
  name: 'PrivacyPolicyPage',
  components: {
    PageTitle,
    PrivacyPolicy,
  },
}
</script>

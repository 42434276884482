<template>
  <form v-if="loggedInUser" @submit.prevent v-on:submit="bankInfoSubmit">
    <div class="apply-loan-content-box">
      <div class="apply-loan-content-box-action" @click="backButtonClicked">
        <i class="ri-arrow-left-s-line"></i>
        {{ $t('jobDetailsForm.formTitle').toUpperCase() }}
      </div>
      <div class="apply-loan-content-box-inner">
        <div class="apply-loan-content-box-header">
          <h5 class="apply-loan-content-box-header-title">
            {{ $t('bankDetailsForm.formTitle') }}
          </h5>
          <div class="apply-loan-content-box-header-desc">
            {{ $t('bankDetailsForm.formSubtitle') }}
          </div>
        </div>
        <div class="apply-loan-content-box-body">
          <div class="form-group">
            <label>{{ $t('bankDetailsForm.chooseBank') }}</label>
            <select required v-model="inFormBankDetails.userBankName">
              <option value="">{{ $t('jobDetailsForm.select') }}</option>
              <option value="Bank of Montreal">
                {{ $t('bankDetailsForm.bmo') }}
              </option>
              <option value="The Bank of Nova Scotia">
                {{ $t('bankDetailsForm.bns') }}
              </option>
              <option value="Royal Bank of Canada">
                {{ $t('bankDetailsForm.rbc') }}
              </option>
              <option value="TD Canada Trust">
                {{ $t('bankDetailsForm.td') }}
              </option>
              <option value=">National Bank of Canada">
                {{ $t('bankDetailsForm.nb') }}
              </option>
              <option value="CIBC">{{ $t('bankDetailsForm.cibc') }}</option>
              <option value="Laurentian Bank of Canada">
                {{ $t('bankDetailsForm.lbc') }}
              </option>
              <option value="Desjardins">
                {{ $t('bankDetailsForm.des') }}
              </option>
              <option value="Tangerine">{{ $t('bankDetailsForm.tan') }}</option>
              <option value="Other">{{ $t('bankDetailsForm.other') }}</option>
            </select>
          </div>

          <div class="form-group">
            <label>
              {{ $t('bankDetailsForm.planningBankruptcy') }}
            </label>
            <div class="radio_box">
              <div class="radio style3">
                <input
                  type="radio"
                  value="Yes"
                  v-model="inFormBankDetails.planningBankruptcy"
                />
                <label>{{ $t('sharedFormTexts.yes') }}</label>
              </div>

              <div class="radio style3">
                <input
                  type="radio"
                  checked
                  value="No"
                  v-model="inFormBankDetails.planningBankruptcy"
                />
                <label>{{ $t('sharedFormTexts.no') }}</label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>
              {{ $t('bankDetailsForm.hadBankruptcy') }}
            </label>
            <div class="radio_box">
              <div class="radio style3">
                <input
                  type="radio"
                  value="Yes"
                  v-model="inFormBankDetails.hadBankruptcy"
                />
                <label>{{ $t('sharedFormTexts.yes') }}</label>
              </div>

              <div class="radio style3">
                <input
                  type="radio"
                  checked
                  value="No"
                  v-model="inFormBankDetails.hadBankruptcy"
                />
                <label>{{ $t('sharedFormTexts.no') }}</label>
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="btn style1 w-100 d-block"
            @click="ProcessBankDetailsForm"
            :disabled="disableContinue"
          >
            {{ $t('sharedFormTexts.next').toUpperCase() }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { getServerFormatDate } from '@/helpers/textFormatter'
import { BankDetails, LoanDetails } from '@/models/Models'
import { mapState } from 'pinia'
import { useRepo } from 'pinia-orm'
import { useAuthStore } from '@/store/auth'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'BankDetailsForm',
  data() {
    return {
      inFormBankDetails: {
        userBankName: '',
        planningBankruptcy: false,
        hadBankruptcy: false,
      },
    }
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    disableContinue() {
      return this.inFormBankDetails.userBankName === ''
    },
  },
  methods: {
    ProcessBankDetailsForm() {
      if (this.loggedInUser) {
        const bankDetails = new BankDetails({
          userBankName: this.inFormBankDetails.userBankName,
          institutionNumber: '123',
          branchNumber: '2345',
          acctNumber: '34567',
          planningBankruptcy: this.inFormBankDetails.planningBankruptcy,
          hadBankruptcy: this.inFormBankDetails.hadBankruptcy,
          transferMethod: '',
          createTime: getServerFormatDate(),
          updateTime: getServerFormatDate(),
          email: this.loggedInUser.email,

          uid: this.loggedInUser.uid,
        })

        useRepo(BankDetails).save(bankDetails)
        const createdStore = useRepo(BankDetails).all()[0]

        if (createdStore.userBankName) {
          this.bankDetailsSubmitClicked()
        }
      }
    },
    backButtonClicked() {
      this.$emit('backButtonClicked', 1, 3)
    },
    bankDetailsSubmitClicked() {
      this.$emit('bankDetailsSubmitClicked')
    },
  },
  setup() {
    const router = useRouter()

    onMounted(() => {
      const loanDetails = useRepo(LoanDetails).all()
      if (loanDetails.length === 0) {
        router.push('/')
      }
    })
  },
}
</script>

<template>
  <div class="page-wrapper">
    <div v-if="loggedInUser" class="content-wrapper">
      <PageTitle :pageTitle="$t('navbar.userAccount')" />
      <PersonalDetailsCard />
      <ContactDetailsCard />
      <SecondaryContactDetailsCard />
    </div>
    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import PersonalDetailsCard from '@/components/Account/MyAccount/PersonalDetailsCard'
import ContactDetailsCard from '@/components/Account/MyAccount/ContactDetailsCard'
import SecondaryContactDetailsCard from '@/components/Account/MyAccount/SecondaryContactDetailsCard'
import ErrorPage from '@/components/Pages/ErrorPage'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'

export default {
  name: 'MyAccountPage',
  components: {
    PageTitle,
    PersonalDetailsCard,
    ContactDetailsCard,
    SecondaryContactDetailsCard,
    ErrorPage,
  },
  data: function () {
    return {
      bankDetails: {},
      personalDetails: {},
      contactDetails: {},
      secondaryContactDetails: {},
      jobDetails: {},
      plaidDetails: {},
    }
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
}
</script>

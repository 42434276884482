<template>
  <div class="container">
    <div class="login-form-wrap">
      <div class="login-header">
        <h3>Crée un compte</h3>
        <p>
          Connectez-vous pour vérifier votre processus de demande et gérer vos
          prêts.
        </p>
      </div>
      <div class="login-form">
        <div class="login-body">
          <form class="form-wrap" @submit.prevent>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <input
                    type="text"
                    class="input"
                    placeholder="Nom d'utilisateur ou courriel"
                    v-model="email"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <input
                    type="password"
                    class="input"
                    placeholder="Mot de passe"
                    v-model="password"
                  />
                </div>
              </div>
              <div class="form-group">
                <input
                  type="password"
                  class="input"
                  placeholder="Confirmer votre mot de passe"
                  v-model="confirmPassword"
                />
              </div>
              <div class="col-md-12 text-center">
                <p class="mb-0 text-danger" v-if="errorMessage !== ''">
                  {{ errorMessage }}
                </p>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <button
                    class="btn style1"
                    @click="signUp"
                    :disabled="password !== confirmPassword"
                  >
                    Crée mon compte
                  </button>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <p class="mb-0">
                  Vous avez un compte?
                  <a class="style1" @click="toggleRegistrationView">
                    Connectez-vous à votre compte</a
                  >
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import environment from '../../../environment'
import { mapState, mapActions } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { auth } from '@/helpers/firebaseInit.js'
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth'

export default {
  name: 'RegistrationdContent',
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      errorMessage: '',
    }
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    isValid() {
      return this.email !== '' && this.password !== ''
    },
  },
  methods: {
    ...mapActions(useAuthStore, ['setLoggedInUser']),
    toggleRegistrationView() {
      this.$emit('toggleRegistrationViewClicked')
    },
    verifyAccountEmail() {
      const user = auth.currentUser
      if (user && !user.emailVerified) {
        // send the signed in user a verification email
        const actionCodeSettings = {
          // TO-DO Use only 1, this is also used in verifyEmail
          url: environment.APP_DOMAIN,
        }
        sendEmailVerification(user, actionCodeSettings)
          .then(() => {})
          .catch((error) => {
            error =
              error.message.indexOf('Firebase: ') > -1
                ? error.message.split('Firebase: ')[1]
                : error.message
            this.errorMessage = error
          })
      }
    },
    signUp() {
      const email = this.email
      const password = this.password

      if (!this.confirmPasswordComplexity()) {
        return
      }

      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          if (this.loggedInUser) {
            this.verifyAccountEmail()
          }
        })
        .catch((error) => {
          // catch any errors
          error =
            error.message.indexOf('Firebase: ') > -1
              ? error.message.split('Firebase: ')[1]
              : error.message
          this.errorMessage = error
        })
    },
    confirmPasswordComplexity() {
      this.errorMessage = ''

      let passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{12,}$/
      if (!passwordRegex.test(this.password)) {
        this.errorMessage = this.$t('error.passwordComplexityError')
        return false
      }

      return true
    },
  },
  watch: {
    showPopup() {},
  },
}
</script>

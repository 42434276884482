<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Testimonials" />
      <Testimonials />
    </div>
  </div>
</template>

<script>
import PageTitle from '../Common/PageTitle'
import Testimonials from '../Testimonials/Testimonials'

export default {
  name: 'TestimonialsPage',
  components: {
    PageTitle,
    Testimonials,
  },
}
</script>

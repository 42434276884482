<template>
  <form v-if="loggedInUser">
    <div class="apply-loan-content-box">
      <div class="apply-loan-content-box-action" @click="backButtonClicked">
        <i class="ri-arrow-left-s-line"></i>
        {{ $t('contractForm.formTitle').toUpperCase() }}
      </div>
      <div class="apply-loan-content-box-header">
        <h5 class="apply-loan-content-box-header-title">
          {{ $t('transferMethodForm.formTitle') }}
        </h5>
        <div class="apply-loan-content-box-header-desc">
          {{ $t('transferMethodForm.formSubtitle') }}
        </div>
      </div>

      <div class="apply-loan-content-box-body">
        <div
          v-if="messaage.text"
          :class="
            messaage.error
              ? 'text-danger text-center mb-20'
              : 'text-success text-center mb-20'
          "
        >
          {{ messaage.text }}
        </div>

        <div class="radio_box form-group">
          <div class="radio radio-top">
            <input
              type="radio"
              value="Interac e-transfer"
              checked
              v-model="inFormTransferMethodDetails.transferMethod"
            />
            <label for="interac_e_transfer">
              <b> {{ $t('transferMethodForm.eTransfer') }} </b> <br />
              {{ $t('transferMethodForm.eTransferDescription') }}
            </label>
          </div>
        </div>
        <div class="radio_box form-group">
          <div class="radio radio-top">
            <input
              type="radio"
              value="Direct deposit"
              v-model="inFormTransferMethodDetails.transferMethod"
            />
            <label for="transferMethodDeposit">
              <b> {{ $t('transferMethodForm.directDeposit') }} </b> <br />
              {{ $t('transferMethodForm.directDepositDescription') }}
            </label>
          </div>
        </div>
      </div>
      <div class="apply-loan-content-box-footer">
        <button
          type="submit"
          class="btn style1 w-100 d-block"
          @click="ProcessTransferMethodDetailsForm"
        >
          {{ $t('sharedFormTexts.next').toUpperCase() }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import {
  BankDetails,
  PersonalDetails,
  ContactDetails,
  SecondaryContactDetails,
  JobDetails,
  LoanDetails,
} from '@/models/Models'
import { useRepo } from 'pinia-orm'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'

export default {
  name: 'TransferMethodForm',
  data() {
    return {
      inFormTransferMethodDetails: { transferMethod: 'Interac e-transfer' },
      messaage: {},
    }
  },
  mounted() {
    this.getContractStatus()
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
  methods: {
    async getContractStatus() {
      const getContractSignedStatus = httpsCallable(
        functions,
        'getContractSignedStatus',
      )

      let loanDetails = useRepo(LoanDetails).all()[0]

      try {
        const response = await getContractSignedStatus(loanDetails.contractID)
        loanDetails.status = response.data
        useRepo(LoanDetails).save(loanDetails)
      } catch (error) {
        console.error('Error calling getContractSignedStatus:', error)
      }
    },
    ProcessTransferMethodDetailsForm() {
      let bankDetails = useRepo(BankDetails).all()[0]
      bankDetails.transferMethod =
        this.inFormTransferMethodDetails.transferMethod

      useRepo(BankDetails).save(bankDetails)
      bankDetails = useRepo(BankDetails).all()[0]

      if (bankDetails.transferMethod !== '') {
        let loanDetails = useRepo(LoanDetails).all()[0]

        // TO-DO fully submit loan to server
        let personalDetails = useRepo(PersonalDetails).all()[0]
        let contactDetails = useRepo(ContactDetails).all()[0]
        let secondaryContactDetails = useRepo(SecondaryContactDetails).all()[0]
        let jobDetails = useRepo(JobDetails).all()[0]

        const loanRequestDetails = {
          bankDetails: bankDetails,
          personalDetails: personalDetails,
          contactDetails: contactDetails,
          secondaryContactDetails: secondaryContactDetails,
          jobDetails: jobDetails,
          loanDetails: loanDetails,
        }

        const createALoanRequest = httpsCallable(
          functions,
          'createALoanRequest',
        )
        createALoanRequest(loanRequestDetails)

        this.transferMethodFormSubmitClicked()
      }
    },
    backButtonClicked() {
      this.$emit('backButtonClicked', 3, 5)
    },
    transferMethodFormSubmitClicked() {
      this.$emit('transferMethodFormSubmitClicked')
    },
  },
  setup() {
    const router = useRouter()

    onMounted(() => {
      const loanDetails = useRepo(LoanDetails).all()
      if (loanDetails.length === 0) {
        router.push('/')
      }
    })
  },
}
</script>

<template>
  <div v-if="loggedInUser && !loggedInUser.emailVerified" class="popup-layout">
    <div class="popup">
      <div class="container">
        <div class="login-form-wrap">
          <div class="login-header">
            <h3>Vérifiez votre compte</h3>
            <p>
              Nous avons envoyé un e-mail de vérification sur votre compte
              <b>{{ loggedInUser?.email }}</b>
            </p>
          </div>
          <div class="login-form">
            <div class="login-body">
              <div class="col-lg-12">
                <div class="form-group">
                  <button class="btn style1" @click="verifyAccountEmail">
                    Ré-envoyer l'email
                  </button>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <p class="pt-4 mb-0">
                  Connectez-vous avec un autre compte ou avez-vous confirmé
                  votre courriel?
                  <a @click="logoutButton"><b>Se déconnecter</b></a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import environment from '../../../environment'
import { mapState, mapActions } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { auth } from '@/helpers/firebaseInit.js'
import { sendEmailVerification } from 'firebase/auth'

export default {
  name: 'VerifyEmailContent',
  data() {
    return {}
  },
  mounted() {},
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
  methods: {
    ...mapActions(useAuthStore, ['setLoggedInUser']),
    logoutButton() {
      this.$emit('logoutButtonClicked')
    },
    verifyAccountEmail() {
      const user = auth.currentUser
      if (user && !user.emailVerified) {
        // send the signed in user a verification email
        const actionCodeSettings = {
          // TO-DO Use only 1, this is also used in verifyEmail
          url: environment.APP_DOMAIN,
        }
        sendEmailVerification(user, actionCodeSettings)
          .then(() => {})
          .catch((error) => {
            error =
              error.message.indexOf('Firebase: ') > -1
                ? error.message.split('Firebase: ')[1]
                : error.message
            this.errorMessage = error
          })
      }
    },
  },
}
</script>

<template>
  <Pricing />
</template>

<script>
import Pricing from '@/components/Unused/Pricing/Pricing'

export default {
  name: 'PricingPlan',
  components: {
    Pricing,
  },
}
</script>

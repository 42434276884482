<template>
  <div class="about-wrap style2 pb-100 bg-stratos">
    <img
      src="@/assets/images/section-shape-1.png"
      alt="Image"
      class="section-shape-one"
    />
    <div class="container">
      <div class="row gx-5 align-items-center">
        <div class="col-lg-6 col-12 order-lg-1 order-2">
          <div class="about-content">
            <img
              src="@/assets/images/about/about-shape-4.png"
              alt="Image"
              class="about-shape-one bounce"
            />
            <div class="content-title style2">
              <span>Reliable OnlinePayment Platform</span>
              <h2>Anytime Anywhere Money Can Be Deposited</h2>
              <p>
                There are many variations of passages of Lorem Ipsum amet
                avoilble but majority have suffered alteration in some form, by
                injected humur or randomise words which don't sure amet sit
                dolor quras alto lorem.
              </p>
            </div>
            <ul class="content-feature-list list-style">
              <li><i class="ri-check-double-line"></i>Powerful Mobile App</li>
              <li><i class="ri-check-double-line"></i>Free Plan Available</li>
              <li><i class="ri-check-double-line"></i>Commitment Free</li>
              <li><i class="ri-check-double-line"></i>100% Transparent Cost</li>
              <li>
                <i class="ri-check-double-line"></i>Full Data Privacy Compliance
              </li>
              <li>
                <i class="ri-check-double-line"></i>Debit Mastercard Included
              </li>
            </ul>
            <router-link to="/about" class="btn style1">Learn More</router-link>
          </div>
        </div>
        <div class="col-lg-6 col-12 order-lg-2 order-1">
          <div class="about-img-wrap">
            <img
              src="@/assets/images/about/about-shape-5.png"
              alt="Image"
              class="about-shape-two bounce"
            />
            <img
              src="@/assets/images/about/about-shape-6.png"
              alt="Image"
              class="about-shape-three moveHorizontal"
            />
            <img src="@/assets/images/about/about-img-2.png" alt="Image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentPlatform',
}
</script>

<template>
  <div v-if="loggedInUser" class="container my-5 p-10">
    <div class="container">
      <iframe
        :src="this.contractUrl"
        class="w-100"
        style="height: 80vh"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { LoanDetails } from '@/models/Models'
import { mapRepos } from 'pinia-orm'

export default {
  name: 'MyContract',
  data() {
    return {
      contractUrl: '',
    }
  },
  mounted() {
    this.loadContract()
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    ...mapRepos({ loanDetailsRepo: LoanDetails }),
    loanDetails() {
      return this.loanDetailsRepo.all()[0]
    },
  },
  methods: {
    loadContract() {
      if (this.loanDetails) {
        this.contractUrl = this.loanDetails.contractUrl
      }
    },
  },
  watch: {
    loanDetails: {
      handler() {
        this.loadContract()
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <Preloader v-if="isLoading" />
  <router-view v-slot="{ Component }">
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
      mode="out-in"
      :duration="600"
      @after-leave="$root.$emit('triggerScroll')"
    >
      <div>
        <Navbar class="style1" />
        <component :is="Component" />
        <Footer />
      </div>
    </transition>
  </router-view>
  <BackToTop />
</template>

<script>
import Preloader from './components/Layouts/Preloader.vue'
import BackToTop from './components/Layouts/BackToTop.vue'
import Navbar from '@/components/Layouts/Navbar.vue'
import Footer from '@/components/Layouts/Footer.vue'
import { initFirebase } from '@/helpers/firebaseInit.js'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    Preloader,
    BackToTop,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  created() {
    initFirebase()
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 2000)
  },
}
</script>

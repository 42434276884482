<template>
  <div class="page-wrapper">
    <div v-if="loggedInUser" class="content-wrapper">
      <PageTitle
        :pageTitle="$t('navbar.loanFileUpload')"
        :previousPage="$t('filesUpload.previousPage')"
        :previousPageLabel="$t('navbar.userDashboard')"
      />
      <FilesUpload />
    </div>
    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import FilesUpload from '@/components/Account/FilesUpload'
import ErrorPage from '@/components/Pages/ErrorPage'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'

export default {
  name: 'FilesUploadPage',
  components: {
    PageTitle,
    FilesUpload,
    ErrorPage,
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
}
</script>

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Blog Left Sidebar" />
      <BlogLeftSidebar />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import BlogLeftSidebar from '@/components/Unused/BlogLeftSidebar/BlogLeftSidebar'

export default {
  name: 'BlogLeftSidebarPage',
  components: {
    PageTitle,
    BlogLeftSidebar,
  },
}
</script>

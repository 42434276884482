import { createWebHistory, createRouter } from 'vue-router'

import HomePage from '@/components/Pages/HomePage'
import HomeTwoPage from '@/components/Unused/Pages/HomeTwoPage'
import HomeThreePage from '@/components/Unused/Pages/HomeThreePage'
import AboutPage from '@/components/Unused/Pages/AboutPage'
import ContactUsPage from '@/components/Pages/ContactUsPage'
import ServicesPage from '@/components/Unused/Pages/ServicesPage'
import ServicesTwoPage from '@/components/Unused/Pages/ServicesTwoPage'
import ServicesDetailsPage from '@/components/Unused/Pages/ServicesDetailsPage'
import ProjectPage from '@/components/Unused/Pages/ProjectPage'
import ProjectDetailsPage from '@/components/Unused/Pages/ProjectDetailsPage'
import TeamPage from '@/components/Pages/TeamPage'
import AccountPage from '@/components/Unused/Pages/AccountPage'
import ApplyPage from '@/components/Pages/ApplyPage'
import PricingPage from '@/components/Unused/Pages/PricingPage'
import FaqPage from '@/components/Pages/FaqPage'
import TestimonialsPage from '@/components/Pages/TestimonialsPage'
import ViewContractPage from '@/components/Pages/ViewContractPage'
import MyAccountPage from '@/components/Pages/MyAccountPage'
import MyDashboardPage from '@/components/Pages/MyDashboardPage'
import FilesUploadPage from '@/components/Pages/FilesUploadPage'
import AdminDashboardPage from '@/components/Pages/AdminDashboardPage'
import AdminUsersPage from '@/components/Unused/Pages/AdminUsersPage'
import MyCalendarPage from '@/components/Pages/MyCalendarPage'
import LoginPage from '@/components/Unused/Pages/LoginPage'
import RegisterPage from '@/components/Unused/Pages/RegisterPage'
import RecoverPasswordPage from '@/components/Unused/Pages/RecoverPasswordPage'
import TermsOfServicePage from '@/components/Unused/Pages/TermsOfServicePage'
import PrivacyPolicyPage from '@/components/Pages/PrivacyPolicyPage'
import ErrorPage from '@/components/Pages/ErrorPage'
import BlogPage from '@/components/Unused/Pages/BlogPage'
import BlogLeftSidebarPage from '@/components/Unused/Pages/BlogLeftSidebarPage'
import BlogRightSidebarPage from '@/components/Unused/Pages/BlogRightSidebarPage'
import BlogDetailsPage from '@/components/Unused/Pages/BlogDetailsPage'
import BlogDetailsLeftSidebarPage from '@/components/Unused/Pages/BlogDetailsLeftSidebarPage'
import BlogDetailsRightSidebarPage from '@/components/Unused/Pages/BlogDetailsRightSidebarPage'

// Use /path-more-path-page for user pages routing
// and use /admin/path/more/path for admin pages routing
const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/about', name: 'AboutPage', component: AboutPage },
  { path: '/faq', name: 'FaqPage', component: FaqPage },
  { path: '/contact', name: 'ContactUsPage', component: ContactUsPage },
  {
    path: '/upload-documents',
    name: 'FilesUploadPage',
    component: FilesUploadPage,
  },
  {
    path: '/my-contract',
    name: 'MyContract',
    component: ViewContractPage,
  },
  { path: '/my-account', name: 'MyAccountPage', component: MyAccountPage },
  {
    path: '/my-dashboard',
    name: 'MyDashboardPage',
    component: MyDashboardPage,
  },
  {
    path: '/my-calendar',
    name: 'MyCalendarPage',
    component: MyCalendarPage,
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboardPage',
    component: AdminDashboardPage,
  },
  {
    path: '/admin/users',
    name: 'AdminUsersPage',
    component: AdminUsersPage,
  },
  { path: '/services', name: 'ServicesPage', component: ServicesPage },
  {
    path: '/services-two',
    name: 'ServicesTwoPage',
    component: ServicesTwoPage,
  },
  {
    path: '/service-details',
    name: 'ServicesDetailsPage',
    component: ServicesDetailsPage,
  },
  { path: '/projects', name: 'ProjectPage', component: ProjectPage },
  {
    path: '/project-details',
    name: 'ProjectDetailsPage',
    component: ProjectDetailsPage,
  },
  { path: '/team', name: 'TeamPage', component: TeamPage },
  { path: '/open-account', name: 'AccountPage', component: AccountPage },
  { path: '/apply-loan', name: 'ApplyPage', component: ApplyPage },
  { path: '/pricing', name: 'PricingPage', component: PricingPage },
  {
    path: '/testimonials',
    name: 'TestimonialsPage',
    component: TestimonialsPage,
  },
  { path: '/login', name: 'LoginPage', component: LoginPage },
  { path: '/register', name: 'RegisterPage', component: RegisterPage },
  {
    path: '/recover-password',
    name: 'RecoverPasswordPage',
    component: RecoverPasswordPage,
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfServicePage',
    component: TermsOfServicePage,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage,
  },
  { path: '/:pathMatch(.*)*', name: 'ErrorPage', component: ErrorPage },
  // Out of scope
  { path: '/home-two', name: 'HomeTwoPage', component: HomeTwoPage },
  { path: '/home-three', name: 'HomeThreePage', component: HomeThreePage },
  { path: '/blog', name: 'BlogPage', component: BlogPage },
  {
    path: '/blog-left-sidebar',
    name: 'BlogLeftSidebarPage',
    component: BlogLeftSidebarPage,
  },
  {
    path: '/blog-right-sidebar',
    name: 'BlogRightSidebarPage',
    component: BlogRightSidebarPage,
  },
  {
    path: '/blog-details',
    name: 'BlogDetailsPage',
    component: BlogDetailsPage,
  },
  {
    path: '/blog-details-left-sidebar',
    name: 'BlogDetailsLeftSidebarPage',
    component: BlogDetailsLeftSidebarPage,
  },
  {
    path: '/blog-details-right-sidebar',
    name: 'BlogDetailsRightSidebarPage',
    component: BlogDetailsRightSidebarPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' }
  },
})

export default router

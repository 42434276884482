<template>
  <div class="page-wrapper">
    <div
      v-if="
        loggedInUser &&
        loanDetails &&
        (loanDetails.status == 'inProgress' ||
          loanDetails.status == 'sent' ||
          loanDetails.status == 'signed' ||
          loanDetails.status == 'approved' ||
          loanDetails.status == 'rejected')
      "
      class="content-wrapper"
    >
      <PageTitle :pageTitle="$t('navbar.userDashboard')" />
      <MyDashboard />
    </div>
    <div v-else>
      <NoLoanErrorPage />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import MyDashboard from '@/components/Account/MyDashboard'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { mapRepos } from 'pinia-orm'
import { LoanDetails } from '@/models/Models'
import NoLoanErrorPage from './NoLoanErrorPage.vue'

export default {
  name: 'MyDashboardPage',
  components: {
    PageTitle,
    MyDashboard,
    NoLoanErrorPage,
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    ...mapRepos({ loanDetailsRepo: LoanDetails }),
    loanDetails() {
      return this.loanDetailsRepo.all()[0]
    },
  },
}
</script>

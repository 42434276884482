<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Admin dashboard" />
      <AdminUsersView />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import AdminUsersView from '../Admin/AdminUsersView'

export default {
  name: 'AdminUsersPage',
  components: {
    PageTitle,
    AdminUsersView,
  },
}
</script>

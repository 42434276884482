import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'

let app = undefined
let db = undefined
let auth = undefined
let functions = undefined

let initFirebase = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyAxL0uUZcy5dVchRscVfXQzuvbXt85mkto',
    authDomain: 'pretruby-860f5.firebaseapp.com',
    projectId: 'pretruby-860f5',
    storageBucket: 'gs://pretruby-860f5.appspot.com/',
    messagingSenderId: '141759726733',
    appId: '1:141759726733:web:75c1a30c6b70ba7f8f0ec4',
    measurementId: 'G-PLNM6RML4N',
  }

  app = initializeApp(firebaseConfig)

  getAnalytics(app)
  db = getFirestore(app)
  auth = getAuth(app)

  functions = getFunctions(app)

  // Activate to test local functions
  //   connectFunctionsEmulator(functions, 'localhost', 5001)
}

export { initFirebase, app, db, auth, functions }

<template>
  <div class="container col-6 mt-3">
    <h5>{{ $t('contactDetails') }}</h5>
    <div class="edit-button">
      <button class="btn style3" @click="toggleEditMode()">
        {{ showEdit ? 'Cancel' : 'Edit' }}
      </button>
    </div>

    <form @submit.prevent>
      <div v-if="!showEdit">
        <label for="cellPhoneNumber">
          {{ $t('cellPhoneNumber') }} {{ contactDetails.cellPhoneNumber }}
        </label>
      </div>
      <div v-if="showEdit">
        <label>{{ $t('cellPhoneNumber') }}</label>

        <input
          :placeholder="contactDetails.cellPhoneNumber"
          v-model="editedContactDetails.cellPhoneNumber"
          type="text"
          id="cellPhoneNumber"
        />
      </div>
      <div v-if="!showEdit">
        <label for="cellPhoneNumber">
          {{ $t('homePhoneNumber') }} {{ contactDetails.homePhoneNumber }}
        </label>
      </div>
      <div v-if="showEdit">
        <label>{{ $t('homePhoneNumber') }}</label>

        <input
          :placeholder="contactDetails.homePhoneNumber"
          v-model="editedContactDetails.homePhoneNumber"
          type="text"
          id="homePhoneNumber"
        />
      </div>
      <div v-if="!showEdit">
        <label for="addressLine1">
          {{ $t('addressLine1') }} {{ contactDetails.addressLine1 }}
        </label>
      </div>
      <div v-if="showEdit">
        <label>{{ $t('addressLine1') }}</label>

        <input
          :placeholder="contactDetails.addressLine1"
          v-model="editedContactDetails.addressLine1"
          type="text"
          id="addressLine1"
        />
      </div>

      <div v-if="!showEdit">
        <label>
          {{ $t('addressLine2') }}
          {{ contactDetails.addressLine2 }}</label
        >
      </div>
      <div v-if="showEdit">
        <label for="addressLine2">
          {{ $t('addressLine2') }}
        </label>
        <input
          :placeholder="contactDetails.addressLine2"
          v-model="editedContactDetails.addressLine2"
          type="text"
          id="addressLine2"
        />
      </div>
      <div v-if="!showEdit">
        <label>
          {{ $t('city') }}
          {{ contactDetails.city }}</label
        >
      </div>
      <div v-if="showEdit">
        <label for="city">
          {{ $t('city') }}
        </label>
        <input
          :placeholder="contactDetails.city"
          v-model="editedContactDetails.city"
          type="text"
          id="city"
        />
      </div>
      <div v-if="!showEdit">
        <label>
          {{ $t('province') }}
          {{ contactDetails.province }}</label
        >
      </div>
      <div v-if="showEdit">
        <label for="province">
          {{ $t('province') }}
        </label>
        <input
          :placeholder="contactDetails.province"
          v-model="editedContactDetails.province"
          type="text"
          id="province"
        />
      </div>
      <div v-if="!showEdit">
        <label>
          {{ $t('postalCode') }}
          {{ contactDetails.postalCode }}</label
        >
      </div>
      <div v-if="showEdit">
        <label for="postalCode">
          {{ $t('postalCode') }}
        </label>
        <input
          :placeholder="contactDetails.postalCode"
          v-model="editedContactDetails.postalCode"
          type="text"
          id="postalCode"
        />
      </div>
      <button
        class="save-button"
        v-if="showEdit"
        type="submit"
        @click="saveChanges"
      >
        {{ $t('save') }}
      </button>
    </form>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'
import { getServerFormatDate } from '@/helpers/textFormatter'
import { useRepo } from 'pinia-orm'
import { ContactDetails } from '@/models/Models'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'

export default {
  name: 'ContactDetailsCard',
  props: {},
  data() {
    return {
      editedContactDetails: {
        cellPhoneNumber: '',
        homePhoneNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        province: '',
        postalCode: '',
        email: '',
      },
      contactDetails: {},
      showEdit: false,
    }
  },
  mounted() {
    setTimeout(this.laodDetails, 1000)
  },
  methods: {
    async laodDetails() {
      try {
        const fetchDetailsFrom = httpsCallable(functions, 'fetchDetailsFrom')
        const response = await fetchDetailsFrom({
          uid: this.loggedInUser.uid,
          storage: 'contactDetails',
        })
        if (response.data.contactDetails[0] != 'no contact details found') {
          useRepo(ContactDetails).save(response.data.contactDetails)

          console.log(response.data.contactDetails)

          let contactDetails = useRepo(ContactDetails).all()[0]
          this.contactDetails = { ...contactDetails }
        }
      } catch (error) {
        console.error('Error calling laodDetails in contactDetailsCard:', error)
      }
    },
    toggleEditMode() {
      this.showEdit = !this.showEdit
    },
    async saveChanges() {
      if (this.showEdit) {
        let contactDetailsEdit = {}

        const updateFieldIfModified = (field, editedField) => {
          const trimmedValue = editedField.trim()
          if (trimmedValue !== '' && trimmedValue !== field) {
            return trimmedValue
          }
          return field
        }

        contactDetailsEdit.cellPhoneNumber = updateFieldIfModified(
          this.contactDetails.cellPhoneNumber,
          this.editedContactDetails.cellPhoneNumber,
        )
        contactDetailsEdit.homePhoneNumber = updateFieldIfModified(
          this.contactDetails.homePhoneNumber,
          this.editedContactDetails.homePhoneNumber,
        )
        contactDetailsEdit.addressLine1 = updateFieldIfModified(
          this.contactDetails.addressLine1,
          this.editedContactDetails.addressLine1,
        )
        contactDetailsEdit.addressLine2 = updateFieldIfModified(
          this.contactDetails.addressLine2,
          this.editedContactDetails.addressLine2,
        )
        contactDetailsEdit.city = updateFieldIfModified(
          this.contactDetails.city,
          this.editedContactDetails.city,
        )
        contactDetailsEdit.province = updateFieldIfModified(
          this.contactDetails.province,
          this.editedContactDetails.province,
        )
        contactDetailsEdit.postalCode = updateFieldIfModified(
          this.contactDetails.postalCode,
          this.editedContactDetails.postalCode,
        )

        console.log(this.loggedInUser.uid)

        try {
          const contactDetailsUpdate = {
            uid: this.loggedInUser.uid,
            updateTime: getServerFormatDate(),
            ...contactDetailsEdit,
          }

          console.log(contactDetailsUpdate)
          const updateFirestore = httpsCallable(functions, 'updateFirestore')
          const response = await updateFirestore({
            contactDetails: contactDetailsUpdate,
          })

          console.log(response.data.message)
          this.toggleEditMode()
          setTimeout(this.laodDetails, 1000)
        } catch (error) {
          console.error(error)
        }
      }
    },
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
}
</script>
<style scoped>
.container {
  position: relative;
}

.edit-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.save-button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
}
</style>

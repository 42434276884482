<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Recover Password" />
      <RecoverPassword />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import RecoverPassword from '@/components/Unused/RecoverPassword/RecoverPassword'

export default {
  name: 'RecoverPasswordPage',
  components: {
    PageTitle,
    RecoverPassword,
  },
}
</script>

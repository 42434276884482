<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Service Details" />
      <ServicesDetails />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import ServicesDetails from '@/components/Unused/ServicesDetails/ServicesDetails'

export default {
  name: 'ServicesDetailsPage',
  components: {
    PageTitle,
    ServicesDetails,
  },
}
</script>

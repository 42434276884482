export default {
  save: 'Enregistrer',
  loading: 'Chargement en cours...',
  register: 'Créer un compte',
  login: 'Connexion',
  logout: 'Déconnexion',
  close: 'Fermer',
  getALoan: 'Obtenir un prêt',
  forgotPasswordLink: 'Mot de passe oublié?',
  email: 'Courriel',
  numberOfAccountsLinked: 'Nombre de comptes liés',
  userTransactions: "Transactions de l'utilisateur",
  requestID: 'Identifiant de la demande',
  dateRequested: 'Date de la demande',
  contract: 'Contrat',
  approve: 'Accepter',
  deny: 'Refuser',
  expand: 'Élargir',
  minimize: 'Rétrécir',
  delete: 'Supprimer',
  refresh: 'Recharger',
  loanAmount: 'Montant',
  lastName: 'Nom de famille',
  firstName: 'Prénom',
  dateOfBirth: 'Date de naissance',
  sin: "Numéro d'assurance sociale",
  relationToUser: 'Ta relation',
  phoneNumber: 'Numero de téléphone',
  contactDetails: 'Informations de contact',
  cellPhone: 'Téléphone portable',
  homePhone: 'Téléphone résidentiel (facultatif)',
  addressLine1: 'Adresse (ligne 1)',
  addressLine2: 'Adresse (ligne 2)',
  city: 'Ville',
  province: 'Province',
  postalCode: 'Code postal',
  secondaryContact: 'Coordonnées secondaires',
  paymentsPage: {
    myPayments: 'Mes paiements',
    setUpPayments: 'Set up your payments',
    intialLoan: 'Initial loan',
  },
  viewContractPage: {
    myContract: 'Mon contrat',
  },
  loanStatus: {
    approved: 'Approuvé',
    inProgress: 'En cours de révision',
    sent: 'Contrat non signé ou non approuvé',
    signed: 'Contrat signé',
    rejected: 'Refusé',
    completed: 'Remboursé',
  },
  myCalendar: {
    errorTitle: 'Aucun prêt actif trouvé',
    errorContent:
      'Lorsque vous avez un prêt approuvé, votre calendrier apparaîtra',
    next: 'Suivant >',
    previous: '< Précédent',
    paymentDue: 'Paiement dû !',
    january: 'Janvier',
    febuary: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
    sunday: 'Dimanche',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
  },
  myDashboard: {
    welcome: 'Bienvenue',
    loanApproved:
      'Votre prêt a été approuvé, vous devriez recevoir vos fonds dans les 24 heures.',
    loanPending:
      "Votre prêt est actuellement en attente d'approbation. Vous recevrez un avis lorsqu'il sera approuvé.",
    makeAPayment: 'Effectuer un paiement',
    viewStatement: 'Vue déclaration',
    renewLoan: 'Renouveler prêt',
    viewContract: 'Visualiser mon contrat',
    renewLoanCondition:
      'Vous pourrez renouveler votre prêt lorsque plus de 60 % du prêt sera remboursé.',
    noPendingLoans:
      "Vous n'avez aucun prêt en attente, faites une demande de prêt dès maintenant !",
  },
  filesUpload: {
    title: 'Uploader des documents',
    titleBankStatement: 'Vos relevés bancaires des 3 derniers mois',

    descriptionBankStatement:
      'Il doit inclure votre nom complet, numéro de compte et daté de moins de 3 mois complets.',
    titlePaySlip: 'Votre dernier bulletin de paie',
    descriptionPaySlip:
      "Il doit inclure votre nom complet, le nom complet de l'employeur et votre salaire.",
    titleProofOfId: "Une preuve d'identité valide et votre visage",
    descriptionProofOfId:
      'Veuillez prendre une photo de vous tenant votre permis de conduire ou passeport valide contre un arrière-plan blanc.',
    titleProofOfResidency: 'Preuve de résidence valide',
    descriptionProofOfResidency:
      'Cela pourrait être votre bail ou vos factures de services publics.',
    titleVoidCheck: 'A void cheque',
    descriptionVoidCheck:
      'Doit inclure votre nom complet, votre numéro de routage bancaire et votre numéro de compte.',
    titleOtherDocuments: 'Autres documents.',
    descriptionOtherDocuments:
      'Tout document que vous pensez pourrait nous aider à prendre notre décision.',
    upload: 'Uploader',
    uploadSuccesful: 'Upload réussi !',
    uploadFailed: 'Téléchargement échoué, réessayez...',
    previousPage: 'my-dashboard',
  },
  frontApply: {
    applyNow:
      "Postulez dès aujourd'hui pour profiter de nos prêts ultra flexibles",
    selectAmount: 'Sélectionnez le montant de votre prêt',
    dateLoanWanted: 'Quand serez-vous payé ensuite?',
    paycheckFrequency: 'À quelle fréquence recevez-vous votre salaire?',
    payApproximately: 'Vous paierez environ',
    payBackTime:
      'Chaque fois que vous êtes payé. Vous rembourserez le prêt en environ',
    askForLoan: 'DEMANDER CE PRÊT',
    agreeToTerms: "En cliquant sur 'Demander ce Prêt', j'accepte",
    termsAndPolicy: 'les Termes et la Politique',
    weekly: 'Hebdomadaire',
    everyTwoWeeks: 'Toutes les deux semaines',
    twiceAMonth: 'Deux fois par mois',
    monthly: 'Mensuel',
  },
  whyChooseUs: {
    title: 'Obtenez le meilleur service de paiement en ligne.	  ',
    textUnderTitle:
      "Peu importe le nombre d'erreurs que les gens ont commises dans le passé. Tout le monde mérite un autre essai. C'est pourquoi nous proposons des prêts flexibles aux personnes indépendamment de leur origine.",
    reasonTitle1: 'Coûts faibles',
    reasonText1:
      "Nos prêts ont des taux d'intérêt abordables et transparents pour faciliter le remboursement.",
    reasonTitle2: 'Sécurité et protection',
    reasonText2:
      'Nous utilisons les dernières technologies de sécurité pour protéger les informations confidentielles de nos clients.',
    reasonTitle3: 'Support en direct',
    reasonText3:
      'Notre équipe de support est disponible 24h/24 et 7j/7 pour aider les clients en cas de besoin.',
  },
  contactUs: {
    title: 'PretRuby, une solution rapide pour les petits prêts',
    shortTitle: 'Contact',
    faqButton: 'FAQ sur les prêts',
    ourLocationTitle: 'Nos bureau',
    ourLocationText:
      '304 Av. de Grand-Mère, Shawinigan, Qubec, Canada, G9T 2H5',
    emailUsTitle: 'Envoyer nous un courriel',
    emailUsText1: 'support',
    emailUsText2: 'pretruby.ca',
    callUsTitle: 'Appelez-nous',
    callUsText: '+1 514 500 5936',
    contactFormSuperTitle: 'Envoyer nous un message',
    contactFormTitle: 'Avez-vous des questions?',
    contactFormName: 'Votre nom',
    contactFormEmail: 'Votre courriel',
    contactFormPhoneNumber: 'Numero de telephone',
    contactFormSubject: 'Sujet',
    contactFormMessageFill: 'Votre message',
    contactFormSend: 'Envoyer',
  },
  feature: {
    featureTitle1: 'Créer un compte',
    featureText1:
      "Inscription rapide et facile pour des transferts d'argent instantanés.",
    featureTitle2: 'Attacher un compte bancaire',
    featureText2:
      "Sécurisez vos transferts d'argent en reliant votre compte bancaire à notre plateforme.",
    featureTitle3: "Envoyer de l'argent",
    featureText3:
      "Transférez de l'argent rapidement et facilement à vos proches.",
  },
  counter: {
    counterTitle1: 'Clients satisfaits',
    counterValue1: '6342',
    counterTitle2: 'Satisfaction client',
    counterValue2: '98%',
    counterTitle3: 'Approbation automatisée',
    counterValue3: 'OUI',
    counterTitle4: 'Projets réussis',
    counterValue4: '473',
  },
  navbar: {
    support: 'Support',
    help: 'Aide',
    language: 'English',
    loggedInAs: 'Connecté en tant que ',
    dropdownTitle: 'Menu',
    userPages: 'Pages utilisateur',
    userAccount: 'Mon compte',
    userCalendar: 'Mon calendrier',
    legalMention: 'Mention légale',
    ourTeam: 'Notre équipe',
    testimonials: 'Témoignages',
    userDashboard: 'Tableau de bord',
    loanFileUpload: 'Téléchargements de fichiers connexes',
  },
  footer: {
    leftText:
      'Si votre demande est complète, elle sera approuvée en 30 min. Recevez un courriel pour déposer vos fonds via virement Interac. Autrement, les fonds seront déposés directement dans votre compte sous 24 heures ouvrables.',
    rightText: 'Vous ne serez pas déçu de faire confiance à PretRuby',
    copyright: "Droits d'auteur © 2023",
    reserved: 'PretRuby. Tous droits réservés.',
    ourCompany: 'Notre Companie',
    link1: 'Companie & Equipe',
    link2: "Option d'emprunt",
    link3: 'Contact',
    link4: 'Mention légale',
    footerContactTitle: 'Nous contacter',
    footerContactLine1: '304 Av. de Grand-Mère',
    footerContactLine2: 'Shawinigan, Qubec, Canada',
    footerContactLine3: 'G9T 2H5',
    footerContactLine4: '+1 514 500-5936',
    subscribeToNewsletter: 'Souscrire',
  },
  faq: {
    title: 'Foire au questions',
    faqPaymentTitle: 'Paiement',
    faqPaymentQuestion1: 'Comment vais-je recevoir les fonds?',
    faqPaymentAnswer1:
      "Vous recevrez un virement Interac dans l'heure suivant son approbation. Notre équipe vous assistera dans le dépôt de vos fonds. Si vous ne pouvez pas recevoir de virement Interac, notre équipe peut effectuer un dépôt direct directement dans votre compte. Dans ce cas, prévoyez jusqu'à 24 heures ouvrables pour la réception des fonds",
    faqPaymentQuestion2: 'Comment vais-je rembourser mon prêt?',
    faqPaymentAnswer2:
      'Votre prêt est remboursé par prélèvement automatique sur votre compte bancaire. Vous pouvez consulter votre échéancier de paiement dans votre profil.',
    faqPaymentQuestion3:
      'Que se passe-t-il si je ne peux pas effectuer mon paiement?',
    faqPaymentAnswer3:
      "Vous devez nous contacter au moins 72 heures ouvrables avant la date du retrait. Le paiement différé sera reporté dans le calendrier, sauf indication contraire. Des frais de 10 $ s'appliquent, mais vous n'aurez pas à payer de frais NSF.",
    faqPaymentQuestion4:
      'Que se passe-t-il si je ne peux pas rembourser mon prêt?',
    faqPaymentAnswer4:
      "En cas de défaut, la créance sera envoyée à une agence de recouvrement. Tous les frais de recouvrement seront à la charge de l'emprunteur conformément à la loi.",
    faqAccountTitle: 'Compte',
    faqAccountQuestion1: 'Vos services sont-ils sécurisés?',
    faqAccountAnswer1:
      "Oui, nos services répondent aux normes les plus élevées du secteur financier. Nous utilisons les mêmes procédures que les grandes institutions financières. Nous n'aurons jamais accès à votre compte bancaire.",
    faqAccountQuestion2: 'Avez-vous accès à mon compte bancaire?',
    faqAccountAnswer2:
      "Non, nous n'avons accès qu'aux informations qui nous permettent de prendre une décision concernant votre demande. Nous n'aurons jamais accès à votre compte bancaire ou à votre mot de passe.",
    faqAccountQuestion3: 'Comment puis-je changer mon mot de passe?',
    faqAccountAnswer3:
      "Votre mot de passe peut être modifié à tout moment lorsque vous êtes connecté à votre compte. Accédez simplement à votre profil, cliquez sur l'icône de la clé à molette, entrez votre ancien mot de passe et votre nouveau mot de passe dans les champs et votre mot de passe sera modifié. (Indisponible a l'instan, utiliser l'option mot de passe oublier)",
    faqAccountQuestion4: 'Que dois-je faire si je perds mon mot de passe?',
    faqAccountAnswer4:
      "Si vous oubliez votre mot de passe, cliquez sur le lien Mot de passe oublié sur la page de connexion. Entrez l'email associé à votre compte et cliquez sur Réinitialiser le mot de passe. Vous recevrez un e-mail contenant des instructions supplémentaires sur la réinitialisation de votre mot de passe. Si vous ne recevez pas l'e-mail, veuillez vérifier votre spam.",
    faqAdmissibilityTitle: 'Admissibilité',
    faqAdmissibilityQuestion1:
      'Dois-je gagner un salaire pour utiliser vos services?',
    faqAdmissibilityAnswer1:
      'Vous devez gagner un revenu net minimum de 1 200 $ par mois.',
    faqAdmissibilityQuestion2:
      'Devez-vous être résident du Québec pour utiliser vos services?',
    faqAdmissibilityAnswer2:
      "Oui, nous n'offrons nos services qu'au Québec pour le moment.",
    faqAdmissibilityQuestion3:
      'Combien de temps faut-il pour recevoir les fonds?',
    faqAdmissibilityAnswer3:
      "Si votre demande est complète, elle sera approuvée dans les 30 minutes et vous recevrez un courriel pour déposer vos fonds par Virement Interac dans l'heure.",
    faqAdmissibilityQuestion4:
      'Pourquoi ma demande de prêt serait-elle refusée?',
    faqAdmissibilityAnswer41:
      "Votre demande pourrait être refusée pour l'une des raisons suivantes:",
    faqAdmissibilityAnswer42: "Antécédents d'emploi instables",
    faqAdmissibilityAnswer43:
      'De nombreux mauvais paiements effectués sur votre compte',
    faqAdmissibilityAnswer44:
      'Formulaire incomplet ou informations inexactes non payé',
    faqAdmissibilityAnswer45: 'La faillite',
    faqAdmissibilityAnswer46: "Niveau d'endettement à court terme élevé",
    faqAdmissibilityQuestion5:
      'Suis-je admissible si je reçois mon salaire par chèque ou en espèces?',
    faqAdmissibilityAnswer5:
      "Non, malheureusement, nous n'offrons nos services qu'aux clients qui reçoivent leur paie par dépôt direct dans un compte bancaire canadien.",
    faqAdmissibilityQuestion6:
      "Un indépendant peut-il contracter l'un de vos prêts?",
    faqAdmissibilityAnswer6:
      "Nous n'acceptons que les personnes qui reçoivent leur salaire par dépôt direct.",
    faqAdmissibilityQuestion7:
      'Dois-je être employé pour utiliser vos services?',
    faqAdmissibilityAnswer7:
      'Oui, vous devez être employé depuis au moins 2 mois.',
    faqLoanTitle: 'Emprunt',
    faqLoanQuestion1: 'Quel type de prêts offrez-vous?',
    faqLoanAnswer1:
      "Nous offrons des facilités de crédit variables. En conséquence, nos clients ont un accès rapide à des fonds qu'ils peuvent ensuite utiliser à leur propre discrétion. Les remboursements sont effectués à l'avance sans frais ni pénalités supplémentaires.",
    faqLoanQuestion2: 'Comment faire une demande de prêt?',
    faqLoanAnswer2:
      'Pour faire une demande, connectez-vous et remplissez le formulaire de demande dans la section Services.',
    faqLoanQuestion3:
      'Combien de temps faut-il pour remplir une demande de prêt?',
    faqLoanAnswer3:
      'Une demande peut être complétée en aussi peu que 2 minutes avec notre système de vérification bancaire instantanée.',
    faqLoanQuestion4: 'Y a-t-il des documents à signer ou à remplir?',
    faqLoanAnswer4:
      'Non, la seule signature requise est celle du contrat et elle se fait électroniquement. Aucun document à remplir et à renvoyer par courrier.',
    faqLoanQuestion5: 'Combien de temps faut-il pour recevoir les fonds?',
    faqLoanAnswer5:
      "Si votre demande est complète, elle sera approuvée dans les 30 minutes et vous recevrez un courriel pour déposer vos fonds par virement Interac. Si vous ne pouvez pas accepter les virements électroniques Interac, nous vous enverrons un dépôt direct et les fonds apparaîtront dans votre compte dans les 24 heures ouvrables.Si la demande est retournée après les heures d'ouverture, veuillez attendre le lendemain pour votre demande. à traiter.",
    faqLoanQuestion6:
      'Quels sont les taux et frais applicables à vos facilités de crédit?',
    faqLoanAnswer6:
      "Notre taux d'intérêt annuel est de 19% pour chaque prêt. Nous facturons également des frais d'adhésion hebdomadaires de 20,00 $ jusqu'au paiement intégral du solde.Pour toutes les demandes d'avances supplémentaires, des frais de renouvellement de 50,00 $ s'appliquent.",
  },
  sharedFormTexts: {
    next: 'Prochain',
    yes: 'Oui',
    no: 'Non',
  },
  applyPage: {
    step1: 'Vos informations personnelles',
    step2: 'Connectez votre banque',
    step3: 'Signer le contrat',
    step4: 'Application terminé',
  },
  applicationSubmittedForm: {
    formTitle: 'Application déposée!',
    formSubtitle:
      'Nous examinerons votre demande et vous informerons lorsque la décision sera prise.',
    goToDashboard: 'Allez au tableau de bord',
  },
  transferMethodForm: {
    formTitle: 'Sélectionnez la méthode de transfert',
    formSubtitle:
      'Faites-nous savoir comment vous souhaitez que votre argent soit transféré.',
    eTransfer: 'Virement Interac',
    eTransferDescription:
      'Vous pouvez faire déposer automatiquement les fonds par virement Interac dans votre compte bancaire.',
    directDeposit: 'Dépôt direct',
    directDepositDescription:
      "Le dépôt direct est une option de paiement où vos fonds sont transférés électroniquement à votre compte chèque ou d'épargne.",
  },
  contractForm: {
    formTitle: 'Signer le contrat',
    formSubtitle:
      'Vous avez presque terminé votre candidature ! Lisez et signez ce contrat pour soumettre votre candidature.',
    confirmContinue:
      "Assurez-vous d'avoir signé le contrat, sinon votre demande pourrait ne pas être traitée intégralement...",
  },
  ibvForm: {
    connectButton: 'Connectez votre compte bancaire avec Plaid',
    formTitle: 'Vérification bancaire sécurisée',
    formSubtitle:
      "Effectuez votre vérification en toute sécurité en quelques secondes à l'aide de la vérification bancaire instantanée (IBV).",
  },
  secondaryContactForm: {
    formSubtitle:
      "Ces informations nous aident à nous contacter en cas d'urgence.",
    spouse: 'Époux',
    father: 'Père',
    mother: 'Mère',
    child: 'Enfant',
    friend: 'Ami',
    sister: 'Sœur',
    brother: 'Frère',
    other: 'Autre',
  },
  bankDetailsForm: {
    formTitle: 'Informations bancaires',
    formSubtitle:
      'Vos informations bancaires nous aideront à approuver votre demande.',
    chooseBank: 'Choisissez votre banque',
    bmo: 'Banque de Montréal',
    bns: 'Banque de Nova Scotia',
    rbc: 'Banque Royale',
    td: 'TD Canada Trust',
    nb: 'Banque Nationale',
    cibc: 'CIBC',
    lbc: 'Banque Laurenienne',
    des: 'Desjardins',
    tan: 'Tangerine',
    planningBankruptcy:
      "Envisagez-vous de déclarer faillite,\nproposition de consommateur ou dépôt volontaire à l'avenir?",
    hadBankruptcy:
      'Avez-vous déjà fait faillite, proposition de consommateur ou dépôt volontaire?',
    other: 'Autre',
  },
  jobDetailsForm: {
    formTitle: "Information sur l'emploi",
    subTitle:
      'Vos informations professionnelles nous aideront à approuver votre candidature.',
    sourceOfIncome: 'Source de revenu',
    select: 'Sélectionnez',
    employmentInsurance: "L'assurance-emploi",
    qpip: 'R.P.Q.P.',
    employed: 'Employé(e)',
    selfEmployed: 'Travailleur indépendant',
    temporaryCSST: 'C.S.S.T. Temporaire',
    permanentCSST: 'C.S.S.T. Pour la vie',
    temporarySAAQ: 'S.A.A.Q. Temporaire',
    permanentSAAQ: 'S.A.A.Q. Pour la vie',
    socialInsurance: 'Assistance sociale',
    socialInsuranceStartDate:
      "Quand vos prestations d'assiramce-emploi commencent",
    paidByDirectDeposit: 'Êtes-vous payé par dépôt direct?',
    employerName: "Nom de l'Employeur",
    employerPhoneNumber: "Numéro de téléphone de l'employeur",
    jobDescription: 'Description du poste',
    supervisorName: 'Nom du Superviseur',
    hiringDate: "Date approximative d'embauch",
  },
  personalDetailsForm: {
    formTitle: 'Informations personnelles',
    subTitle:
      'Ruby est tenu de collecter ces informations pour votre candidature.',
    formBottomText:
      "Fournir votre numéro d'assurance sociale n'est pas obligatoire, mais pourrait accélérer le traitement de votre demande",
  },
  pageTitle: {
    home: 'Accueil',
  },
  error: {
    passwordComplexityError:
      'Le mot de passe doit comporter au moins 12 caractères et inclure au moins une lettre majuscule, une lettre minuscule et un chiffre.',
  },
  errorPage: {
    title: 'Oops ! Page non trouvée',
    content:
      "La page que vous cherchez n'existe plus ou a été déplacée, ou est temporairement indisponible.",
    button: "Retourner à la page d'accueil",
  },
  underConstructionPage: {
    title: 'Oups ! Page en construction !',
    content:
      'La page que vous recherchez est actuellement en construction. Veuillez réessayer ultérieurement.',
  },
}

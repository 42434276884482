<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Blog Details" />
      <BlogDetails />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import BlogDetails from '@/components/Unused/BlogDetails/BlogDetails'

export default {
  name: 'BlogDetailsPage',
  components: {
    PageTitle,
    BlogDetails,
  },
}
</script>

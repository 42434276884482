<template>
  <div class="table-container">
    <b-table
      v-if="accounts && accounts.length > 0"
      responsive
      :items="accounts"
    >
      <template #cell(balances)="row">
        <b-table :items="[row.item.balances]" :fields="balanceFields"></b-table>
      </template>
    </b-table>
    <b-table
      v-if="transactions && transactions.length > 0"
      responsive
      :items="transactions"
    >
      <template #cell(balances)="row">
        <b-table :items="[row.item.balances]" :fields="balanceFields"></b-table>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'UserDetailsCard',
  props: ['accounts', 'transactions'],
  data() {
    return {
      balanceFields: [
        { key: 'available', label: 'Available' },
        { key: 'current', label: 'Current' },
        { key: 'iso_currency_code', label: 'Currency' },
        { key: 'limit', label: 'Limit' },
        { key: 'unofficial_currency_code', label: 'Unofficial Currency' },
      ],
    }
  },
}
</script>

<style>
.table-container {
  max-width: 1220px;
  margin: 0 auto;
}
</style>

<template>
  <div class="container col-6 mt-3">
    <h5>{{ $t('applyPage.step1') }}</h5>
    <div class="edit-button">
      <button class="btn style3" @click="toggleEditMode()">
        {{ showEdit ? 'Cancel' : 'Edit' }}
      </button>
    </div>

    <form @submit.prevent>
      <div v-if="!showEdit">
        <h2>{{ personalDetails.firstName }} {{ personalDetails.lastName }}</h2>
      </div>
      <div v-if="showEdit">
        <label for="firstName">{{ $t('firstName') }}</label>
        <input
          :placeholder="personalDetails.firstName"
          v-model="editedPersonalDetails.firstName"
          type="text"
          id="firstName"
        />
        <label for="lastName">{{ $t('lastName') }}</label>
        <input
          :placeholder="personalDetails.lastName"
          v-model="editedPersonalDetails.lastName"
          type="text"
          id="lastName"
        />
      </div>
      <div v-if="!showEdit">
        <label for="dateOfBirth">
          {{ $t('dateOfBirth') }} {{ personalDetails.dateOfBirth }}
        </label>
      </div>
      <div v-if="showEdit">
        <label>{{ $t('dateOfBirth') }}</label>

        <input
          :placeholder="personalDetails.dateOfBirth"
          v-model="editedPersonalDetails.dateOfBirth"
          type="text"
          id="dateOfBirth"
        />
      </div>

      <div v-if="!showEdit">
        <label>
          {{ $t('sin') }}
          {{ personalDetails.socialInsuranceNumber }}</label
        >
      </div>
      <div v-if="showEdit">
        <label for="socialInsuranceNumber">
          {{ $t('sin') }}
        </label>
        <input
          :placeholder="personalDetails.socialInsuranceNumber"
          v-model="editedPersonalDetails.socialInsuranceNumber"
          type="text"
          id="socialInsuranceNumber"
        />
      </div>
      <button
        class="save-button"
        v-if="showEdit"
        type="submit"
        @click="saveChanges"
      >
        {{ $t('save') }}
      </button>
    </form>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'
import { getServerFormatDate } from '@/helpers/textFormatter'
import { useRepo } from 'pinia-orm'
import { PersonalDetails } from '@/models/Models'
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'

export default {
  name: 'PersonalDetailsCard',
  props: {},
  data() {
    return {
      editedPersonalDetails: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        socialInsuranceNumber: '',
        email: '',
      },
      personalDetails: {},
      showEdit: false,
    }
  },
  mounted() {
    setTimeout(this.laodDetails, 1000)
  },
  methods: {
    async laodDetails() {
      try {
        const fetchDetailsFrom = httpsCallable(functions, 'fetchDetailsFrom')
        const response = await fetchDetailsFrom({
          uid: this.loggedInUser.uid,
          storage: 'personalDetails',
        })
        if (response.data.personalDetails[0] != 'no personal details found') {
          useRepo(PersonalDetails).save(response.data.personalDetails)

          console.log(response.data.personalDetails)

          let personalDetails = useRepo(PersonalDetails).all()[0]
          this.personalDetails = { ...personalDetails }
        }
      } catch (error) {
        console.error(
          'Error calling laodDetails in personalDetailsCard:',
          error,
        )
      }
    },
    toggleEditMode() {
      this.showEdit = !this.showEdit
    },
    async saveChanges() {
      if (this.showEdit) {
        let personalDetailsEdit = {}

        const updateFieldIfModified = (field, editedField) => {
          const trimmedValue = editedField.trim()
          if (trimmedValue !== '' && trimmedValue !== field) {
            return trimmedValue
          }
          return field
        }

        personalDetailsEdit.firstName = updateFieldIfModified(
          this.personalDetails.firstName,
          this.editedPersonalDetails.firstName,
        )
        personalDetailsEdit.lastName = updateFieldIfModified(
          this.personalDetails.lastName,
          this.editedPersonalDetails.lastName,
        )
        personalDetailsEdit.dateOfBirth = updateFieldIfModified(
          this.personalDetails.dateOfBirth,
          this.editedPersonalDetails.dateOfBirth,
        )
        personalDetailsEdit.socialInsuranceNumber = updateFieldIfModified(
          this.personalDetails.socialInsuranceNumber,
          this.editedPersonalDetails.socialInsuranceNumber,
        )

        console.log(this.loggedInUser.uid)

        try {
          const personalDetailsUpdate = {
            uid: this.loggedInUser.uid,
            updateTime: getServerFormatDate(),
            ...personalDetailsEdit,
          }

          console.log(personalDetailsUpdate)
          const updateFirestore = httpsCallable(functions, 'updateFirestore')
          const response = await updateFirestore({
            personalDetails: personalDetailsUpdate,
          })

          console.log(response.data.message)
          this.toggleEditMode()
          setTimeout(this.laodDetails, 1000)
        } catch (error) {
          console.error(error)
        }
      }
    },
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
}
</script>
<style scoped>
.container {
  position: relative;
}

.edit-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.save-button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
}
</style>

<template>
  <div class="security-wrap ptb-100">
    <div class="container">
      <div class="row gx-5 align-items-center">
        <div class="col-lg-6 col-12 order-lg-1 order-2">
          <div class="security-content">
            <div class="content-title style1">
              <span>Banking Security</span>
              <h2>The Safest Way To Transact Your Money Fast</h2>
              <p>
                There are many variations of passages of Lorem Ipsum amet
                avoilble but majority have suffered alteration in some form, by
                injected humur or randomise words which don't sure amet sit
                dolor quras alto lorem.
              </p>
            </div>
            <div class="feature-item-wrap">
              <div class="feature-item">
                <div class="feature-icon">
                  <img
                    src="@/assets/images/security/security-icon-1.png"
                    alt="Image"
                  />
                </div>
                <div class="feature-text">
                  <h3>Pay Online Securely</h3>
                  <p>
                    Vestibulum ac diam sit amet quam vehicula elemen tum sed sit
                    amet dui praesent sapien pellen tesque .
                  </p>
                </div>
              </div>
              <div class="feature-item">
                <div class="feature-icon">
                  <img
                    src="@/assets/images/security/security-icon-2.png"
                    alt="Image"
                  />
                </div>
                <div class="feature-text">
                  <h3>Convert Your Money In Seconds</h3>
                  <p>
                    Vestibulum ac diam sit amet quam vehicula elemen tum sed sit
                    amet dui praesent sapien pellen tesque.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 order-lg-2 order-1">
          <div class="security-img-wrap">
            <img
              src="@/assets/images/security/security-shape-1.png"
              alt="Image"
              class="security-shape-one"
            />
            <img
              src="@/assets/images/security/security-shape-2.png"
              alt="Image"
              class="security-shape-two"
            />
            <img src="@/assets/images/security/security-1.png" alt="Image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BankingSecurity',
}
</script>

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Terms Of Service" />
      <TermsOfService />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import TermsOfService from '@/components/Unused/TermsOfService/TermsOfService'

export default {
  name: 'TermsOfServicePage',
  components: {
    PageTitle,
    TermsOfService,
  },
}
</script>

<template>
  <div v-if="loggedInUser" class="container my-5 p-10">
    <div v-if="showSuccessPopup" class="popup-layout">
      <div class="popup">
        <div class="container d-flex justify-content-center">
          <p>{{ $t('filesUpload.uploadSuccesful') }}</p>
        </div>
        <div class="container d-flex justify-content-center">
          <button class="btn style2" @click="closeSuccessPopup">
            {{ $t('close') }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="showFailedPopup" class="popup-layout">
      <div class="popup">
        <div class="container d-flex justify-content-center">
          <p>{{ $t('filesUpload.uploadFailed') }}</p>
        </div>
        <div class="container d-flex justify-content-center">
          <button class="btn style2" @click="closeFailedPopup">
            {{ $t('close') }}
          </button>
        </div>
      </div>
    </div>
    <h3 class="text-center mb-5">{{ $t('filesUpload.title') }}</h3>
    <div class="row">
      <div
        class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
      >
        <form class="form-group" @submit.prevent>
          <div class="row mb-3 p-2 border">
            <div class="col-2 text-center">
              <h1 class="circle-number">1</h1>
            </div>
            <div class="col-10">
              <h4>{{ $t('filesUpload.titleBankStatement') }}</h4>
              <p>
                {{ $t('filesUpload.descriptionBankStatement') }}
              </p>
              <input
                type="file"
                class="form-control-file"
                ref="bankStatementFile"
                :accept="this.acceptFileFormat"
                @change="onFileChange"
              />
              <div v-if="downloadUrls.bankStatement" class="m-3">
                <div class="image-container">
                  <img
                    :src="downloadUrls.bankStatement"
                    style="max-width: 150px; height-width: 150px"
                  />
                  <button class="delete-btn" @click="deleteFile(key)">
                    <span class="delete-icon">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 p-2 border">
            <div class="col-2 text-center">
              <h1 class="circle-number">2</h1>
            </div>
            <div class="col-10">
              <h4>{{ $t('filesUpload.titlePaySlip') }}</h4>
              <p>
                {{ $t('filesUpload.descriptionPaySlip') }}
              </p>
              <input
                type="file"
                class="form-control-file"
                ref="paySlipFile"
                :accept="this.acceptFileFormat"
                @change="onFileChange"
              />
              <div v-if="downloadUrls.paySlip" class="m-3">
                <div class="image-container">
                  <img
                    :src="downloadUrls.paySlip"
                    style="max-width: 150px; height: auto"
                  />
                  <button class="delete-btn" @click="deleteFile(key)">
                    <span class="delete-icon">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 p-2 border">
            <div class="col-2 text-center">
              <h1 class="circle-number">3</h1>
            </div>
            <div class="col-10">
              <h4>{{ $t('filesUpload.titleProofOfId') }}</h4>
              <p>
                {{ $t('filesUpload.descriptionProofOfId') }}
              </p>
              <input
                type="file"
                class="form-control-file"
                ref="proofOfIdFile"
                :accept="this.acceptFileFormat"
                @change="onFileChange"
              />
              <div v-if="downloadUrls.proofOfId" class="m-3">
                <div class="image-container">
                  <img
                    :src="downloadUrls.proofOfId"
                    style="max-width: 150px; height: auto"
                  />
                  <button class="delete-btn" @click="deleteFile(key)">
                    <span class="delete-icon">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 p-2 border">
            <div class="col-2 text-center">
              <h1 class="circle-number">4</h1>
            </div>
            <div class="col-10">
              <h4>{{ $t('filesUpload.titleProofOfResidency') }}</h4>
              <p>{{ $t('filesUpload.descriptionProofOfResidency') }}</p>
              <input
                type="file"
                class="form-control-file"
                ref="proofOfResidencyFile"
                :accept="this.acceptFileFormat"
                @change="onFileChange"
              />
              <div v-if="downloadUrls.proofOfResidency" class="m-3">
                <div class="image-container">
                  <img
                    :src="downloadUrls.proofOfResidency"
                    style="max-width: 150px; height: auto"
                  />
                  <button class="delete-btn" @click="deleteFile(key)">
                    <span class="delete-icon">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 p-2 border">
            <div class="col-2 text-center">
              <h1 class="circle-number">5</h1>
            </div>
            <div class="col-10">
              <h4>{{ $t('filesUpload.titleVoidCheck') }}</h4>
              <p>
                {{ $t('filesUpload.descriptionVoidCheck') }}
              </p>
              <input
                type="file"
                class="form-control-file"
                ref="voidCheckFile"
                :accept="this.acceptFileFormat"
                @change="onFileChange"
              />
              <div v-if="downloadUrls.voidCheck" class="m-3">
                <div class="image-container">
                  <img
                    :src="downloadUrls.voidCheck"
                    style="max-width: 150px; height: auto"
                  />
                  <button class="delete-btn" @click="deleteFile(key)">
                    <span class="delete-icon">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 p-2 border">
            <div class="col-2 text-center">
              <h1 class="circle-number">6</h1>
            </div>
            <div class="col-10">
              <h4>{{ $t('filesUpload.titleOtherDocuments') }}</h4>
              <p>{{ $t('filesUpload.descriptionOtherDocuments') }}</p>
              <input
                type="file"
                class="form-control-file"
                ref="otherDocumentsFile"
                :accept="this.acceptFileFormat"
                @change="onFileChange"
              />
              <div v-if="downloadUrls.otherDocuments" class="m-3">
                <div class="image-container">
                  <img
                    :src="downloadUrls.otherDocuments"
                    style="max-width: 150px; height: auto"
                  />
                  <button class="delete-btn" @click="deleteFile(key)">
                    <span class="delete-icon">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button
              type="submit"
              class="btn style1"
              :disabled="disableUpload"
              @click="uploadFiles"
            >
              {{ $t('filesUpload.upload') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'

export default {
  name: 'FilesUpload',
  data() {
    return {
      acceptFileFormat: '.jpg,.jpeg,.png,.pdf',
      disableUpload: true,
      showSuccessPopup: false,
      showFailedPopup: false,
      downloadUrls: {},
    }
  },
  mounted() {
    this.getUserFiles()
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
  },
  methods: {
    async getUserFiles() {
      try {
        const getUserFilesFromStorage = httpsCallable(
          functions,
          'getUserFilesFromStorage',
        )

        // Call the new function to retrieve the user's files from storage
        const response = await getUserFilesFromStorage()
        this.downloadUrls = response.data.userFiles // assuming the function returns an object with userFiles property
        console.log(this.downloadUrls)
      } catch (error) {
        console.error(error)
      }
    },
    async uploadFiles() {
      try {
        const files = {}

        // Read the contents of each file as a data URL and store it in the files object
        const readFile = (file) => {
          return new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              resolve(reader.result)
            }
          })
        }

        if (this.$refs.bankStatementFile.files[0]) {
          files.bankStatement = await readFile(
            this.$refs.bankStatementFile.files[0],
          )
        }

        if (this.$refs.paySlipFile.files[0]) {
          files.paySlip = await readFile(this.$refs.paySlipFile.files[0])
        }

        if (this.$refs.proofOfIdFile.files[0]) {
          files.proofOfId = await readFile(this.$refs.proofOfIdFile.files[0])
        }

        if (this.$refs.proofOfResidencyFile.files[0]) {
          files.proofOfResidency = await readFile(
            this.$refs.proofOfResidencyFile.files[0],
          )
        }

        if (this.$refs.voidCheckFile.files[0]) {
          files.voidCheck = await readFile(this.$refs.voidCheckFile.files[0])
        }

        if (this.$refs.otherDocumentsFile.files[0]) {
          files.otherDocuments = await readFile(
            this.$refs.otherDocumentsFile.files[0],
          )
        }

        console.log(files)
        const saveDocumentsToStorage = httpsCallable(
          functions,
          'saveDocumentsToStorage',
        )

        // Call the modified function and retrieve the download URLs from the response
        const response = await saveDocumentsToStorage(files)
        console.log(response)

        this.getUserFiles()

        this.showSuccessPopup = true
      } catch (error) {
        this.showFailedPopup = true
        console.error(error)
      }
    },
    closeSuccessPopup() {
      this.showSuccessPopup = false
    },
    closeFailedPopup() {
      this.showFailedPopup = false
    },
    onFileChange(event) {
      this.disableUpload = event.target.files.length == 0
    },
  },
}
</script>

<style>
.image-container {
  display: inline-block;
  position: relative;
  max-width: 150px;
  height: auto;
}

.delete-btn {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #000;
  cursor: pointer;
}

.delete-btn:hover .delete-icon {
  color: #f44336;
}

.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  padding: 5px;
}
</style>

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="À propos" />
      <SmartBanking />
      <Services />
      <OnlineShopping />
      <AppDownload />
      <GoalSettings />
      <Testimonials />
      <BankingSecurity />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import SmartBanking from '@/components/Unused/About/SmartBanking'
import Services from '@/components/Unused/About/Services'
import OnlineShopping from '@/components/Unused/About/OnlineShopping'
import AppDownload from '@/components/Unused/About/AppDownload'
import GoalSettings from '@/components/Unused/About/GoalSettings'
import Testimonials from '@/components/Unused/About/Testimonials'
import BankingSecurity from '@/components/Unused/About/BankingSecurity'

export default {
  name: 'AboutPage',
  components: {
    PageTitle,
    SmartBanking,
    Services,
    OnlineShopping,
    AppDownload,
    GoalSettings,
    Testimonials,
    BankingSecurity,
  },
}
</script>

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Project" />
      <Projects />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import Projects from '@/components/Unused/Projects/Projects'

export default {
  name: 'ProjectPage',
  components: {
    PageTitle,
    Projects,
  },
}
</script>

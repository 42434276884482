<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Pricing Plan" />
      <Pricing />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import Pricing from '@/components/Unused/Pricing/Pricing'

export default {
  name: 'PricingPage',
  components: {
    PageTitle,
    Pricing,
  },
}
</script>

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Services" />
      <Services />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import Services from '@/components/Unused/Services/Services'

export default {
  name: 'ServicesPage',
  components: {
    PageTitle,
    Services,
  },
}
</script>

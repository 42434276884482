<template>
  <section class="why-choose-wrap style1 pb-100">
    <div class="container">
      <div class="row gx-5 align-items-center">
        <div class="col-lg-6">
          <div class="wh-img-wrap">
            <img
              src="../../assets/images/why-choose-us/wh-img-1.png"
              alt="Image"
            />
            <img
              class="wh-shape-one bounce"
              src="../../assets/images/why-choose-us/wh-shape-1.png"
              alt="Image"
            />
            <img
              class="wh-shape-two animationFramesTwo"
              src="../../assets/images/why-choose-us/wh-shape-2.png"
              alt="Image"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="wh-content">
            <div class="content-title style1">
              <span></span>
              <h2>{{ $t('whyChooseUs.title') }}</h2>
              <p>
                {{ $t('whyChooseUs.textUnderTitle') }}
              </p>
            </div>
            <div class="feature-item-wrap">
              <div class="feature-item">
                <span class="feature-icon">
                  <i class="flaticon-graph"></i>
                </span>
                <div class="feature-text">
                  <h3>{{ $t('whyChooseUs.reasonTitle1') }}</h3>
                  <p>
                    {{ $t('whyChooseUs.reasonText1') }}
                  </p>
                </div>
              </div>
              <div class="feature-item">
                <span class="feature-icon">
                  <i class="flaticon-loan-1"></i>
                </span>
                <div class="feature-text">
                  <h3>{{ $t('whyChooseUs.reasonTitle2') }}</h3>
                  <p>
                    {{ $t('whyChooseUs.reasonText2') }}
                  </p>
                </div>
              </div>
              <div class="feature-item">
                <span class="feature-icon">
                  <i class="flaticon-computer"></i>
                </span>
                <div class="feature-text">
                  <h3>{{ $t('whyChooseUs.reasonTitle3') }}</h3>
                  <p>
                    {{ $t('whyChooseUs.reasonText3') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhyChooseUs',
}
</script>

<template>
  <div class="loader js-preloader">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
}
</script>

<template>
  <div class="error-wrapper">
    <div class="error-wrap">
      <div class="error">
        <div class="container">
          <div class="error-content">
            <img src="../../assets/images/404.png" alt="Iamge" />
            <h2>{{ $t('myCalendar.errorTitle') }}</h2>
            <p>
              {{ $t('myCalendar.errorContent') }}
            </p>
            <router-link to="/" class="btn style2">{{
              $t('errorPage.button')
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoLoanErrorPage',
}
</script>

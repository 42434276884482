<template>
  <div class="page-wrapper">
    <MainBanner />
    <SmartBanking />
    <Feature />
    <BankingSecurity />
    <Services />
    <AppDownload />
    <Testimonials />
    <PricingPlan />
    <ContactUs />
    <Blog />
  </div>
</template>

<script>
import MainBanner from '@/components/Unused/HomeThree/MainBanner'
import SmartBanking from '@/components/Unused/HomeThree/SmartBanking'
import Feature from '@/components/Unused/HomeThree/Feature'
import BankingSecurity from '@/components/Unused/HomeThree/BankingSecurity'
import Services from '@/components/Unused/HomeThree/Services'
import AppDownload from '@/components/Unused/HomeThree/AppDownload'
import Testimonials from '@/components/Unused/HomeThree/Testimonials'
import PricingPlan from '@/components/Unused/Home/PricingPlan'
import ContactUs from '@/components/Unused/HomeThree/ContactUs'
import Blog from '@/components/Unused/HomeThree/Blog'

export default {
  name: 'HomeThreePage',
  components: {
    MainBanner,
    SmartBanking,
    Feature,
    BankingSecurity,
    Services,
    AppDownload,
    Testimonials,
    PricingPlan,
    ContactUs,
    Blog,
  },
}
</script>

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Project Details" />
      <ProjectDetails />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import ProjectDetails from '@/components/Unused/ProjectDetails/ProjectDetails'

export default {
  name: 'ProjectDetailsPage',
  components: {
    PageTitle,
    ProjectDetails,
  },
}
</script>

<template>
  <div class="counter-wrap">
    <div class="container">
      <div class="counter-card-wrap">
        <div class="counter-card">
          <div class="counter-text">
            <div class="counter-num">
              <span>{{ $t('counter.counterValue1') }}</span>
            </div>
            <p>{{ $t('counter.counterTitle1') }}</p>
          </div>
        </div>
        <div class="counter-card">
          <div class="counter-text">
            <div class="counter-num">
              <span>{{ $t('counter.counterValue2') }}</span>
            </div>
            <p>{{ $t('counter.counterTitle2') }}</p>
          </div>
        </div>
        <div class="counter-card">
          <div class="counter-text">
            <div class="counter-num">
              <span>{{ $t('counter.counterValue3') }}</span>
            </div>
            <p>{{ $t('counter.counterTitle3') }}</p>
          </div>
        </div>
        <div class="counter-card">
          <div class="counter-text">
            <div class="counter-num">
              <span>{{ $t('counter.counterValue4') }}</span>
            </div>
            <p>{{ $t('counter.counterTitle4') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Counter',
}
</script>

export default {
  save: 'Save',
  loading: 'Loading...',
  register: 'Register',
  login: 'Login',
  logout: 'Logout',
  close: 'Close',
  getALoan: 'Get a loan',
  forgotPasswordLink: 'Forgot your password?',
  email: 'Email',
  numberOfAccountsLinked: 'Number of accounts linked',
  userTransactions: 'User Transactions',
  requestID: 'Request ID',
  dateRequested: 'Date requested',
  contract: 'Contract',
  approve: 'Approve',
  deny: 'Deny',
  expand: 'Expand',
  minimize: 'Minimize',
  delete: 'Delete',
  refresh: 'Refresh',
  loanAmount: 'Amount',
  lastName: 'Last Name',
  firstName: 'First Name',
  dateOfBirth: 'Date of Birth',
  sin: 'Social Insurance Number',
  relationToUser: 'Your relationship',
  phoneNumber: 'Phone number',
  contactDetails: 'Contact Information',
  cellPhone: 'Cell phone',
  homePhone: 'Home phone',
  addressLine1: 'Adress (line 1)',
  addressLine2: 'Adress (line 2)',
  city: 'City',
  province: 'Province',
  postalCode: 'Postal Code',
  secondaryContact: 'Secondary contact info',
  paymentsPage: {
    myPayments: 'My payments',
    setUpPayments: 'Set up your payments',
    intialLoan: 'Initial loan',
  },
  viewContractPage: {
    myContract: 'My Contract',
  },
  loanStatus: {
    approved: 'Approved',
    inProgress: 'In Review',
    sent: 'Contract is unsigned or not approved yet',
    signed: 'Contract Signed',
    rejected: 'Denied',
    completed: 'Paid Back',
  },
  myCalendar: {
    errorTitle: 'No active loan found',
    errorContent: 'When you have an approved loan your callendar will appears',
    next: 'Next >',
    previous: '< Previous',
    paymentDue: 'Payment due!',
    january: 'January',
    febuary: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
  },
  myDashboard: {
    welcome: 'Welcome',
    loanApproved:
      'Your loan has been approved, you should received your funds within 24 hours.',
    loanPending:
      'Your loan is currently pending approval. You will receive a notice when it is approved.',
    makeAPayment: 'Make a payment',
    viewStatement: 'View statement',
    renewLoan: 'Renew loan',
    viewContract: 'View contract',
    renewLoanCondition:
      "You'll be able to renew your loan when more than 60% of the loan is paid off.",
    noPendingLoans: "You don't have any pending loans, apply for a loan now!",
  },
  filesUpload: {
    title: 'Upload Documents',
    titleBankStatement: 'Your bank statements for the last 3 months',
    descriptionBankStatement:
      'It must include your full name, account number and dated within the most recent and full 3 months.',
    titlePaySlip: 'Your last pay slip',
    descriptionPaySlip:
      'It must include your full name, full name of employer and salary.',
    titleProofOfId: 'Valid proof of identity and your face',
    descriptionProofOfId:
      "Please take a photo of yourself holding up your current valid driver's license or passport against a white background.",
    titleProofOfResidency: 'Valid proof of residency',
    descriptionProofOfResidency:
      'This could be your lease or home utility bills.',
    titleVoidCheck: 'A void cheque',
    descriptionVoidCheck:
      'Must include your full name, bank routing number and your account number.',
    titleOtherDocuments: 'Other documents.',
    descriptionOtherDocuments:
      'Any document you might think would help us make our decision.',
    upload: 'Upload',
    uploadSuccesful: 'Upload successful!',
    uploadFailed: 'Upload Failed, try again...',
    previousPage: 'my-dashboard',
  },
  frontApply: {
    applyNow: 'Apply today to take advantage of our ultra-flexible loans',
    selectAmount: 'Select your loan amount',
    dateLoanWanted: 'When will you get paid next?',
    paycheckFrequency: 'How often do you get paid?',
    payApproximately: "You'll pay approximately",
    payBackTime:
      "Every time you get paid. You'll pay off the loan in approximately",
    askForLoan: 'APPLY FOR THIS LOAN',
    agreeToTerms: "By clicking 'Apply for this loan', I am agree with",
    termsAndPolicy: 'Terms & Policy',
    weekly: 'Weekly',
    everyTwoWeeks: 'Every Two Weeks',
    twiceAMonth: 'Twice A Month',
    monthly: 'Monthly',
  },
  whyChooseUs: {
    title: 'Get World Class & Fastest Online Payment Service',
    textUnderTitle:
      'No matter how many mistakes people have made in the past. Everyone deserves another try. That is why we offer flexible loans to people regardless of their background.',
    reasonTitle1: 'Low Costing',
    reasonText1:
      'Our loans have affordable and transparent interest rates to facilitate repayment.',
    reasonTitle2: 'Safe & Secure',
    reasonText2:
      "We use the latest security technologies to protect our customers' confidential information.",
    reasonTitle3: 'Live Support',
    reasonText3:
      'Our support team is available 24/7 to assist customers whenever they need help.',
  },
  contactUs: {
    title: 'PretRuby is a quick solution for small personal loans',
    shortTitle: 'Contact',
    faqButton: 'FAQ About Loans',
    ourLocationTitle: 'Our Offices',
    ourLocationText:
      '304 Av. de Grand-Mère, Shawinigan, Qubec, Canada, G9T 2H5',
    emailUsTitle: 'Send us an email',
    emailUsText1: 'support',
    emailUsText2: 'pretruby.ca',
    callUsTitle: 'Call us',
    callUsText: '+1 514 500 5936',
    contactFormSuperTitle: 'Send us a message',
    contactFormTitle: 'Do you have any questions?',
    contactFormName: 'Your name',
    contactFormEmail: 'Your email',
    contactFormPhoneNumber: 'Your phone number',
    contactFormSubject: 'Title',
    contactFormMessageFill: 'Your message',
    contactFormSend: 'Send',
  },
  feature: {
    featureTitle1: 'Create An Account',
    featureText1:
      'Sign up in minutes and start using our platform for your money transfers.',
    featureTitle2: 'Attach bank Account',
    featureText2: 'Connect your bank account to securely transfer money.',
    featureTitle3: 'Send Money',
    featureText3:
      'Send money to your loved ones with a few clicks from our easy-to-use platform.',
  },
  counter: {
    counterTitle1: 'Satisfied Customers',
    counterValue1: '6342',
    counterTitle2: 'Client satisfaction',
    counterValue2: '98%',
    counterTitle3: 'Automated approval',
    counterValue3: 'YES',
    counterTitle4: 'Successful Projects',
    counterValue4: '433',
  },
  navbar: {
    support: 'Support',
    help: 'Aide',
    language: 'Français',
    loggedInAs: 'Logged in as ',
    dropdownTitle: 'Menu',
    userPages: 'User Pages',
    userAccount: 'My Account',
    userCalendar: 'My Calendar',
    legalMention: 'Legal Mentions',
    ourTeam: 'Our Team',
    testimonials: 'Testimonials',
    userDashboard: 'Dashboard',
    loanFileUpload: 'Related File Uploads',
  },
  footer: {
    leftText:
      '"If your application is complete, it will be approved within 30 minutes and you will receive an email to deposit your funds via Interac e-Transfer. If you are unable to accept Interac e-Transfers, we will send a direct deposit and the funds will appear in your account within 24 business hours.',
    rightText: 'You will not be dissapointed when trusting PretRuby',
    copyright: 'Copyright © 2023',
    reserved: 'PretRuby. All Rights Reserved.',
    ourCompany: 'Our company',
    link1: 'Company & Team',
    link2: 'Pricing Plan',
    link3: 'Contact Us',
    link4: 'Mention légale',
    footerContactTitle: 'Contact Us',
    footerContactLine1: '304 Av. de Grand-Mère',
    footerContactLine2: 'Shawinigan, Qubec, Canada',
    footerContactLine3: 'G9T 2H5',
    footerContactLine4: '+1 514 500-5936',
    subscribeToNewsletter: 'Subscribe',
  },
  faq: {
    title: 'Frequently Asked Questions',
    faqPaymentTitle: 'Payments',
    faqPaymentQuestion1: 'How will I receive the funds?',
    faqPaymentAnswer1:
      'You will receive an Interac e-Transfer within an hour after being approved. Our team will assist you in depositing your funds. If you cannot receive an Interac e-transfer, our team can make a direct deposit directly into your account. In this case, allow up to 24 business hours for receipt of funds',
    faqPaymentQuestion2: 'How will I repay my loan?',
    faqPaymentAnswer2:
      'Your loan is repaid by direct debit from your bank account. You can view your payment schedule in your profile.',
    faqPaymentQuestion3: "What happens if I can't make my payment?",
    faqPaymentAnswer3:
      'You must contact us at least 72 business hours prior to the date of withdrawal. Deferred payment will be postponed in the schedule, unless otherwise specified. A fee of $ 10 applies, however you will not have to pay an NSF fee.',
    faqPaymentQuestion4: "What happens if I can't repay my loan?",
    faqPaymentAnswer4:
      'In the event of default, the debt will be sent to a collection agency for collection. All collection costs will be borne by the borrower in accordance with the law.',
    faqAccountTitle: 'Account',
    faqAccountQuestion1: 'Are your services secure?',
    faqAccountAnswer1:
      'Yes, our services meet the highest standards in the financial industry. We use the same procedures as the large financial institutions. We will never have access to your bank account.',
    faqAccountQuestion2: 'Do you have access to my bank account?',
    faqAccountAnswer2:
      'No, we only have access to the information that allows us to make a decision regarding your request. We will never have access to your bank account or your password.',
    faqAccountQuestion3: 'How can I change my password?',
    faqAccountAnswer3:
      'Your password can be changed at any time while you are logged into your account. Simply go to your profile, click on the wrench icon, enter your old password and your new password in the fields and your password will be changed. (Currently Unavailable, use forgot password to reset)',
    faqAccountQuestion4: 'What should I do if I lose my password?',
    faqAccountAnswer4:
      'If you forget your password, click on the forgot password link on the login page. Enter the email associated with your account and click on Reset Password. You will receive an email with further instructions on how to reset your password. If you do not receive the email, please check your spam.',
    faqAdmissibilityTitle: 'Eligibility',
    faqAdmissibilityQuestion1:
      'Do I have to earn a salary to use your services?',
    faqAdmissibilityAnswer1:
      'You must earn a minimum net income of $ 1,200 per month.',
    faqAdmissibilityQuestion2:
      'Do you have to be a resident of Quebec to use your services?',
    faqAdmissibilityAnswer2:
      'Yes, we only offer our services in Quebec for the moment.',
    faqAdmissibilityQuestion3: 'How long does it take to receive the funds?',
    faqAdmissibilityAnswer3:
      'If your request is complete, it will be approved within 30 minutes and you will receive an email to deposit your funds by Interac e-Transfer within the hour.',
    faqAdmissibilityQuestion4: 'Why would my loan application be refused?',
    faqAdmissibilityAnswer41:
      'Your application may be refused for one or more of the following reasons:',
    faqAdmissibilityAnswer42: 'Unstable employment history',
    faqAdmissibilityAnswer43: 'Numerous bad payments made to your account',
    faqAdmissibilityAnswer44:
      'Incomplete form or inaccurate information Unpaid',
    faqAdmissibilityAnswer45: 'Bankruptcy',
    faqAdmissibilityAnswer46: 'High short-term debt level',
    faqAdmissibilityQuestion5:
      'Am I eligible if I receive my salary by check or cash?',
    faqAdmissibilityAnswer5:
      'No, unfortunately we only offer our services to clients who receive their payroll by direct deposit into a Canadian bank account.',
    faqAdmissibilityQuestion6:
      'Can a self-employed person take out one of your loans?',
    faqAdmissibilityAnswer6:
      'We only accept people who receive their salary by direct deposit.',
    faqAdmissibilityQuestion7: 'Do I need to be employed to use your services?',
    faqAdmissibilityAnswer7:
      'Yes, you must have been employed for at least 2 months.',
    faqLoanTitle: 'Loans',
    faqLoanQuestion1: 'What type of loans do you offer?',
    faqLoanAnswer1:
      'We offer variable credit facilities. As a result, our clients have quick access to funds which they can then use at their own discretion. Refunds are made in advance with no additional charges or penalties.',
    faqLoanQuestion2: 'How to apply for a loan?',
    faqLoanAnswer2:
      'To make a request, log in and complete the request form in the Services section.',
    faqLoanQuestion3: 'How long does it take to complete a loan application?',
    faqLoanAnswer3:
      'An application can be completed in as little as 2 minutes with our instant bank verification system.',
    faqLoanQuestion4: 'Are there any documents to sign or fill out?',
    faqLoanAnswer4:
      'No, the only signature required is that of the contract and it is done electronically. No documents to fill out and return by mail.',
    faqLoanQuestion5: 'How long does it take to receive the funds?',
    faqLoanAnswer5:
      'If your request is complete, it will be approved within 30 minutes and you will receive an email to deposit your funds by Interac e-Transfer within the hour.',
    faqLoanQuestion6:
      'What are the applicable rates and fees for your credit facilities?',
    faqLoanAnswer6:
      'Our annual interest rate is 19% for each loan. We also charge a weekly membership fee of $ 20.00 until full payment of the balance.For all requests for additional advances, a renewal fee of $ 50.00 applies. ',
  },
  sharedFormTexts: {
    next: 'Next',
    yes: 'Yes',
    no: 'No',
  },
  applyPage: {
    step1: 'Your Personal Information',
    step2: 'Connect Your Bank',
    step3: 'Sign Contract',
    step4: 'Application Done',
  },
  applicationSubmittedForm: {
    formTitle: 'Application submitted!',
    formSubtitle:
      'We will review your application and notify  you when the decision is made.',
    goToDashboard: 'GO TO DASHBOARD',
  },
  transferMethodForm: {
    formTitle: 'Select transfer method',
    formSubtitle:
      'Let us know how you would like your money to be transferred.',
    eTransfer: 'Interac e-transfer',
    eTransferDescription:
      'You can have the funds sent via Interac e-Transfer automatically deposited into your bank account.',
    directDeposit: 'Direct deposit',
    directDepositDescription:
      'Direct deposit is a payment option where your funds are electronically transferred to your checking or savings account.',
  },
  contractForm: {
    formTitle: 'Sign contract',
    formSubtitle:
      'You’re almost done with your application! Review and sign this contract to submit application.',
    confirmContinue:
      'Make sure to have signed the contract othewise your application may not be processed fully...',
  },
  ibvForm: {
    connectButton: 'Connect your bank account with Plaid',
    formTitle: 'Secure Banking Verification',
    formSubtitle:
      'Complete your verification securely within seconds using instant banking verification (IBV).',
  },
  secondaryContactForm: {
    formSubtitle:
      'This information helps us get in touch in case of an emergency.',
    spouse: 'Spouse',
    father: 'Father',
    mother: 'Mother',
    child: 'Child',
    friend: 'Friend',
    sister: 'Sister',
    brother: 'Brother',
    other: 'Other',
  },
  bankDetailsForm: {
    formTitle: 'Bank details',
    formSubtitle:
      'Your bank information will help us approve your application.',
    chooseBank: 'Choose your bank',
    bmo: 'Bank of Montreal',
    bns: 'The Bank of Nova Scotia',
    rbc: 'Royal Bank of Canada',
    td: 'TD Canada Trust',
    nb: 'National Bank of Canada',
    cibc: 'CIBC',
    lbc: 'Laurentian Bank of Canada',
    des: 'Desjardins',
    tan: 'Tangerine',
    planningBankruptcy:
      'Are you planning on declaring bankruptcy,\nconsumer proposal or volontary deposit in the future?',
    hadBankruptcy:
      'Did you declare bankruptcy, consumer proposal or volontary deposit in the past?',
    other: 'Other',
  },
  jobDetailsForm: {
    formTitle: 'Job Information',
    subTitle: 'Your job information will help us approve your application.',
    sourceOfIncome: 'Source of income',
    select: 'Select',
    employmentInsurance: 'Employment Insurance',
    qpip: 'Q.P.I.P.',
    employed: 'Employed',
    selfEmployed: 'Self-Employed',
    temporaryCSST: 'C.S.S.T. Temporary',
    permanentCSST: 'C.S.S.T. For Life',
    temporarySAAQ: 'S.A.A.Q. Temporary',
    permanentSAAQ: 'S.A.A.Q. For Life',
    socialInsurance: 'Social Assistance',
    socialInsuranceStartDate:
      'When did your Employment Insurance Benefits Start',
    paidByDirectDeposit: 'Are you paid by direct deposit?',
    employerName: 'Name of Employer',
    employerPhoneNumber: 'Employer Phone Number',
    jobDescription: 'Job Descriptin',
    supervisorName: 'Name of Supervisor',
    hiringDate: 'Approximate Date of Hiring',
  },

  personalDetailsForm: {
    formTitle: 'Personal Information',
    subTitle:
      'Ruby is required to collect this information for your application.',
    formBottomText:
      ' Providing your social insurance number is not mandatory, but could speed up the processing of your application',
  },
  pageTitle: {
    home: 'Home',
  },
  error: {
    passwordComplexityError:
      'Password must be at least 12 characters long and include at least one uppercase letter, one lowercase letter, and one digit.',
  },
  errorPage: {
    title: 'Oops! Page Not Found',
    content:
      'The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
    button: 'Back To Home',
  },
  underConstructionPage: {
    title: 'Oops! Page Under Construction!',
    content:
      'The page you are looking for is currently under construction. Please try again at a later date.',
  },
}

<template>
  <FaqPayment />
  <FaqAccount />
  <FaqAdmissibility />
  <FaqLoan />
</template>

<script>
import FaqPayment from '../Faq/FaqPayment.vue'
import FaqAccount from '../Faq/FaqAccount.vue'
import FaqAdmissibility from '../Faq/FaqAdmissibility.vue'
import FaqLoan from '../Faq/FaqLoan.vue'

export default {
  name: 'Faq',
  components: {
    FaqPayment,
    FaqAccount,
    FaqAdmissibility,
    FaqLoan,
  },
}
</script>

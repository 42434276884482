<template>
  <div :class="'apply-loan-steps ' + classStep">
    <div class="apply-loan-steps-heading">
      {{ steps[currentStep - 1].heading }}
    </div>
    <div class="apply-loan-steps-row">
      <div
        v-for="step in steps"
        :key="step.id"
        :class="
          step.id == currentStep
            ? 'apply-loan-steps-row-col current-step'
            : step.id < currentStep
            ? 'apply-loan-steps-row-col completed-step'
            : 'apply-loan-steps-row-col '
        "
      >
        <div class="apply-loan-steps-row-col-inner">
          <span>{{ step.id }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoanSteps',
  props: ['currentStep', 'classStep', 'steps'],
  data: () => ({}),
}
</script>

<template>
  <div class="others-options d-flex align-items-center">
    <div class="header-btn mx-1 col-sm-6 col-md-8">
      <a
        v-if="!loggedInUser"
        id="user-register-btn"
        class="btn style1"
        @click="openPopupToRegister"
      >
        {{ $t('register') }}
      </a>
      <router-link v-else to="/" class="btn style1">
        {{ $t('getALoan') }}
      </router-link>
    </div>
    <div class="header-btn col-sm-6 col-md-6">
      <a v-if="!loggedInUser" class="btn style1" @click="openPopupToLogin">
        {{ $t('login') }}
      </a>
      <a v-else class="btn style1" @click="logout">{{ $t('logout') }}</a>
    </div>
  </div>
  <div class="login-popup-container">
    <div v-if="showPopup" class="popup-layout">
      <div class="popup">
        <ForgotPasswordContent
          v-if="showForgotPasswordContent"
          @toggleForgotPasswordViewClicked="toggleForgotPasswordView"
        />

        <LoginContent
          v-if="showLoginContent"
          @toggleRegistrationViewClicked="toggleRegistrationView"
          @toggleForgotPasswordViewClicked="toggleForgotPasswordView"
          @loginButtonClicked="fetchAllUserDataFromServer"
        />

        <RegistrationContent
          v-if="showRegisterContent"
          @toggleRegistrationViewClicked="toggleRegistrationView"
        />

        <a class="btn style5" @click="closePopup"> {{ $t('close') }} </a>
      </div>
    </div>
    <!-- verify email popup -->
    <VerifyEmailContent @logoutButtonClicked="logout" />
  </div>
</template>

<script>
import ForgotPasswordContent from '@/components/UserAuth/ForgotPasswordContent.vue'
import LoginContent from '@/components/UserAuth/LoginContent.vue'
import RegistrationContent from '@/components/UserAuth/RegistrationContent.vue'
import VerifyEmailContent from '@/components/UserAuth/VerifyEmailContent.vue'
import { mapState, mapActions } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { auth } from '@/helpers/firebaseInit.js'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { useRepo } from 'pinia-orm'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebaseInit.js'
import {
  BankDetails,
  ContactDetails,
  JobDetails,
  LoanDetails,
  PersonalDetails,
  SecondaryContactDetails,
  PlaidDetails,
} from '@/models/Models'

export default {
  components: {
    ForgotPasswordContent,
    LoginContent,
    RegistrationContent,
    VerifyEmailContent,
  },
  data() {
    return {
      showPopup: false,
      showLoginContent: true,
      showRegisterContent: false,
      showForgotPasswordContent: false,
      email: '',
      password: '',
      confirmPassword: '',
      errorMessage: '',
      forotEmailSentMessage: '',
    }
  },
  mounted() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.setLoggedInUser(user)
        await this.fetchAllUserDataFromServer()
        this.closePopup()
      } else {
        this.clearAllUserData()
        this.setLoggedInUser()
      }
    })
  },
  computed: {
    ...mapState(useAuthStore, ['loggedInUser']),
    isValid() {
      return this.email !== '' && this.password !== ''
    },
  },
  methods: {
    ...mapActions(useAuthStore, ['setLoggedInUser']),
    async fetchAllUserDataFromServer() {
      const fetchAllUserData = httpsCallable(functions, 'fetchAllUserData')
      fetchAllUserData().then((result) => {
        const data = result.data

        console.log('Fetching All User Data...')

        if (data.bankDetails[0] !== 'no bank details found') {
          useRepo(BankDetails).save(data.bankDetails)
        }
        if (data.contactDetails[0] !== 'no contact details found') {
          useRepo(ContactDetails).save(data.contactDetails)
        }
        if (data.jobDetails[0] !== 'no job details found') {
          useRepo(JobDetails).save(data.jobDetails)
        }
        if (data.loanDetails[0] !== 'no loan details found') {
          useRepo(LoanDetails).save(data.loanDetails)
        }
        if (data.personalDetails[0] !== 'no personal details found') {
          useRepo(PersonalDetails).save(data.personalDetails)
        }
        if (
          data.secondaryContactDetails[0] !==
          'no secondary contact details found'
        ) {
          useRepo(SecondaryContactDetails).save(data.secondaryContactDetails)
        }
        if (data.plaidDetails[0] !== 'no plaid details found') {
          useRepo(PlaidDetails).save(data.plaidDetails)
        }
      })
    },
    clearAllUserData() {
      useRepo(BankDetails).flush()
      useRepo(ContactDetails).flush()
      useRepo(JobDetails).flush()
      useRepo(LoanDetails).flush()
      useRepo(PersonalDetails).flush()
      useRepo(SecondaryContactDetails).flush()
      useRepo(PlaidDetails).flush()

      this.$router.push('/')
    },
    openPopupToLogin() {
      this.showPopup = true
      this.showLoginContent = true
      this.showRegisterContent = false
      this.showForgotPasswordContent = false
    },
    openPopupToRegister() {
      this.showPopup = true
      this.showLoginContent = false
      this.showRegisterContent = true
      this.showForgotPasswordContent = false
    },
    closePopup() {
      this.showPopup = false
      this.showLoginContent = true
      this.showRegisterContent = false
      this.showForgotPasswordContent = false
    },
    toggleRegistrationView() {
      this.forotEmailSentMessage = ''
      this.showLoginContent = !this.showLoginContent
      this.showRegisterContent = !this.showRegisterContent
      this.showForgotPasswordContent = false
    },
    toggleForgotPasswordView() {
      this.forotEmailSentMessage = ''
      this.showLoginContent = !this.showLoginContent
      this.showRegisterContent = false
      this.showForgotPasswordContent = !this.showForgotPasswordContent
    },
    logout() {
      signOut(auth)
        .then(() => {
          console.log('signed out...')
        })
        .catch((error) => {
          error =
            error.message.indexOf('Firebase: ') > -1
              ? error.message.split('Firebase: ')[1]
              : error.message
          this.errorMessage = error
        })
    },
  },
  watch: {
    showPopup() {},
  },
}
</script>

<style>
.btn.style5 {
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .header-btn {
    flex: 1;
  }
  .login-popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup-layout {
    background-color: rgb(0 0 0 / 20%);
    z-index: 999;
  }
  .popup {
    position: sticky;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .btn.style5 {
    position: absolute;
    height: 36px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .header-btn {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-popup-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .popup-layout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 50%);
    z-index: 999;
  }
  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}
</style>

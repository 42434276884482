<template>
  <div v-if="showPopup" class="popup-layout">
    <div class="login-popup-container">
      <div class="popup">
        <div class="container">
          <div class="login-form-wrap">
            <div class="login-header">
              <h3>{{ popupTitle }}</h3>
            </div>
            <div class="login-form">
              <div class="login-body">
                <p>
                  {{ popupContent }}
                </p>
              </div>
              <a class="btn style1 m-2" @click="okButtonClicked">Continuer</a>
              <a class="btn style1 m-2" @click="cancelButtonClicked">Annuler</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: ['popupTitle', 'popupContent', 'showMessage'],
  data() {
    return {
      showPopup: this.showMessage,
    }
  },
  methods: {
    okButtonClicked() {
      this.$emit('okClicked')
    },
    cancelButtonClicked() {
      this.$emit('cancelClicked')
    },
  },
  watch: {
    showMessage(newValue) {
      this.showPopup = newValue
    },
  },
}
</script>

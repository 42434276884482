<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Register" />
      <Register />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import Register from '@/components/Unused/Register/Register'

export default {
  name: 'RegisterPage',
  components: {
    PageTitle,
    Register,
  },
}
</script>

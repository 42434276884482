<template>
  <div class="page-wrapper">
    <FrontApply />
    <WhyChooseUs />
    <ContactUs />
    <Feature />
    <Counter />
  </div>
</template>

<script>
import ContactUs from '@/components/Home/ContactUs'
import Feature from '@/components/Home/Feature'
import WhyChooseUs from '@/components/Home/WhyChooseUs'
import Counter from '@/components/Home/Counter'
import FrontApply from '@/components/Home/FrontApply'

export default {
  name: 'HomePage',
  components: {
    ContactUs,
    Feature,
    WhyChooseUs,
    Counter,
    FrontApply,
  },
}
</script>

<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <PageTitle pageTitle="Login" />
      <Login />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Common/PageTitle'
import Login from '@/components/Unused/Login/Login'

export default {
  name: 'LoginPage',
  components: {
    PageTitle,
    Login,
  },
}
</script>

<template>
  <div class="currency-wrap pt-100 pb-75 bg-stratos">
    <div class="container">
      <div class="content-title style2 text-center mb-40">
        <span>Currencies</span>
        <h2>Ability To Transfer Money</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="currency-card">
            <div class="currency-flag">
              <img src="@/assets/images/currency/flag-1.png" alt="Image" />
            </div>
            <div class="currency-info">
              <h3>Australia</h3>
              <span>Dollar($)</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="currency-card">
            <div class="currency-flag">
              <img src="@/assets/images/currency/flag-2.png" alt="Image" />
            </div>
            <div class="currency-info">
              <h3>Australia</h3>
              <span>Dollar($)</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="currency-card">
            <div class="currency-flag">
              <img src="@/assets/images/currency/flag-3.png" alt="Image" />
            </div>
            <div class="currency-info">
              <h3>United Kingdom</h3>
              <span>Pound Sterling($)</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="currency-card">
            <div class="currency-flag">
              <img src="@/assets/images/currency/flag-4.png" alt="Image" />
            </div>
            <div class="currency-info">
              <h3>Japan(JPY)</h3>
              <span>Yen(¥)</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="currency-card">
            <div class="currency-flag">
              <img src="@/assets/images/currency/flag-5.png" alt="Image" />
            </div>
            <div class="currency-info">
              <h3>New Zealand</h3>
              <span>Dollar($)</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="currency-card">
            <div class="currency-flag">
              <img src="@/assets/images/currency/flag-6.png" alt="Image" />
            </div>
            <div class="currency-info">
              <h3>Austria</h3>
              <span>Euro(€)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Currency',
}
</script>

<template>
  <footer class="footer-wrap bg-rock">
    <div class="container">
      <img
        src="../../assets/images/footer-shape-1.png"
        alt="Image"
        class="footer-shape-one"
      />
      <img
        src="../../assets/images/footer-shape-2.png"
        alt="Image"
        class="footer-shape-two"
      />
      <div class="row pt-100 pb-75">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="footer-widget">
            <router-link to="/" class="footer-logo">
              <img src="../../assets/images/logo-white.png" alt="Image" />
            </router-link>
            <p class="comp-desc">
              {{ $t('footer.leftText') }}
            </p>
            <div class="social-link">
              <ul class="social-profile list-style style1">
                <li>
                  <a target="_blank" href="https://facebook.com">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://linkedin.com">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://instagram.com">
                    <i class="ri-pinterest-fill"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">
              {{ $t('footer.footerContactTitle') }}
            </h3>
            <ul class="footer-menu list-style">
              <li>
                <i class="comp-desc">{{ $t('footer.footerContactLine1') }}</i>
              </li>
              <li>
                <i class="comp-desc">{{ $t('footer.footerContactLine2') }}</i>
              </li>
              <li>
                <i class="comp-desc">{{ $t('footer.footerContactLine3') }}</i>
              </li>
              <li>
                <i class="comp-desc">{{ $t('footer.footerContactLine4') }}</i>
              </li>
              <li>
                <i class="comp-desc">
                  {{ $t('contactUs.emailUsText1') }}@{{
                    $t('contactUs.emailUsText2')
                  }}
                </i>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">{{ $t('footer.ourCompany') }}</h3>

            <ul class="footer-menu list-style">
              <li>
                <router-link to="/about">
                  <i class="flaticon-next"></i>
                  {{ $t('footer.link1') }}
                </router-link>
              </li>
              <li>
                <router-link to="/pricing">
                  <i class="flaticon-next"></i>
                  {{ $t('footer.link2') }}
                </router-link>
              </li>
              <li>
                <router-link to="/contact">
                  <i class="flaticon-next"></i>
                  {{ $t('footer.link3') }}
                </router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">
                  <i class="flaticon-next"></i>
                  {{ $t('footer.link4') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">
              {{ $t('footer.subscribeToNewsletter') }}
            </h3>
            <p class="newsletter-text">
              {{ $t('footer.rightText') }}
            </p>
            <form @submit.prevent class="newsletter-form">
              <input type="email" placeholder="Email" />
              <button type="button">
                {{ $t('footer.subscribeToNewsletter') }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-text">
      <p>
        <i class="ri-copyright-line"></i>{{ currentYear }}
        {{ $t('footer.reserved') }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>
